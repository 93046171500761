@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap");
.min-w-0 {
  min-width: 0px !important;
}

.w-0 {
  width: 0px !important;
}

.max-w-0 {
  max-width: 0px !important;
}

.min-h-0 {
  min-height: 0px !important;
}

.h-0 {
  height: 0px !important;
}

.max-h-0 {
  max-height: 0px !important;
}

.lh-0 {
  line-height: 0px !important;
}

.min-w-1 {
  min-width: 1px !important;
}

.w-1 {
  width: 1px !important;
}

.max-w-1 {
  max-width: 1px !important;
}

.min-h-1 {
  min-height: 1px !important;
}

.h-1 {
  height: 1px !important;
}

.max-h-1 {
  max-height: 1px !important;
}

.lh-1 {
  line-height: 1px !important;
}

.min-w-2 {
  min-width: 2px !important;
}

.w-2 {
  width: 2px !important;
}

.max-w-2 {
  max-width: 2px !important;
}

.min-h-2 {
  min-height: 2px !important;
}

.h-2 {
  height: 2px !important;
}

.max-h-2 {
  max-height: 2px !important;
}

.lh-2 {
  line-height: 2px !important;
}

.min-w-3 {
  min-width: 3px !important;
}

.w-3 {
  width: 3px !important;
}

.max-w-3 {
  max-width: 3px !important;
}

.min-h-3 {
  min-height: 3px !important;
}

.h-3 {
  height: 3px !important;
}

.max-h-3 {
  max-height: 3px !important;
}

.lh-3 {
  line-height: 3px !important;
}

.min-w-4 {
  min-width: 4px !important;
}

.w-4 {
  width: 4px !important;
}

.max-w-4 {
  max-width: 4px !important;
}

.min-h-4 {
  min-height: 4px !important;
}

.h-4 {
  height: 4px !important;
}

.max-h-4 {
  max-height: 4px !important;
}

.lh-4 {
  line-height: 4px !important;
}

.min-w-5 {
  min-width: 5px !important;
}

.w-5 {
  width: 5px !important;
}

.max-w-5 {
  max-width: 5px !important;
}

.min-h-5 {
  min-height: 5px !important;
}

.h-5 {
  height: 5px !important;
}

.max-h-5 {
  max-height: 5px !important;
}

.lh-5 {
  line-height: 5px !important;
}

.min-w-6 {
  min-width: 6px !important;
}

.w-6 {
  width: 6px !important;
}

.max-w-6 {
  max-width: 6px !important;
}

.min-h-6 {
  min-height: 6px !important;
}

.h-6 {
  height: 6px !important;
}

.max-h-6 {
  max-height: 6px !important;
}

.lh-6 {
  line-height: 6px !important;
}

.min-w-7 {
  min-width: 7px !important;
}

.w-7 {
  width: 7px !important;
}

.max-w-7 {
  max-width: 7px !important;
}

.min-h-7 {
  min-height: 7px !important;
}

.h-7 {
  height: 7px !important;
}

.max-h-7 {
  max-height: 7px !important;
}

.lh-7 {
  line-height: 7px !important;
}

.min-w-8 {
  min-width: 8px !important;
}

.w-8 {
  width: 8px !important;
}

.max-w-8 {
  max-width: 8px !important;
}

.min-h-8 {
  min-height: 8px !important;
}

.h-8 {
  height: 8px !important;
}

.max-h-8 {
  max-height: 8px !important;
}

.lh-8 {
  line-height: 8px !important;
}

.min-w-9 {
  min-width: 9px !important;
}

.w-9 {
  width: 9px !important;
}

.max-w-9 {
  max-width: 9px !important;
}

.min-h-9 {
  min-height: 9px !important;
}

.h-9 {
  height: 9px !important;
}

.max-h-9 {
  max-height: 9px !important;
}

.lh-9 {
  line-height: 9px !important;
}

.min-w-10 {
  min-width: 10px !important;
}

.w-10 {
  width: 10px !important;
}

.max-w-10 {
  max-width: 10px !important;
}

.min-h-10 {
  min-height: 10px !important;
}

.h-10 {
  height: 10px !important;
}

.max-h-10 {
  max-height: 10px !important;
}

.lh-10 {
  line-height: 10px !important;
}

.min-w-11 {
  min-width: 11px !important;
}

.w-11 {
  width: 11px !important;
}

.max-w-11 {
  max-width: 11px !important;
}

.min-h-11 {
  min-height: 11px !important;
}

.h-11 {
  height: 11px !important;
}

.max-h-11 {
  max-height: 11px !important;
}

.lh-11 {
  line-height: 11px !important;
}

.min-w-12 {
  min-width: 12px !important;
}

.w-12 {
  width: 12px !important;
}

.max-w-12 {
  max-width: 12px !important;
}

.min-h-12 {
  min-height: 12px !important;
}

.h-12 {
  height: 12px !important;
}

.max-h-12 {
  max-height: 12px !important;
}

.lh-12 {
  line-height: 12px !important;
}

.min-w-13 {
  min-width: 13px !important;
}

.w-13 {
  width: 13px !important;
}

.max-w-13 {
  max-width: 13px !important;
}

.min-h-13 {
  min-height: 13px !important;
}

.h-13 {
  height: 13px !important;
}

.max-h-13 {
  max-height: 13px !important;
}

.lh-13 {
  line-height: 13px !important;
}

.min-w-14 {
  min-width: 14px !important;
}

.w-14 {
  width: 14px !important;
}

.max-w-14 {
  max-width: 14px !important;
}

.min-h-14 {
  min-height: 14px !important;
}

.h-14 {
  height: 14px !important;
}

.max-h-14 {
  max-height: 14px !important;
}

.lh-14 {
  line-height: 14px !important;
}

.min-w-15 {
  min-width: 15px !important;
}

.w-15 {
  width: 15px !important;
}

.max-w-15 {
  max-width: 15px !important;
}

.min-h-15 {
  min-height: 15px !important;
}

.h-15 {
  height: 15px !important;
}

.max-h-15 {
  max-height: 15px !important;
}

.lh-15 {
  line-height: 15px !important;
}

.min-w-16 {
  min-width: 16px !important;
}

.w-16 {
  width: 16px !important;
}

.max-w-16 {
  max-width: 16px !important;
}

.min-h-16 {
  min-height: 16px !important;
}

.h-16 {
  height: 16px !important;
}

.max-h-16 {
  max-height: 16px !important;
}

.lh-16 {
  line-height: 16px !important;
}

.min-w-17 {
  min-width: 17px !important;
}

.w-17 {
  width: 17px !important;
}

.max-w-17 {
  max-width: 17px !important;
}

.min-h-17 {
  min-height: 17px !important;
}

.h-17 {
  height: 17px !important;
}

.max-h-17 {
  max-height: 17px !important;
}

.lh-17 {
  line-height: 17px !important;
}

.min-w-18 {
  min-width: 18px !important;
}

.w-18 {
  width: 18px !important;
}

.max-w-18 {
  max-width: 18px !important;
}

.min-h-18 {
  min-height: 18px !important;
}

.h-18 {
  height: 18px !important;
}

.max-h-18 {
  max-height: 18px !important;
}

.lh-18 {
  line-height: 18px !important;
}

.min-w-19 {
  min-width: 19px !important;
}

.w-19 {
  width: 19px !important;
}

.max-w-19 {
  max-width: 19px !important;
}

.min-h-19 {
  min-height: 19px !important;
}

.h-19 {
  height: 19px !important;
}

.max-h-19 {
  max-height: 19px !important;
}

.lh-19 {
  line-height: 19px !important;
}

.min-w-20 {
  min-width: 20px !important;
}

.w-20 {
  width: 20px !important;
}

.max-w-20 {
  max-width: 20px !important;
}

.min-h-20 {
  min-height: 20px !important;
}

.h-20 {
  height: 20px !important;
}

.max-h-20 {
  max-height: 20px !important;
}

.lh-20 {
  line-height: 20px !important;
}

.min-w-21 {
  min-width: 21px !important;
}

.w-21 {
  width: 21px !important;
}

.max-w-21 {
  max-width: 21px !important;
}

.min-h-21 {
  min-height: 21px !important;
}

.h-21 {
  height: 21px !important;
}

.max-h-21 {
  max-height: 21px !important;
}

.lh-21 {
  line-height: 21px !important;
}

.min-w-22 {
  min-width: 22px !important;
}

.w-22 {
  width: 22px !important;
}

.max-w-22 {
  max-width: 22px !important;
}

.min-h-22 {
  min-height: 22px !important;
}

.h-22 {
  height: 22px !important;
}

.max-h-22 {
  max-height: 22px !important;
}

.lh-22 {
  line-height: 22px !important;
}

.min-w-23 {
  min-width: 23px !important;
}

.w-23 {
  width: 23px !important;
}

.max-w-23 {
  max-width: 23px !important;
}

.min-h-23 {
  min-height: 23px !important;
}

.h-23 {
  height: 23px !important;
}

.max-h-23 {
  max-height: 23px !important;
}

.lh-23 {
  line-height: 23px !important;
}

.min-w-24 {
  min-width: 24px !important;
}

.w-24 {
  width: 24px !important;
}

.max-w-24 {
  max-width: 24px !important;
}

.min-h-24 {
  min-height: 24px !important;
}

.h-24 {
  height: 24px !important;
}

.max-h-24 {
  max-height: 24px !important;
}

.lh-24 {
  line-height: 24px !important;
}

.min-w-25 {
  min-width: 25px !important;
}

.w-25 {
  width: 25px !important;
}

.max-w-25 {
  max-width: 25px !important;
}

.min-h-25 {
  min-height: 25px !important;
}

.h-25 {
  height: 25px !important;
}

.max-h-25 {
  max-height: 25px !important;
}

.lh-25 {
  line-height: 25px !important;
}

.min-w-26 {
  min-width: 26px !important;
}

.w-26 {
  width: 26px !important;
}

.max-w-26 {
  max-width: 26px !important;
}

.min-h-26 {
  min-height: 26px !important;
}

.h-26 {
  height: 26px !important;
}

.max-h-26 {
  max-height: 26px !important;
}

.lh-26 {
  line-height: 26px !important;
}

.min-w-27 {
  min-width: 27px !important;
}

.w-27 {
  width: 27px !important;
}

.max-w-27 {
  max-width: 27px !important;
}

.min-h-27 {
  min-height: 27px !important;
}

.h-27 {
  height: 27px !important;
}

.max-h-27 {
  max-height: 27px !important;
}

.lh-27 {
  line-height: 27px !important;
}

.min-w-28 {
  min-width: 28px !important;
}

.w-28 {
  width: 28px !important;
}

.max-w-28 {
  max-width: 28px !important;
}

.min-h-28 {
  min-height: 28px !important;
}

.h-28 {
  height: 28px !important;
}

.max-h-28 {
  max-height: 28px !important;
}

.lh-28 {
  line-height: 28px !important;
}

.min-w-29 {
  min-width: 29px !important;
}

.w-29 {
  width: 29px !important;
}

.max-w-29 {
  max-width: 29px !important;
}

.min-h-29 {
  min-height: 29px !important;
}

.h-29 {
  height: 29px !important;
}

.max-h-29 {
  max-height: 29px !important;
}

.lh-29 {
  line-height: 29px !important;
}

.min-w-30 {
  min-width: 30px !important;
}

.w-30 {
  width: 30px !important;
}

.max-w-30 {
  max-width: 30px !important;
}

.min-h-30 {
  min-height: 30px !important;
}

.h-30 {
  height: 30px !important;
}

.max-h-30 {
  max-height: 30px !important;
}

.lh-30 {
  line-height: 30px !important;
}

.min-w-31 {
  min-width: 31px !important;
}

.w-31 {
  width: 31px !important;
}

.max-w-31 {
  max-width: 31px !important;
}

.min-h-31 {
  min-height: 31px !important;
}

.h-31 {
  height: 31px !important;
}

.max-h-31 {
  max-height: 31px !important;
}

.lh-31 {
  line-height: 31px !important;
}

.min-w-32 {
  min-width: 32px !important;
}

.w-32 {
  width: 32px !important;
}

.max-w-32 {
  max-width: 32px !important;
}

.min-h-32 {
  min-height: 32px !important;
}

.h-32 {
  height: 32px !important;
}

.max-h-32 {
  max-height: 32px !important;
}

.lh-32 {
  line-height: 32px !important;
}

.min-w-33 {
  min-width: 33px !important;
}

.w-33 {
  width: 33px !important;
}

.max-w-33 {
  max-width: 33px !important;
}

.min-h-33 {
  min-height: 33px !important;
}

.h-33 {
  height: 33px !important;
}

.max-h-33 {
  max-height: 33px !important;
}

.lh-33 {
  line-height: 33px !important;
}

.min-w-34 {
  min-width: 34px !important;
}

.w-34 {
  width: 34px !important;
}

.max-w-34 {
  max-width: 34px !important;
}

.min-h-34 {
  min-height: 34px !important;
}

.h-34 {
  height: 34px !important;
}

.max-h-34 {
  max-height: 34px !important;
}

.lh-34 {
  line-height: 34px !important;
}

.min-w-35 {
  min-width: 35px !important;
}

.w-35 {
  width: 35px !important;
}

.max-w-35 {
  max-width: 35px !important;
}

.min-h-35 {
  min-height: 35px !important;
}

.h-35 {
  height: 35px !important;
}

.max-h-35 {
  max-height: 35px !important;
}

.lh-35 {
  line-height: 35px !important;
}

.min-w-36 {
  min-width: 36px !important;
}

.w-36 {
  width: 36px !important;
}

.max-w-36 {
  max-width: 36px !important;
}

.min-h-36 {
  min-height: 36px !important;
}

.h-36 {
  height: 36px !important;
}

.max-h-36 {
  max-height: 36px !important;
}

.lh-36 {
  line-height: 36px !important;
}

.min-w-37 {
  min-width: 37px !important;
}

.w-37 {
  width: 37px !important;
}

.max-w-37 {
  max-width: 37px !important;
}

.min-h-37 {
  min-height: 37px !important;
}

.h-37 {
  height: 37px !important;
}

.max-h-37 {
  max-height: 37px !important;
}

.lh-37 {
  line-height: 37px !important;
}

.min-w-38 {
  min-width: 38px !important;
}

.w-38 {
  width: 38px !important;
}

.max-w-38 {
  max-width: 38px !important;
}

.min-h-38 {
  min-height: 38px !important;
}

.h-38 {
  height: 38px !important;
}

.max-h-38 {
  max-height: 38px !important;
}

.lh-38 {
  line-height: 38px !important;
}

.min-w-39 {
  min-width: 39px !important;
}

.w-39 {
  width: 39px !important;
}

.max-w-39 {
  max-width: 39px !important;
}

.min-h-39 {
  min-height: 39px !important;
}

.h-39 {
  height: 39px !important;
}

.max-h-39 {
  max-height: 39px !important;
}

.lh-39 {
  line-height: 39px !important;
}

.min-w-40 {
  min-width: 40px !important;
}

.w-40 {
  width: 40px !important;
}

.max-w-40 {
  max-width: 40px !important;
}

.min-h-40 {
  min-height: 40px !important;
}

.h-40 {
  height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.lh-40 {
  line-height: 40px !important;
}

.min-w-41 {
  min-width: 41px !important;
}

.w-41 {
  width: 41px !important;
}

.max-w-41 {
  max-width: 41px !important;
}

.min-h-41 {
  min-height: 41px !important;
}

.h-41 {
  height: 41px !important;
}

.max-h-41 {
  max-height: 41px !important;
}

.lh-41 {
  line-height: 41px !important;
}

.min-w-42 {
  min-width: 42px !important;
}

.w-42 {
  width: 42px !important;
}

.max-w-42 {
  max-width: 42px !important;
}

.min-h-42 {
  min-height: 42px !important;
}

.h-42 {
  height: 42px !important;
}

.max-h-42 {
  max-height: 42px !important;
}

.lh-42 {
  line-height: 42px !important;
}

.min-w-43 {
  min-width: 43px !important;
}

.w-43 {
  width: 43px !important;
}

.max-w-43 {
  max-width: 43px !important;
}

.min-h-43 {
  min-height: 43px !important;
}

.h-43 {
  height: 43px !important;
}

.max-h-43 {
  max-height: 43px !important;
}

.lh-43 {
  line-height: 43px !important;
}

.min-w-44 {
  min-width: 44px !important;
}

.w-44 {
  width: 44px !important;
}

.max-w-44 {
  max-width: 44px !important;
}

.min-h-44 {
  min-height: 44px !important;
}

.h-44 {
  height: 44px !important;
}

.max-h-44 {
  max-height: 44px !important;
}

.lh-44 {
  line-height: 44px !important;
}

.min-w-45 {
  min-width: 45px !important;
}

.w-45 {
  width: 45px !important;
}

.max-w-45 {
  max-width: 45px !important;
}

.min-h-45 {
  min-height: 45px !important;
}

.h-45 {
  height: 45px !important;
}

.max-h-45 {
  max-height: 45px !important;
}

.lh-45 {
  line-height: 45px !important;
}

.min-w-46 {
  min-width: 46px !important;
}

.w-46 {
  width: 46px !important;
}

.max-w-46 {
  max-width: 46px !important;
}

.min-h-46 {
  min-height: 46px !important;
}

.h-46 {
  height: 46px !important;
}

.max-h-46 {
  max-height: 46px !important;
}

.lh-46 {
  line-height: 46px !important;
}

.min-w-47 {
  min-width: 47px !important;
}

.w-47 {
  width: 47px !important;
}

.max-w-47 {
  max-width: 47px !important;
}

.min-h-47 {
  min-height: 47px !important;
}

.h-47 {
  height: 47px !important;
}

.max-h-47 {
  max-height: 47px !important;
}

.lh-47 {
  line-height: 47px !important;
}

.min-w-48 {
  min-width: 48px !important;
}

.w-48 {
  width: 48px !important;
}

.max-w-48 {
  max-width: 48px !important;
}

.min-h-48 {
  min-height: 48px !important;
}

.h-48 {
  height: 48px !important;
}

.max-h-48 {
  max-height: 48px !important;
}

.lh-48 {
  line-height: 48px !important;
}

.min-w-49 {
  min-width: 49px !important;
}

.w-49 {
  width: 49px !important;
}

.max-w-49 {
  max-width: 49px !important;
}

.min-h-49 {
  min-height: 49px !important;
}

.h-49 {
  height: 49px !important;
}

.max-h-49 {
  max-height: 49px !important;
}

.lh-49 {
  line-height: 49px !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.w-50 {
  width: 50px !important;
}

.max-w-50 {
  max-width: 50px !important;
}

.min-h-50 {
  min-height: 50px !important;
}

.h-50 {
  height: 50px !important;
}

.max-h-50 {
  max-height: 50px !important;
}

.lh-50 {
  line-height: 50px !important;
}

.min-w-51 {
  min-width: 51px !important;
}

.w-51 {
  width: 51px !important;
}

.max-w-51 {
  max-width: 51px !important;
}

.min-h-51 {
  min-height: 51px !important;
}

.h-51 {
  height: 51px !important;
}

.max-h-51 {
  max-height: 51px !important;
}

.lh-51 {
  line-height: 51px !important;
}

.min-w-52 {
  min-width: 52px !important;
}

.w-52 {
  width: 52px !important;
}

.max-w-52 {
  max-width: 52px !important;
}

.min-h-52 {
  min-height: 52px !important;
}

.h-52 {
  height: 52px !important;
}

.max-h-52 {
  max-height: 52px !important;
}

.lh-52 {
  line-height: 52px !important;
}

.min-w-53 {
  min-width: 53px !important;
}

.w-53 {
  width: 53px !important;
}

.max-w-53 {
  max-width: 53px !important;
}

.min-h-53 {
  min-height: 53px !important;
}

.h-53 {
  height: 53px !important;
}

.max-h-53 {
  max-height: 53px !important;
}

.lh-53 {
  line-height: 53px !important;
}

.min-w-54 {
  min-width: 54px !important;
}

.w-54 {
  width: 54px !important;
}

.max-w-54 {
  max-width: 54px !important;
}

.min-h-54 {
  min-height: 54px !important;
}

.h-54 {
  height: 54px !important;
}

.max-h-54 {
  max-height: 54px !important;
}

.lh-54 {
  line-height: 54px !important;
}

.min-w-55 {
  min-width: 55px !important;
}

.w-55 {
  width: 55px !important;
}

.max-w-55 {
  max-width: 55px !important;
}

.min-h-55 {
  min-height: 55px !important;
}

.h-55 {
  height: 55px !important;
}

.max-h-55 {
  max-height: 55px !important;
}

.lh-55 {
  line-height: 55px !important;
}

.min-w-56 {
  min-width: 56px !important;
}

.w-56 {
  width: 56px !important;
}

.max-w-56 {
  max-width: 56px !important;
}

.min-h-56 {
  min-height: 56px !important;
}

.h-56 {
  height: 56px !important;
}

.max-h-56 {
  max-height: 56px !important;
}

.lh-56 {
  line-height: 56px !important;
}

.min-w-57 {
  min-width: 57px !important;
}

.w-57 {
  width: 57px !important;
}

.max-w-57 {
  max-width: 57px !important;
}

.min-h-57 {
  min-height: 57px !important;
}

.h-57 {
  height: 57px !important;
}

.max-h-57 {
  max-height: 57px !important;
}

.lh-57 {
  line-height: 57px !important;
}

.min-w-58 {
  min-width: 58px !important;
}

.w-58 {
  width: 58px !important;
}

.max-w-58 {
  max-width: 58px !important;
}

.min-h-58 {
  min-height: 58px !important;
}

.h-58 {
  height: 58px !important;
}

.max-h-58 {
  max-height: 58px !important;
}

.lh-58 {
  line-height: 58px !important;
}

.min-w-59 {
  min-width: 59px !important;
}

.w-59 {
  width: 59px !important;
}

.max-w-59 {
  max-width: 59px !important;
}

.min-h-59 {
  min-height: 59px !important;
}

.h-59 {
  height: 59px !important;
}

.max-h-59 {
  max-height: 59px !important;
}

.lh-59 {
  line-height: 59px !important;
}

.min-w-60 {
  min-width: 60px !important;
}

.w-60 {
  width: 60px !important;
}

.max-w-60 {
  max-width: 60px !important;
}

.min-h-60 {
  min-height: 60px !important;
}

.h-60 {
  height: 60px !important;
}

.max-h-60 {
  max-height: 60px !important;
}

.lh-60 {
  line-height: 60px !important;
}

.min-w-61 {
  min-width: 61px !important;
}

.w-61 {
  width: 61px !important;
}

.max-w-61 {
  max-width: 61px !important;
}

.min-h-61 {
  min-height: 61px !important;
}

.h-61 {
  height: 61px !important;
}

.max-h-61 {
  max-height: 61px !important;
}

.lh-61 {
  line-height: 61px !important;
}

.min-w-62 {
  min-width: 62px !important;
}

.w-62 {
  width: 62px !important;
}

.max-w-62 {
  max-width: 62px !important;
}

.min-h-62 {
  min-height: 62px !important;
}

.h-62 {
  height: 62px !important;
}

.max-h-62 {
  max-height: 62px !important;
}

.lh-62 {
  line-height: 62px !important;
}

.min-w-63 {
  min-width: 63px !important;
}

.w-63 {
  width: 63px !important;
}

.max-w-63 {
  max-width: 63px !important;
}

.min-h-63 {
  min-height: 63px !important;
}

.h-63 {
  height: 63px !important;
}

.max-h-63 {
  max-height: 63px !important;
}

.lh-63 {
  line-height: 63px !important;
}

.min-w-64 {
  min-width: 64px !important;
}

.w-64 {
  width: 64px !important;
}

.max-w-64 {
  max-width: 64px !important;
}

.min-h-64 {
  min-height: 64px !important;
}

.h-64 {
  height: 64px !important;
}

.max-h-64 {
  max-height: 64px !important;
}

.lh-64 {
  line-height: 64px !important;
}

.min-w-65 {
  min-width: 65px !important;
}

.w-65 {
  width: 65px !important;
}

.max-w-65 {
  max-width: 65px !important;
}

.min-h-65 {
  min-height: 65px !important;
}

.h-65 {
  height: 65px !important;
}

.max-h-65 {
  max-height: 65px !important;
}

.lh-65 {
  line-height: 65px !important;
}

.min-w-66 {
  min-width: 66px !important;
}

.w-66 {
  width: 66px !important;
}

.max-w-66 {
  max-width: 66px !important;
}

.min-h-66 {
  min-height: 66px !important;
}

.h-66 {
  height: 66px !important;
}

.max-h-66 {
  max-height: 66px !important;
}

.lh-66 {
  line-height: 66px !important;
}

.min-w-67 {
  min-width: 67px !important;
}

.w-67 {
  width: 67px !important;
}

.max-w-67 {
  max-width: 67px !important;
}

.min-h-67 {
  min-height: 67px !important;
}

.h-67 {
  height: 67px !important;
}

.max-h-67 {
  max-height: 67px !important;
}

.lh-67 {
  line-height: 67px !important;
}

.min-w-68 {
  min-width: 68px !important;
}

.w-68 {
  width: 68px !important;
}

.max-w-68 {
  max-width: 68px !important;
}

.min-h-68 {
  min-height: 68px !important;
}

.h-68 {
  height: 68px !important;
}

.max-h-68 {
  max-height: 68px !important;
}

.lh-68 {
  line-height: 68px !important;
}

.min-w-69 {
  min-width: 69px !important;
}

.w-69 {
  width: 69px !important;
}

.max-w-69 {
  max-width: 69px !important;
}

.min-h-69 {
  min-height: 69px !important;
}

.h-69 {
  height: 69px !important;
}

.max-h-69 {
  max-height: 69px !important;
}

.lh-69 {
  line-height: 69px !important;
}

.min-w-70 {
  min-width: 70px !important;
}

.w-70 {
  width: 70px !important;
}

.max-w-70 {
  max-width: 70px !important;
}

.min-h-70 {
  min-height: 70px !important;
}

.h-70 {
  height: 70px !important;
}

.max-h-70 {
  max-height: 70px !important;
}

.lh-70 {
  line-height: 70px !important;
}

.min-w-71 {
  min-width: 71px !important;
}

.w-71 {
  width: 71px !important;
}

.max-w-71 {
  max-width: 71px !important;
}

.min-h-71 {
  min-height: 71px !important;
}

.h-71 {
  height: 71px !important;
}

.max-h-71 {
  max-height: 71px !important;
}

.lh-71 {
  line-height: 71px !important;
}

.min-w-72 {
  min-width: 72px !important;
}

.w-72 {
  width: 72px !important;
}

.max-w-72 {
  max-width: 72px !important;
}

.min-h-72 {
  min-height: 72px !important;
}

.h-72 {
  height: 72px !important;
}

.max-h-72 {
  max-height: 72px !important;
}

.lh-72 {
  line-height: 72px !important;
}

.min-w-73 {
  min-width: 73px !important;
}

.w-73 {
  width: 73px !important;
}

.max-w-73 {
  max-width: 73px !important;
}

.min-h-73 {
  min-height: 73px !important;
}

.h-73 {
  height: 73px !important;
}

.max-h-73 {
  max-height: 73px !important;
}

.lh-73 {
  line-height: 73px !important;
}

.min-w-74 {
  min-width: 74px !important;
}

.w-74 {
  width: 74px !important;
}

.max-w-74 {
  max-width: 74px !important;
}

.min-h-74 {
  min-height: 74px !important;
}

.h-74 {
  height: 74px !important;
}

.max-h-74 {
  max-height: 74px !important;
}

.lh-74 {
  line-height: 74px !important;
}

.min-w-75 {
  min-width: 75px !important;
}

.w-75 {
  width: 75px !important;
}

.max-w-75 {
  max-width: 75px !important;
}

.min-h-75 {
  min-height: 75px !important;
}

.h-75 {
  height: 75px !important;
}

.max-h-75 {
  max-height: 75px !important;
}

.lh-75 {
  line-height: 75px !important;
}

.min-w-76 {
  min-width: 76px !important;
}

.w-76 {
  width: 76px !important;
}

.max-w-76 {
  max-width: 76px !important;
}

.min-h-76 {
  min-height: 76px !important;
}

.h-76 {
  height: 76px !important;
}

.max-h-76 {
  max-height: 76px !important;
}

.lh-76 {
  line-height: 76px !important;
}

.min-w-77 {
  min-width: 77px !important;
}

.w-77 {
  width: 77px !important;
}

.max-w-77 {
  max-width: 77px !important;
}

.min-h-77 {
  min-height: 77px !important;
}

.h-77 {
  height: 77px !important;
}

.max-h-77 {
  max-height: 77px !important;
}

.lh-77 {
  line-height: 77px !important;
}

.min-w-78 {
  min-width: 78px !important;
}

.w-78 {
  width: 78px !important;
}

.max-w-78 {
  max-width: 78px !important;
}

.min-h-78 {
  min-height: 78px !important;
}

.h-78 {
  height: 78px !important;
}

.max-h-78 {
  max-height: 78px !important;
}

.lh-78 {
  line-height: 78px !important;
}

.min-w-79 {
  min-width: 79px !important;
}

.w-79 {
  width: 79px !important;
}

.max-w-79 {
  max-width: 79px !important;
}

.min-h-79 {
  min-height: 79px !important;
}

.h-79 {
  height: 79px !important;
}

.max-h-79 {
  max-height: 79px !important;
}

.lh-79 {
  line-height: 79px !important;
}

.min-w-80 {
  min-width: 80px !important;
}

.w-80 {
  width: 80px !important;
}

.max-w-80 {
  max-width: 80px !important;
}

.min-h-80 {
  min-height: 80px !important;
}

.h-80 {
  height: 80px !important;
}

.max-h-80 {
  max-height: 80px !important;
}

.lh-80 {
  line-height: 80px !important;
}

.min-w-81 {
  min-width: 81px !important;
}

.w-81 {
  width: 81px !important;
}

.max-w-81 {
  max-width: 81px !important;
}

.min-h-81 {
  min-height: 81px !important;
}

.h-81 {
  height: 81px !important;
}

.max-h-81 {
  max-height: 81px !important;
}

.lh-81 {
  line-height: 81px !important;
}

.min-w-82 {
  min-width: 82px !important;
}

.w-82 {
  width: 82px !important;
}

.max-w-82 {
  max-width: 82px !important;
}

.min-h-82 {
  min-height: 82px !important;
}

.h-82 {
  height: 82px !important;
}

.max-h-82 {
  max-height: 82px !important;
}

.lh-82 {
  line-height: 82px !important;
}

.min-w-83 {
  min-width: 83px !important;
}

.w-83 {
  width: 83px !important;
}

.max-w-83 {
  max-width: 83px !important;
}

.min-h-83 {
  min-height: 83px !important;
}

.h-83 {
  height: 83px !important;
}

.max-h-83 {
  max-height: 83px !important;
}

.lh-83 {
  line-height: 83px !important;
}

.min-w-84 {
  min-width: 84px !important;
}

.w-84 {
  width: 84px !important;
}

.max-w-84 {
  max-width: 84px !important;
}

.min-h-84 {
  min-height: 84px !important;
}

.h-84 {
  height: 84px !important;
}

.max-h-84 {
  max-height: 84px !important;
}

.lh-84 {
  line-height: 84px !important;
}

.min-w-85 {
  min-width: 85px !important;
}

.w-85 {
  width: 85px !important;
}

.max-w-85 {
  max-width: 85px !important;
}

.min-h-85 {
  min-height: 85px !important;
}

.h-85 {
  height: 85px !important;
}

.max-h-85 {
  max-height: 85px !important;
}

.lh-85 {
  line-height: 85px !important;
}

.min-w-86 {
  min-width: 86px !important;
}

.w-86 {
  width: 86px !important;
}

.max-w-86 {
  max-width: 86px !important;
}

.min-h-86 {
  min-height: 86px !important;
}

.h-86 {
  height: 86px !important;
}

.max-h-86 {
  max-height: 86px !important;
}

.lh-86 {
  line-height: 86px !important;
}

.min-w-87 {
  min-width: 87px !important;
}

.w-87 {
  width: 87px !important;
}

.max-w-87 {
  max-width: 87px !important;
}

.min-h-87 {
  min-height: 87px !important;
}

.h-87 {
  height: 87px !important;
}

.max-h-87 {
  max-height: 87px !important;
}

.lh-87 {
  line-height: 87px !important;
}

.min-w-88 {
  min-width: 88px !important;
}

.w-88 {
  width: 88px !important;
}

.max-w-88 {
  max-width: 88px !important;
}

.min-h-88 {
  min-height: 88px !important;
}

.h-88 {
  height: 88px !important;
}

.max-h-88 {
  max-height: 88px !important;
}

.lh-88 {
  line-height: 88px !important;
}

.min-w-89 {
  min-width: 89px !important;
}

.w-89 {
  width: 89px !important;
}

.max-w-89 {
  max-width: 89px !important;
}

.min-h-89 {
  min-height: 89px !important;
}

.h-89 {
  height: 89px !important;
}

.max-h-89 {
  max-height: 89px !important;
}

.lh-89 {
  line-height: 89px !important;
}

.min-w-90 {
  min-width: 90px !important;
}

.w-90 {
  width: 90px !important;
}

.max-w-90 {
  max-width: 90px !important;
}

.min-h-90 {
  min-height: 90px !important;
}

.h-90 {
  height: 90px !important;
}

.max-h-90 {
  max-height: 90px !important;
}

.lh-90 {
  line-height: 90px !important;
}

.min-w-91 {
  min-width: 91px !important;
}

.w-91 {
  width: 91px !important;
}

.max-w-91 {
  max-width: 91px !important;
}

.min-h-91 {
  min-height: 91px !important;
}

.h-91 {
  height: 91px !important;
}

.max-h-91 {
  max-height: 91px !important;
}

.lh-91 {
  line-height: 91px !important;
}

.min-w-92 {
  min-width: 92px !important;
}

.w-92 {
  width: 92px !important;
}

.max-w-92 {
  max-width: 92px !important;
}

.min-h-92 {
  min-height: 92px !important;
}

.h-92 {
  height: 92px !important;
}

.max-h-92 {
  max-height: 92px !important;
}

.lh-92 {
  line-height: 92px !important;
}

.min-w-93 {
  min-width: 93px !important;
}

.w-93 {
  width: 93px !important;
}

.max-w-93 {
  max-width: 93px !important;
}

.min-h-93 {
  min-height: 93px !important;
}

.h-93 {
  height: 93px !important;
}

.max-h-93 {
  max-height: 93px !important;
}

.lh-93 {
  line-height: 93px !important;
}

.min-w-94 {
  min-width: 94px !important;
}

.w-94 {
  width: 94px !important;
}

.max-w-94 {
  max-width: 94px !important;
}

.min-h-94 {
  min-height: 94px !important;
}

.h-94 {
  height: 94px !important;
}

.max-h-94 {
  max-height: 94px !important;
}

.lh-94 {
  line-height: 94px !important;
}

.min-w-95 {
  min-width: 95px !important;
}

.w-95 {
  width: 95px !important;
}

.max-w-95 {
  max-width: 95px !important;
}

.min-h-95 {
  min-height: 95px !important;
}

.h-95 {
  height: 95px !important;
}

.max-h-95 {
  max-height: 95px !important;
}

.lh-95 {
  line-height: 95px !important;
}

.min-w-96 {
  min-width: 96px !important;
}

.w-96 {
  width: 96px !important;
}

.max-w-96 {
  max-width: 96px !important;
}

.min-h-96 {
  min-height: 96px !important;
}

.h-96 {
  height: 96px !important;
}

.max-h-96 {
  max-height: 96px !important;
}

.lh-96 {
  line-height: 96px !important;
}

.min-w-97 {
  min-width: 97px !important;
}

.w-97 {
  width: 97px !important;
}

.max-w-97 {
  max-width: 97px !important;
}

.min-h-97 {
  min-height: 97px !important;
}

.h-97 {
  height: 97px !important;
}

.max-h-97 {
  max-height: 97px !important;
}

.lh-97 {
  line-height: 97px !important;
}

.min-w-98 {
  min-width: 98px !important;
}

.w-98 {
  width: 98px !important;
}

.max-w-98 {
  max-width: 98px !important;
}

.min-h-98 {
  min-height: 98px !important;
}

.h-98 {
  height: 98px !important;
}

.max-h-98 {
  max-height: 98px !important;
}

.lh-98 {
  line-height: 98px !important;
}

.min-w-99 {
  min-width: 99px !important;
}

.w-99 {
  width: 99px !important;
}

.max-w-99 {
  max-width: 99px !important;
}

.min-h-99 {
  min-height: 99px !important;
}

.h-99 {
  height: 99px !important;
}

.max-h-99 {
  max-height: 99px !important;
}

.lh-99 {
  line-height: 99px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.w-100 {
  width: 100px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.h-100 {
  height: 100px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.lh-100 {
  line-height: 100px !important;
}

.min-w-101 {
  min-width: 101px !important;
}

.w-101 {
  width: 101px !important;
}

.max-w-101 {
  max-width: 101px !important;
}

.min-h-101 {
  min-height: 101px !important;
}

.h-101 {
  height: 101px !important;
}

.max-h-101 {
  max-height: 101px !important;
}

.lh-101 {
  line-height: 101px !important;
}

.min-w-102 {
  min-width: 102px !important;
}

.w-102 {
  width: 102px !important;
}

.max-w-102 {
  max-width: 102px !important;
}

.min-h-102 {
  min-height: 102px !important;
}

.h-102 {
  height: 102px !important;
}

.max-h-102 {
  max-height: 102px !important;
}

.lh-102 {
  line-height: 102px !important;
}

.min-w-103 {
  min-width: 103px !important;
}

.w-103 {
  width: 103px !important;
}

.max-w-103 {
  max-width: 103px !important;
}

.min-h-103 {
  min-height: 103px !important;
}

.h-103 {
  height: 103px !important;
}

.max-h-103 {
  max-height: 103px !important;
}

.lh-103 {
  line-height: 103px !important;
}

.min-w-104 {
  min-width: 104px !important;
}

.w-104 {
  width: 104px !important;
}

.max-w-104 {
  max-width: 104px !important;
}

.min-h-104 {
  min-height: 104px !important;
}

.h-104 {
  height: 104px !important;
}

.max-h-104 {
  max-height: 104px !important;
}

.lh-104 {
  line-height: 104px !important;
}

.min-w-105 {
  min-width: 105px !important;
}

.w-105 {
  width: 105px !important;
}

.max-w-105 {
  max-width: 105px !important;
}

.min-h-105 {
  min-height: 105px !important;
}

.h-105 {
  height: 105px !important;
}

.max-h-105 {
  max-height: 105px !important;
}

.lh-105 {
  line-height: 105px !important;
}

.min-w-106 {
  min-width: 106px !important;
}

.w-106 {
  width: 106px !important;
}

.max-w-106 {
  max-width: 106px !important;
}

.min-h-106 {
  min-height: 106px !important;
}

.h-106 {
  height: 106px !important;
}

.max-h-106 {
  max-height: 106px !important;
}

.lh-106 {
  line-height: 106px !important;
}

.min-w-107 {
  min-width: 107px !important;
}

.w-107 {
  width: 107px !important;
}

.max-w-107 {
  max-width: 107px !important;
}

.min-h-107 {
  min-height: 107px !important;
}

.h-107 {
  height: 107px !important;
}

.max-h-107 {
  max-height: 107px !important;
}

.lh-107 {
  line-height: 107px !important;
}

.min-w-108 {
  min-width: 108px !important;
}

.w-108 {
  width: 108px !important;
}

.max-w-108 {
  max-width: 108px !important;
}

.min-h-108 {
  min-height: 108px !important;
}

.h-108 {
  height: 108px !important;
}

.max-h-108 {
  max-height: 108px !important;
}

.lh-108 {
  line-height: 108px !important;
}

.min-w-109 {
  min-width: 109px !important;
}

.w-109 {
  width: 109px !important;
}

.max-w-109 {
  max-width: 109px !important;
}

.min-h-109 {
  min-height: 109px !important;
}

.h-109 {
  height: 109px !important;
}

.max-h-109 {
  max-height: 109px !important;
}

.lh-109 {
  line-height: 109px !important;
}

.min-w-110 {
  min-width: 110px !important;
}

.w-110 {
  width: 110px !important;
}

.max-w-110 {
  max-width: 110px !important;
}

.min-h-110 {
  min-height: 110px !important;
}

.h-110 {
  height: 110px !important;
}

.max-h-110 {
  max-height: 110px !important;
}

.lh-110 {
  line-height: 110px !important;
}

.min-w-111 {
  min-width: 111px !important;
}

.w-111 {
  width: 111px !important;
}

.max-w-111 {
  max-width: 111px !important;
}

.min-h-111 {
  min-height: 111px !important;
}

.h-111 {
  height: 111px !important;
}

.max-h-111 {
  max-height: 111px !important;
}

.lh-111 {
  line-height: 111px !important;
}

.min-w-112 {
  min-width: 112px !important;
}

.w-112 {
  width: 112px !important;
}

.max-w-112 {
  max-width: 112px !important;
}

.min-h-112 {
  min-height: 112px !important;
}

.h-112 {
  height: 112px !important;
}

.max-h-112 {
  max-height: 112px !important;
}

.lh-112 {
  line-height: 112px !important;
}

.min-w-113 {
  min-width: 113px !important;
}

.w-113 {
  width: 113px !important;
}

.max-w-113 {
  max-width: 113px !important;
}

.min-h-113 {
  min-height: 113px !important;
}

.h-113 {
  height: 113px !important;
}

.max-h-113 {
  max-height: 113px !important;
}

.lh-113 {
  line-height: 113px !important;
}

.min-w-114 {
  min-width: 114px !important;
}

.w-114 {
  width: 114px !important;
}

.max-w-114 {
  max-width: 114px !important;
}

.min-h-114 {
  min-height: 114px !important;
}

.h-114 {
  height: 114px !important;
}

.max-h-114 {
  max-height: 114px !important;
}

.lh-114 {
  line-height: 114px !important;
}

.min-w-115 {
  min-width: 115px !important;
}

.w-115 {
  width: 115px !important;
}

.max-w-115 {
  max-width: 115px !important;
}

.min-h-115 {
  min-height: 115px !important;
}

.h-115 {
  height: 115px !important;
}

.max-h-115 {
  max-height: 115px !important;
}

.lh-115 {
  line-height: 115px !important;
}

.min-w-116 {
  min-width: 116px !important;
}

.w-116 {
  width: 116px !important;
}

.max-w-116 {
  max-width: 116px !important;
}

.min-h-116 {
  min-height: 116px !important;
}

.h-116 {
  height: 116px !important;
}

.max-h-116 {
  max-height: 116px !important;
}

.lh-116 {
  line-height: 116px !important;
}

.min-w-117 {
  min-width: 117px !important;
}

.w-117 {
  width: 117px !important;
}

.max-w-117 {
  max-width: 117px !important;
}

.min-h-117 {
  min-height: 117px !important;
}

.h-117 {
  height: 117px !important;
}

.max-h-117 {
  max-height: 117px !important;
}

.lh-117 {
  line-height: 117px !important;
}

.min-w-118 {
  min-width: 118px !important;
}

.w-118 {
  width: 118px !important;
}

.max-w-118 {
  max-width: 118px !important;
}

.min-h-118 {
  min-height: 118px !important;
}

.h-118 {
  height: 118px !important;
}

.max-h-118 {
  max-height: 118px !important;
}

.lh-118 {
  line-height: 118px !important;
}

.min-w-119 {
  min-width: 119px !important;
}

.w-119 {
  width: 119px !important;
}

.max-w-119 {
  max-width: 119px !important;
}

.min-h-119 {
  min-height: 119px !important;
}

.h-119 {
  height: 119px !important;
}

.max-h-119 {
  max-height: 119px !important;
}

.lh-119 {
  line-height: 119px !important;
}

.min-w-120 {
  min-width: 120px !important;
}

.w-120 {
  width: 120px !important;
}

.max-w-120 {
  max-width: 120px !important;
}

.min-h-120 {
  min-height: 120px !important;
}

.h-120 {
  height: 120px !important;
}

.max-h-120 {
  max-height: 120px !important;
}

.lh-120 {
  line-height: 120px !important;
}

.min-w-121 {
  min-width: 121px !important;
}

.w-121 {
  width: 121px !important;
}

.max-w-121 {
  max-width: 121px !important;
}

.min-h-121 {
  min-height: 121px !important;
}

.h-121 {
  height: 121px !important;
}

.max-h-121 {
  max-height: 121px !important;
}

.lh-121 {
  line-height: 121px !important;
}

.min-w-122 {
  min-width: 122px !important;
}

.w-122 {
  width: 122px !important;
}

.max-w-122 {
  max-width: 122px !important;
}

.min-h-122 {
  min-height: 122px !important;
}

.h-122 {
  height: 122px !important;
}

.max-h-122 {
  max-height: 122px !important;
}

.lh-122 {
  line-height: 122px !important;
}

.min-w-123 {
  min-width: 123px !important;
}

.w-123 {
  width: 123px !important;
}

.max-w-123 {
  max-width: 123px !important;
}

.min-h-123 {
  min-height: 123px !important;
}

.h-123 {
  height: 123px !important;
}

.max-h-123 {
  max-height: 123px !important;
}

.lh-123 {
  line-height: 123px !important;
}

.min-w-124 {
  min-width: 124px !important;
}

.w-124 {
  width: 124px !important;
}

.max-w-124 {
  max-width: 124px !important;
}

.min-h-124 {
  min-height: 124px !important;
}

.h-124 {
  height: 124px !important;
}

.max-h-124 {
  max-height: 124px !important;
}

.lh-124 {
  line-height: 124px !important;
}

.min-w-125 {
  min-width: 125px !important;
}

.w-125 {
  width: 125px !important;
}

.max-w-125 {
  max-width: 125px !important;
}

.min-h-125 {
  min-height: 125px !important;
}

.h-125 {
  height: 125px !important;
}

.max-h-125 {
  max-height: 125px !important;
}

.lh-125 {
  line-height: 125px !important;
}

.min-w-126 {
  min-width: 126px !important;
}

.w-126 {
  width: 126px !important;
}

.max-w-126 {
  max-width: 126px !important;
}

.min-h-126 {
  min-height: 126px !important;
}

.h-126 {
  height: 126px !important;
}

.max-h-126 {
  max-height: 126px !important;
}

.lh-126 {
  line-height: 126px !important;
}

.min-w-127 {
  min-width: 127px !important;
}

.w-127 {
  width: 127px !important;
}

.max-w-127 {
  max-width: 127px !important;
}

.min-h-127 {
  min-height: 127px !important;
}

.h-127 {
  height: 127px !important;
}

.max-h-127 {
  max-height: 127px !important;
}

.lh-127 {
  line-height: 127px !important;
}

.min-w-128 {
  min-width: 128px !important;
}

.w-128 {
  width: 128px !important;
}

.max-w-128 {
  max-width: 128px !important;
}

.min-h-128 {
  min-height: 128px !important;
}

.h-128 {
  height: 128px !important;
}

.max-h-128 {
  max-height: 128px !important;
}

.lh-128 {
  line-height: 128px !important;
}

.min-w-129 {
  min-width: 129px !important;
}

.w-129 {
  width: 129px !important;
}

.max-w-129 {
  max-width: 129px !important;
}

.min-h-129 {
  min-height: 129px !important;
}

.h-129 {
  height: 129px !important;
}

.max-h-129 {
  max-height: 129px !important;
}

.lh-129 {
  line-height: 129px !important;
}

.min-w-130 {
  min-width: 130px !important;
}

.w-130 {
  width: 130px !important;
}

.max-w-130 {
  max-width: 130px !important;
}

.min-h-130 {
  min-height: 130px !important;
}

.h-130 {
  height: 130px !important;
}

.max-h-130 {
  max-height: 130px !important;
}

.lh-130 {
  line-height: 130px !important;
}

.min-w-131 {
  min-width: 131px !important;
}

.w-131 {
  width: 131px !important;
}

.max-w-131 {
  max-width: 131px !important;
}

.min-h-131 {
  min-height: 131px !important;
}

.h-131 {
  height: 131px !important;
}

.max-h-131 {
  max-height: 131px !important;
}

.lh-131 {
  line-height: 131px !important;
}

.min-w-132 {
  min-width: 132px !important;
}

.w-132 {
  width: 132px !important;
}

.max-w-132 {
  max-width: 132px !important;
}

.min-h-132 {
  min-height: 132px !important;
}

.h-132 {
  height: 132px !important;
}

.max-h-132 {
  max-height: 132px !important;
}

.lh-132 {
  line-height: 132px !important;
}

.min-w-133 {
  min-width: 133px !important;
}

.w-133 {
  width: 133px !important;
}

.max-w-133 {
  max-width: 133px !important;
}

.min-h-133 {
  min-height: 133px !important;
}

.h-133 {
  height: 133px !important;
}

.max-h-133 {
  max-height: 133px !important;
}

.lh-133 {
  line-height: 133px !important;
}

.min-w-134 {
  min-width: 134px !important;
}

.w-134 {
  width: 134px !important;
}

.max-w-134 {
  max-width: 134px !important;
}

.min-h-134 {
  min-height: 134px !important;
}

.h-134 {
  height: 134px !important;
}

.max-h-134 {
  max-height: 134px !important;
}

.lh-134 {
  line-height: 134px !important;
}

.min-w-135 {
  min-width: 135px !important;
}

.w-135 {
  width: 135px !important;
}

.max-w-135 {
  max-width: 135px !important;
}

.min-h-135 {
  min-height: 135px !important;
}

.h-135 {
  height: 135px !important;
}

.max-h-135 {
  max-height: 135px !important;
}

.lh-135 {
  line-height: 135px !important;
}

.min-w-136 {
  min-width: 136px !important;
}

.w-136 {
  width: 136px !important;
}

.max-w-136 {
  max-width: 136px !important;
}

.min-h-136 {
  min-height: 136px !important;
}

.h-136 {
  height: 136px !important;
}

.max-h-136 {
  max-height: 136px !important;
}

.lh-136 {
  line-height: 136px !important;
}

.min-w-137 {
  min-width: 137px !important;
}

.w-137 {
  width: 137px !important;
}

.max-w-137 {
  max-width: 137px !important;
}

.min-h-137 {
  min-height: 137px !important;
}

.h-137 {
  height: 137px !important;
}

.max-h-137 {
  max-height: 137px !important;
}

.lh-137 {
  line-height: 137px !important;
}

.min-w-138 {
  min-width: 138px !important;
}

.w-138 {
  width: 138px !important;
}

.max-w-138 {
  max-width: 138px !important;
}

.min-h-138 {
  min-height: 138px !important;
}

.h-138 {
  height: 138px !important;
}

.max-h-138 {
  max-height: 138px !important;
}

.lh-138 {
  line-height: 138px !important;
}

.min-w-139 {
  min-width: 139px !important;
}

.w-139 {
  width: 139px !important;
}

.max-w-139 {
  max-width: 139px !important;
}

.min-h-139 {
  min-height: 139px !important;
}

.h-139 {
  height: 139px !important;
}

.max-h-139 {
  max-height: 139px !important;
}

.lh-139 {
  line-height: 139px !important;
}

.min-w-140 {
  min-width: 140px !important;
}

.w-140 {
  width: 140px !important;
}

.max-w-140 {
  max-width: 140px !important;
}

.min-h-140 {
  min-height: 140px !important;
}

.h-140 {
  height: 140px !important;
}

.max-h-140 {
  max-height: 140px !important;
}

.lh-140 {
  line-height: 140px !important;
}

.min-w-141 {
  min-width: 141px !important;
}

.w-141 {
  width: 141px !important;
}

.max-w-141 {
  max-width: 141px !important;
}

.min-h-141 {
  min-height: 141px !important;
}

.h-141 {
  height: 141px !important;
}

.max-h-141 {
  max-height: 141px !important;
}

.lh-141 {
  line-height: 141px !important;
}

.min-w-142 {
  min-width: 142px !important;
}

.w-142 {
  width: 142px !important;
}

.max-w-142 {
  max-width: 142px !important;
}

.min-h-142 {
  min-height: 142px !important;
}

.h-142 {
  height: 142px !important;
}

.max-h-142 {
  max-height: 142px !important;
}

.lh-142 {
  line-height: 142px !important;
}

.min-w-143 {
  min-width: 143px !important;
}

.w-143 {
  width: 143px !important;
}

.max-w-143 {
  max-width: 143px !important;
}

.min-h-143 {
  min-height: 143px !important;
}

.h-143 {
  height: 143px !important;
}

.max-h-143 {
  max-height: 143px !important;
}

.lh-143 {
  line-height: 143px !important;
}

.min-w-144 {
  min-width: 144px !important;
}

.w-144 {
  width: 144px !important;
}

.max-w-144 {
  max-width: 144px !important;
}

.min-h-144 {
  min-height: 144px !important;
}

.h-144 {
  height: 144px !important;
}

.max-h-144 {
  max-height: 144px !important;
}

.lh-144 {
  line-height: 144px !important;
}

.min-w-145 {
  min-width: 145px !important;
}

.w-145 {
  width: 145px !important;
}

.max-w-145 {
  max-width: 145px !important;
}

.min-h-145 {
  min-height: 145px !important;
}

.h-145 {
  height: 145px !important;
}

.max-h-145 {
  max-height: 145px !important;
}

.lh-145 {
  line-height: 145px !important;
}

.min-w-146 {
  min-width: 146px !important;
}

.w-146 {
  width: 146px !important;
}

.max-w-146 {
  max-width: 146px !important;
}

.min-h-146 {
  min-height: 146px !important;
}

.h-146 {
  height: 146px !important;
}

.max-h-146 {
  max-height: 146px !important;
}

.lh-146 {
  line-height: 146px !important;
}

.min-w-147 {
  min-width: 147px !important;
}

.w-147 {
  width: 147px !important;
}

.max-w-147 {
  max-width: 147px !important;
}

.min-h-147 {
  min-height: 147px !important;
}

.h-147 {
  height: 147px !important;
}

.max-h-147 {
  max-height: 147px !important;
}

.lh-147 {
  line-height: 147px !important;
}

.min-w-148 {
  min-width: 148px !important;
}

.w-148 {
  width: 148px !important;
}

.max-w-148 {
  max-width: 148px !important;
}

.min-h-148 {
  min-height: 148px !important;
}

.h-148 {
  height: 148px !important;
}

.max-h-148 {
  max-height: 148px !important;
}

.lh-148 {
  line-height: 148px !important;
}

.min-w-149 {
  min-width: 149px !important;
}

.w-149 {
  width: 149px !important;
}

.max-w-149 {
  max-width: 149px !important;
}

.min-h-149 {
  min-height: 149px !important;
}

.h-149 {
  height: 149px !important;
}

.max-h-149 {
  max-height: 149px !important;
}

.lh-149 {
  line-height: 149px !important;
}

.min-w-150 {
  min-width: 150px !important;
}

.w-150 {
  width: 150px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.min-h-150 {
  min-height: 150px !important;
}

.h-150 {
  height: 150px !important;
}

.max-h-150 {
  max-height: 150px !important;
}

.lh-150 {
  line-height: 150px !important;
}

.min-w-151 {
  min-width: 151px !important;
}

.w-151 {
  width: 151px !important;
}

.max-w-151 {
  max-width: 151px !important;
}

.min-h-151 {
  min-height: 151px !important;
}

.h-151 {
  height: 151px !important;
}

.max-h-151 {
  max-height: 151px !important;
}

.lh-151 {
  line-height: 151px !important;
}

.min-w-152 {
  min-width: 152px !important;
}

.w-152 {
  width: 152px !important;
}

.max-w-152 {
  max-width: 152px !important;
}

.min-h-152 {
  min-height: 152px !important;
}

.h-152 {
  height: 152px !important;
}

.max-h-152 {
  max-height: 152px !important;
}

.lh-152 {
  line-height: 152px !important;
}

.min-w-153 {
  min-width: 153px !important;
}

.w-153 {
  width: 153px !important;
}

.max-w-153 {
  max-width: 153px !important;
}

.min-h-153 {
  min-height: 153px !important;
}

.h-153 {
  height: 153px !important;
}

.max-h-153 {
  max-height: 153px !important;
}

.lh-153 {
  line-height: 153px !important;
}

.min-w-154 {
  min-width: 154px !important;
}

.w-154 {
  width: 154px !important;
}

.max-w-154 {
  max-width: 154px !important;
}

.min-h-154 {
  min-height: 154px !important;
}

.h-154 {
  height: 154px !important;
}

.max-h-154 {
  max-height: 154px !important;
}

.lh-154 {
  line-height: 154px !important;
}

.min-w-155 {
  min-width: 155px !important;
}

.w-155 {
  width: 155px !important;
}

.max-w-155 {
  max-width: 155px !important;
}

.min-h-155 {
  min-height: 155px !important;
}

.h-155 {
  height: 155px !important;
}

.max-h-155 {
  max-height: 155px !important;
}

.lh-155 {
  line-height: 155px !important;
}

.min-w-156 {
  min-width: 156px !important;
}

.w-156 {
  width: 156px !important;
}

.max-w-156 {
  max-width: 156px !important;
}

.min-h-156 {
  min-height: 156px !important;
}

.h-156 {
  height: 156px !important;
}

.max-h-156 {
  max-height: 156px !important;
}

.lh-156 {
  line-height: 156px !important;
}

.min-w-157 {
  min-width: 157px !important;
}

.w-157 {
  width: 157px !important;
}

.max-w-157 {
  max-width: 157px !important;
}

.min-h-157 {
  min-height: 157px !important;
}

.h-157 {
  height: 157px !important;
}

.max-h-157 {
  max-height: 157px !important;
}

.lh-157 {
  line-height: 157px !important;
}

.min-w-158 {
  min-width: 158px !important;
}

.w-158 {
  width: 158px !important;
}

.max-w-158 {
  max-width: 158px !important;
}

.min-h-158 {
  min-height: 158px !important;
}

.h-158 {
  height: 158px !important;
}

.max-h-158 {
  max-height: 158px !important;
}

.lh-158 {
  line-height: 158px !important;
}

.min-w-159 {
  min-width: 159px !important;
}

.w-159 {
  width: 159px !important;
}

.max-w-159 {
  max-width: 159px !important;
}

.min-h-159 {
  min-height: 159px !important;
}

.h-159 {
  height: 159px !important;
}

.max-h-159 {
  max-height: 159px !important;
}

.lh-159 {
  line-height: 159px !important;
}

.min-w-160 {
  min-width: 160px !important;
}

.w-160 {
  width: 160px !important;
}

.max-w-160 {
  max-width: 160px !important;
}

.min-h-160 {
  min-height: 160px !important;
}

.h-160 {
  height: 160px !important;
}

.max-h-160 {
  max-height: 160px !important;
}

.lh-160 {
  line-height: 160px !important;
}

.min-w-161 {
  min-width: 161px !important;
}

.w-161 {
  width: 161px !important;
}

.max-w-161 {
  max-width: 161px !important;
}

.min-h-161 {
  min-height: 161px !important;
}

.h-161 {
  height: 161px !important;
}

.max-h-161 {
  max-height: 161px !important;
}

.lh-161 {
  line-height: 161px !important;
}

.min-w-162 {
  min-width: 162px !important;
}

.w-162 {
  width: 162px !important;
}

.max-w-162 {
  max-width: 162px !important;
}

.min-h-162 {
  min-height: 162px !important;
}

.h-162 {
  height: 162px !important;
}

.max-h-162 {
  max-height: 162px !important;
}

.lh-162 {
  line-height: 162px !important;
}

.min-w-163 {
  min-width: 163px !important;
}

.w-163 {
  width: 163px !important;
}

.max-w-163 {
  max-width: 163px !important;
}

.min-h-163 {
  min-height: 163px !important;
}

.h-163 {
  height: 163px !important;
}

.max-h-163 {
  max-height: 163px !important;
}

.lh-163 {
  line-height: 163px !important;
}

.min-w-164 {
  min-width: 164px !important;
}

.w-164 {
  width: 164px !important;
}

.max-w-164 {
  max-width: 164px !important;
}

.min-h-164 {
  min-height: 164px !important;
}

.h-164 {
  height: 164px !important;
}

.max-h-164 {
  max-height: 164px !important;
}

.lh-164 {
  line-height: 164px !important;
}

.min-w-165 {
  min-width: 165px !important;
}

.w-165 {
  width: 165px !important;
}

.max-w-165 {
  max-width: 165px !important;
}

.min-h-165 {
  min-height: 165px !important;
}

.h-165 {
  height: 165px !important;
}

.max-h-165 {
  max-height: 165px !important;
}

.lh-165 {
  line-height: 165px !important;
}

.min-w-166 {
  min-width: 166px !important;
}

.w-166 {
  width: 166px !important;
}

.max-w-166 {
  max-width: 166px !important;
}

.min-h-166 {
  min-height: 166px !important;
}

.h-166 {
  height: 166px !important;
}

.max-h-166 {
  max-height: 166px !important;
}

.lh-166 {
  line-height: 166px !important;
}

.min-w-167 {
  min-width: 167px !important;
}

.w-167 {
  width: 167px !important;
}

.max-w-167 {
  max-width: 167px !important;
}

.min-h-167 {
  min-height: 167px !important;
}

.h-167 {
  height: 167px !important;
}

.max-h-167 {
  max-height: 167px !important;
}

.lh-167 {
  line-height: 167px !important;
}

.min-w-168 {
  min-width: 168px !important;
}

.w-168 {
  width: 168px !important;
}

.max-w-168 {
  max-width: 168px !important;
}

.min-h-168 {
  min-height: 168px !important;
}

.h-168 {
  height: 168px !important;
}

.max-h-168 {
  max-height: 168px !important;
}

.lh-168 {
  line-height: 168px !important;
}

.min-w-169 {
  min-width: 169px !important;
}

.w-169 {
  width: 169px !important;
}

.max-w-169 {
  max-width: 169px !important;
}

.min-h-169 {
  min-height: 169px !important;
}

.h-169 {
  height: 169px !important;
}

.max-h-169 {
  max-height: 169px !important;
}

.lh-169 {
  line-height: 169px !important;
}

.min-w-170 {
  min-width: 170px !important;
}

.w-170 {
  width: 170px !important;
}

.max-w-170 {
  max-width: 170px !important;
}

.min-h-170 {
  min-height: 170px !important;
}

.h-170 {
  height: 170px !important;
}

.max-h-170 {
  max-height: 170px !important;
}

.lh-170 {
  line-height: 170px !important;
}

.min-w-171 {
  min-width: 171px !important;
}

.w-171 {
  width: 171px !important;
}

.max-w-171 {
  max-width: 171px !important;
}

.min-h-171 {
  min-height: 171px !important;
}

.h-171 {
  height: 171px !important;
}

.max-h-171 {
  max-height: 171px !important;
}

.lh-171 {
  line-height: 171px !important;
}

.min-w-172 {
  min-width: 172px !important;
}

.w-172 {
  width: 172px !important;
}

.max-w-172 {
  max-width: 172px !important;
}

.min-h-172 {
  min-height: 172px !important;
}

.h-172 {
  height: 172px !important;
}

.max-h-172 {
  max-height: 172px !important;
}

.lh-172 {
  line-height: 172px !important;
}

.min-w-173 {
  min-width: 173px !important;
}

.w-173 {
  width: 173px !important;
}

.max-w-173 {
  max-width: 173px !important;
}

.min-h-173 {
  min-height: 173px !important;
}

.h-173 {
  height: 173px !important;
}

.max-h-173 {
  max-height: 173px !important;
}

.lh-173 {
  line-height: 173px !important;
}

.min-w-174 {
  min-width: 174px !important;
}

.w-174 {
  width: 174px !important;
}

.max-w-174 {
  max-width: 174px !important;
}

.min-h-174 {
  min-height: 174px !important;
}

.h-174 {
  height: 174px !important;
}

.max-h-174 {
  max-height: 174px !important;
}

.lh-174 {
  line-height: 174px !important;
}

.min-w-175 {
  min-width: 175px !important;
}

.w-175 {
  width: 175px !important;
}

.max-w-175 {
  max-width: 175px !important;
}

.min-h-175 {
  min-height: 175px !important;
}

.h-175 {
  height: 175px !important;
}

.max-h-175 {
  max-height: 175px !important;
}

.lh-175 {
  line-height: 175px !important;
}

.min-w-176 {
  min-width: 176px !important;
}

.w-176 {
  width: 176px !important;
}

.max-w-176 {
  max-width: 176px !important;
}

.min-h-176 {
  min-height: 176px !important;
}

.h-176 {
  height: 176px !important;
}

.max-h-176 {
  max-height: 176px !important;
}

.lh-176 {
  line-height: 176px !important;
}

.min-w-177 {
  min-width: 177px !important;
}

.w-177 {
  width: 177px !important;
}

.max-w-177 {
  max-width: 177px !important;
}

.min-h-177 {
  min-height: 177px !important;
}

.h-177 {
  height: 177px !important;
}

.max-h-177 {
  max-height: 177px !important;
}

.lh-177 {
  line-height: 177px !important;
}

.min-w-178 {
  min-width: 178px !important;
}

.w-178 {
  width: 178px !important;
}

.max-w-178 {
  max-width: 178px !important;
}

.min-h-178 {
  min-height: 178px !important;
}

.h-178 {
  height: 178px !important;
}

.max-h-178 {
  max-height: 178px !important;
}

.lh-178 {
  line-height: 178px !important;
}

.min-w-179 {
  min-width: 179px !important;
}

.w-179 {
  width: 179px !important;
}

.max-w-179 {
  max-width: 179px !important;
}

.min-h-179 {
  min-height: 179px !important;
}

.h-179 {
  height: 179px !important;
}

.max-h-179 {
  max-height: 179px !important;
}

.lh-179 {
  line-height: 179px !important;
}

.min-w-180 {
  min-width: 180px !important;
}

.w-180 {
  width: 180px !important;
}

.max-w-180 {
  max-width: 180px !important;
}

.min-h-180 {
  min-height: 180px !important;
}

.h-180 {
  height: 180px !important;
}

.max-h-180 {
  max-height: 180px !important;
}

.lh-180 {
  line-height: 180px !important;
}

.min-w-181 {
  min-width: 181px !important;
}

.w-181 {
  width: 181px !important;
}

.max-w-181 {
  max-width: 181px !important;
}

.min-h-181 {
  min-height: 181px !important;
}

.h-181 {
  height: 181px !important;
}

.max-h-181 {
  max-height: 181px !important;
}

.lh-181 {
  line-height: 181px !important;
}

.min-w-182 {
  min-width: 182px !important;
}

.w-182 {
  width: 182px !important;
}

.max-w-182 {
  max-width: 182px !important;
}

.min-h-182 {
  min-height: 182px !important;
}

.h-182 {
  height: 182px !important;
}

.max-h-182 {
  max-height: 182px !important;
}

.lh-182 {
  line-height: 182px !important;
}

.min-w-183 {
  min-width: 183px !important;
}

.w-183 {
  width: 183px !important;
}

.max-w-183 {
  max-width: 183px !important;
}

.min-h-183 {
  min-height: 183px !important;
}

.h-183 {
  height: 183px !important;
}

.max-h-183 {
  max-height: 183px !important;
}

.lh-183 {
  line-height: 183px !important;
}

.min-w-184 {
  min-width: 184px !important;
}

.w-184 {
  width: 184px !important;
}

.max-w-184 {
  max-width: 184px !important;
}

.min-h-184 {
  min-height: 184px !important;
}

.h-184 {
  height: 184px !important;
}

.max-h-184 {
  max-height: 184px !important;
}

.lh-184 {
  line-height: 184px !important;
}

.min-w-185 {
  min-width: 185px !important;
}

.w-185 {
  width: 185px !important;
}

.max-w-185 {
  max-width: 185px !important;
}

.min-h-185 {
  min-height: 185px !important;
}

.h-185 {
  height: 185px !important;
}

.max-h-185 {
  max-height: 185px !important;
}

.lh-185 {
  line-height: 185px !important;
}

.min-w-186 {
  min-width: 186px !important;
}

.w-186 {
  width: 186px !important;
}

.max-w-186 {
  max-width: 186px !important;
}

.min-h-186 {
  min-height: 186px !important;
}

.h-186 {
  height: 186px !important;
}

.max-h-186 {
  max-height: 186px !important;
}

.lh-186 {
  line-height: 186px !important;
}

.min-w-187 {
  min-width: 187px !important;
}

.w-187 {
  width: 187px !important;
}

.max-w-187 {
  max-width: 187px !important;
}

.min-h-187 {
  min-height: 187px !important;
}

.h-187 {
  height: 187px !important;
}

.max-h-187 {
  max-height: 187px !important;
}

.lh-187 {
  line-height: 187px !important;
}

.min-w-188 {
  min-width: 188px !important;
}

.w-188 {
  width: 188px !important;
}

.max-w-188 {
  max-width: 188px !important;
}

.min-h-188 {
  min-height: 188px !important;
}

.h-188 {
  height: 188px !important;
}

.max-h-188 {
  max-height: 188px !important;
}

.lh-188 {
  line-height: 188px !important;
}

.min-w-189 {
  min-width: 189px !important;
}

.w-189 {
  width: 189px !important;
}

.max-w-189 {
  max-width: 189px !important;
}

.min-h-189 {
  min-height: 189px !important;
}

.h-189 {
  height: 189px !important;
}

.max-h-189 {
  max-height: 189px !important;
}

.lh-189 {
  line-height: 189px !important;
}

.min-w-190 {
  min-width: 190px !important;
}

.w-190 {
  width: 190px !important;
}

.max-w-190 {
  max-width: 190px !important;
}

.min-h-190 {
  min-height: 190px !important;
}

.h-190 {
  height: 190px !important;
}

.max-h-190 {
  max-height: 190px !important;
}

.lh-190 {
  line-height: 190px !important;
}

.min-w-191 {
  min-width: 191px !important;
}

.w-191 {
  width: 191px !important;
}

.max-w-191 {
  max-width: 191px !important;
}

.min-h-191 {
  min-height: 191px !important;
}

.h-191 {
  height: 191px !important;
}

.max-h-191 {
  max-height: 191px !important;
}

.lh-191 {
  line-height: 191px !important;
}

.min-w-192 {
  min-width: 192px !important;
}

.w-192 {
  width: 192px !important;
}

.max-w-192 {
  max-width: 192px !important;
}

.min-h-192 {
  min-height: 192px !important;
}

.h-192 {
  height: 192px !important;
}

.max-h-192 {
  max-height: 192px !important;
}

.lh-192 {
  line-height: 192px !important;
}

.min-w-193 {
  min-width: 193px !important;
}

.w-193 {
  width: 193px !important;
}

.max-w-193 {
  max-width: 193px !important;
}

.min-h-193 {
  min-height: 193px !important;
}

.h-193 {
  height: 193px !important;
}

.max-h-193 {
  max-height: 193px !important;
}

.lh-193 {
  line-height: 193px !important;
}

.min-w-194 {
  min-width: 194px !important;
}

.w-194 {
  width: 194px !important;
}

.max-w-194 {
  max-width: 194px !important;
}

.min-h-194 {
  min-height: 194px !important;
}

.h-194 {
  height: 194px !important;
}

.max-h-194 {
  max-height: 194px !important;
}

.lh-194 {
  line-height: 194px !important;
}

.min-w-195 {
  min-width: 195px !important;
}

.w-195 {
  width: 195px !important;
}

.max-w-195 {
  max-width: 195px !important;
}

.min-h-195 {
  min-height: 195px !important;
}

.h-195 {
  height: 195px !important;
}

.max-h-195 {
  max-height: 195px !important;
}

.lh-195 {
  line-height: 195px !important;
}

.min-w-196 {
  min-width: 196px !important;
}

.w-196 {
  width: 196px !important;
}

.max-w-196 {
  max-width: 196px !important;
}

.min-h-196 {
  min-height: 196px !important;
}

.h-196 {
  height: 196px !important;
}

.max-h-196 {
  max-height: 196px !important;
}

.lh-196 {
  line-height: 196px !important;
}

.min-w-197 {
  min-width: 197px !important;
}

.w-197 {
  width: 197px !important;
}

.max-w-197 {
  max-width: 197px !important;
}

.min-h-197 {
  min-height: 197px !important;
}

.h-197 {
  height: 197px !important;
}

.max-h-197 {
  max-height: 197px !important;
}

.lh-197 {
  line-height: 197px !important;
}

.min-w-198 {
  min-width: 198px !important;
}

.w-198 {
  width: 198px !important;
}

.max-w-198 {
  max-width: 198px !important;
}

.min-h-198 {
  min-height: 198px !important;
}

.h-198 {
  height: 198px !important;
}

.max-h-198 {
  max-height: 198px !important;
}

.lh-198 {
  line-height: 198px !important;
}

.min-w-199 {
  min-width: 199px !important;
}

.w-199 {
  width: 199px !important;
}

.max-w-199 {
  max-width: 199px !important;
}

.min-h-199 {
  min-height: 199px !important;
}

.h-199 {
  height: 199px !important;
}

.max-h-199 {
  max-height: 199px !important;
}

.lh-199 {
  line-height: 199px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.w-200 {
  width: 200px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.min-h-200 {
  min-height: 200px !important;
}

.h-200 {
  height: 200px !important;
}

.max-h-200 {
  max-height: 200px !important;
}

.lh-200 {
  line-height: 200px !important;
}

.min-w-201 {
  min-width: 201px !important;
}

.w-201 {
  width: 201px !important;
}

.max-w-201 {
  max-width: 201px !important;
}

.min-h-201 {
  min-height: 201px !important;
}

.h-201 {
  height: 201px !important;
}

.max-h-201 {
  max-height: 201px !important;
}

.lh-201 {
  line-height: 201px !important;
}

.min-w-202 {
  min-width: 202px !important;
}

.w-202 {
  width: 202px !important;
}

.max-w-202 {
  max-width: 202px !important;
}

.min-h-202 {
  min-height: 202px !important;
}

.h-202 {
  height: 202px !important;
}

.max-h-202 {
  max-height: 202px !important;
}

.lh-202 {
  line-height: 202px !important;
}

.min-w-203 {
  min-width: 203px !important;
}

.w-203 {
  width: 203px !important;
}

.max-w-203 {
  max-width: 203px !important;
}

.min-h-203 {
  min-height: 203px !important;
}

.h-203 {
  height: 203px !important;
}

.max-h-203 {
  max-height: 203px !important;
}

.lh-203 {
  line-height: 203px !important;
}

.min-w-204 {
  min-width: 204px !important;
}

.w-204 {
  width: 204px !important;
}

.max-w-204 {
  max-width: 204px !important;
}

.min-h-204 {
  min-height: 204px !important;
}

.h-204 {
  height: 204px !important;
}

.max-h-204 {
  max-height: 204px !important;
}

.lh-204 {
  line-height: 204px !important;
}

.min-w-205 {
  min-width: 205px !important;
}

.w-205 {
  width: 205px !important;
}

.max-w-205 {
  max-width: 205px !important;
}

.min-h-205 {
  min-height: 205px !important;
}

.h-205 {
  height: 205px !important;
}

.max-h-205 {
  max-height: 205px !important;
}

.lh-205 {
  line-height: 205px !important;
}

.min-w-206 {
  min-width: 206px !important;
}

.w-206 {
  width: 206px !important;
}

.max-w-206 {
  max-width: 206px !important;
}

.min-h-206 {
  min-height: 206px !important;
}

.h-206 {
  height: 206px !important;
}

.max-h-206 {
  max-height: 206px !important;
}

.lh-206 {
  line-height: 206px !important;
}

.min-w-207 {
  min-width: 207px !important;
}

.w-207 {
  width: 207px !important;
}

.max-w-207 {
  max-width: 207px !important;
}

.min-h-207 {
  min-height: 207px !important;
}

.h-207 {
  height: 207px !important;
}

.max-h-207 {
  max-height: 207px !important;
}

.lh-207 {
  line-height: 207px !important;
}

.min-w-208 {
  min-width: 208px !important;
}

.w-208 {
  width: 208px !important;
}

.max-w-208 {
  max-width: 208px !important;
}

.min-h-208 {
  min-height: 208px !important;
}

.h-208 {
  height: 208px !important;
}

.max-h-208 {
  max-height: 208px !important;
}

.lh-208 {
  line-height: 208px !important;
}

.min-w-209 {
  min-width: 209px !important;
}

.w-209 {
  width: 209px !important;
}

.max-w-209 {
  max-width: 209px !important;
}

.min-h-209 {
  min-height: 209px !important;
}

.h-209 {
  height: 209px !important;
}

.max-h-209 {
  max-height: 209px !important;
}

.lh-209 {
  line-height: 209px !important;
}

.min-w-210 {
  min-width: 210px !important;
}

.w-210 {
  width: 210px !important;
}

.max-w-210 {
  max-width: 210px !important;
}

.min-h-210 {
  min-height: 210px !important;
}

.h-210 {
  height: 210px !important;
}

.max-h-210 {
  max-height: 210px !important;
}

.lh-210 {
  line-height: 210px !important;
}

.min-w-211 {
  min-width: 211px !important;
}

.w-211 {
  width: 211px !important;
}

.max-w-211 {
  max-width: 211px !important;
}

.min-h-211 {
  min-height: 211px !important;
}

.h-211 {
  height: 211px !important;
}

.max-h-211 {
  max-height: 211px !important;
}

.lh-211 {
  line-height: 211px !important;
}

.min-w-212 {
  min-width: 212px !important;
}

.w-212 {
  width: 212px !important;
}

.max-w-212 {
  max-width: 212px !important;
}

.min-h-212 {
  min-height: 212px !important;
}

.h-212 {
  height: 212px !important;
}

.max-h-212 {
  max-height: 212px !important;
}

.lh-212 {
  line-height: 212px !important;
}

.min-w-213 {
  min-width: 213px !important;
}

.w-213 {
  width: 213px !important;
}

.max-w-213 {
  max-width: 213px !important;
}

.min-h-213 {
  min-height: 213px !important;
}

.h-213 {
  height: 213px !important;
}

.max-h-213 {
  max-height: 213px !important;
}

.lh-213 {
  line-height: 213px !important;
}

.min-w-214 {
  min-width: 214px !important;
}

.w-214 {
  width: 214px !important;
}

.max-w-214 {
  max-width: 214px !important;
}

.min-h-214 {
  min-height: 214px !important;
}

.h-214 {
  height: 214px !important;
}

.max-h-214 {
  max-height: 214px !important;
}

.lh-214 {
  line-height: 214px !important;
}

.min-w-215 {
  min-width: 215px !important;
}

.w-215 {
  width: 215px !important;
}

.max-w-215 {
  max-width: 215px !important;
}

.min-h-215 {
  min-height: 215px !important;
}

.h-215 {
  height: 215px !important;
}

.max-h-215 {
  max-height: 215px !important;
}

.lh-215 {
  line-height: 215px !important;
}

.min-w-216 {
  min-width: 216px !important;
}

.w-216 {
  width: 216px !important;
}

.max-w-216 {
  max-width: 216px !important;
}

.min-h-216 {
  min-height: 216px !important;
}

.h-216 {
  height: 216px !important;
}

.max-h-216 {
  max-height: 216px !important;
}

.lh-216 {
  line-height: 216px !important;
}

.min-w-217 {
  min-width: 217px !important;
}

.w-217 {
  width: 217px !important;
}

.max-w-217 {
  max-width: 217px !important;
}

.min-h-217 {
  min-height: 217px !important;
}

.h-217 {
  height: 217px !important;
}

.max-h-217 {
  max-height: 217px !important;
}

.lh-217 {
  line-height: 217px !important;
}

.min-w-218 {
  min-width: 218px !important;
}

.w-218 {
  width: 218px !important;
}

.max-w-218 {
  max-width: 218px !important;
}

.min-h-218 {
  min-height: 218px !important;
}

.h-218 {
  height: 218px !important;
}

.max-h-218 {
  max-height: 218px !important;
}

.lh-218 {
  line-height: 218px !important;
}

.min-w-219 {
  min-width: 219px !important;
}

.w-219 {
  width: 219px !important;
}

.max-w-219 {
  max-width: 219px !important;
}

.min-h-219 {
  min-height: 219px !important;
}

.h-219 {
  height: 219px !important;
}

.max-h-219 {
  max-height: 219px !important;
}

.lh-219 {
  line-height: 219px !important;
}

.min-w-220 {
  min-width: 220px !important;
}

.w-220 {
  width: 220px !important;
}

.max-w-220 {
  max-width: 220px !important;
}

.min-h-220 {
  min-height: 220px !important;
}

.h-220 {
  height: 220px !important;
}

.max-h-220 {
  max-height: 220px !important;
}

.lh-220 {
  line-height: 220px !important;
}

.min-w-221 {
  min-width: 221px !important;
}

.w-221 {
  width: 221px !important;
}

.max-w-221 {
  max-width: 221px !important;
}

.min-h-221 {
  min-height: 221px !important;
}

.h-221 {
  height: 221px !important;
}

.max-h-221 {
  max-height: 221px !important;
}

.lh-221 {
  line-height: 221px !important;
}

.min-w-222 {
  min-width: 222px !important;
}

.w-222 {
  width: 222px !important;
}

.max-w-222 {
  max-width: 222px !important;
}

.min-h-222 {
  min-height: 222px !important;
}

.h-222 {
  height: 222px !important;
}

.max-h-222 {
  max-height: 222px !important;
}

.lh-222 {
  line-height: 222px !important;
}

.min-w-223 {
  min-width: 223px !important;
}

.w-223 {
  width: 223px !important;
}

.max-w-223 {
  max-width: 223px !important;
}

.min-h-223 {
  min-height: 223px !important;
}

.h-223 {
  height: 223px !important;
}

.max-h-223 {
  max-height: 223px !important;
}

.lh-223 {
  line-height: 223px !important;
}

.min-w-224 {
  min-width: 224px !important;
}

.w-224 {
  width: 224px !important;
}

.max-w-224 {
  max-width: 224px !important;
}

.min-h-224 {
  min-height: 224px !important;
}

.h-224 {
  height: 224px !important;
}

.max-h-224 {
  max-height: 224px !important;
}

.lh-224 {
  line-height: 224px !important;
}

.min-w-225 {
  min-width: 225px !important;
}

.w-225 {
  width: 225px !important;
}

.max-w-225 {
  max-width: 225px !important;
}

.min-h-225 {
  min-height: 225px !important;
}

.h-225 {
  height: 225px !important;
}

.max-h-225 {
  max-height: 225px !important;
}

.lh-225 {
  line-height: 225px !important;
}

.min-w-226 {
  min-width: 226px !important;
}

.w-226 {
  width: 226px !important;
}

.max-w-226 {
  max-width: 226px !important;
}

.min-h-226 {
  min-height: 226px !important;
}

.h-226 {
  height: 226px !important;
}

.max-h-226 {
  max-height: 226px !important;
}

.lh-226 {
  line-height: 226px !important;
}

.min-w-227 {
  min-width: 227px !important;
}

.w-227 {
  width: 227px !important;
}

.max-w-227 {
  max-width: 227px !important;
}

.min-h-227 {
  min-height: 227px !important;
}

.h-227 {
  height: 227px !important;
}

.max-h-227 {
  max-height: 227px !important;
}

.lh-227 {
  line-height: 227px !important;
}

.min-w-228 {
  min-width: 228px !important;
}

.w-228 {
  width: 228px !important;
}

.max-w-228 {
  max-width: 228px !important;
}

.min-h-228 {
  min-height: 228px !important;
}

.h-228 {
  height: 228px !important;
}

.max-h-228 {
  max-height: 228px !important;
}

.lh-228 {
  line-height: 228px !important;
}

.min-w-229 {
  min-width: 229px !important;
}

.w-229 {
  width: 229px !important;
}

.max-w-229 {
  max-width: 229px !important;
}

.min-h-229 {
  min-height: 229px !important;
}

.h-229 {
  height: 229px !important;
}

.max-h-229 {
  max-height: 229px !important;
}

.lh-229 {
  line-height: 229px !important;
}

.min-w-230 {
  min-width: 230px !important;
}

.w-230 {
  width: 230px !important;
}

.max-w-230 {
  max-width: 230px !important;
}

.min-h-230 {
  min-height: 230px !important;
}

.h-230 {
  height: 230px !important;
}

.max-h-230 {
  max-height: 230px !important;
}

.lh-230 {
  line-height: 230px !important;
}

.min-w-231 {
  min-width: 231px !important;
}

.w-231 {
  width: 231px !important;
}

.max-w-231 {
  max-width: 231px !important;
}

.min-h-231 {
  min-height: 231px !important;
}

.h-231 {
  height: 231px !important;
}

.max-h-231 {
  max-height: 231px !important;
}

.lh-231 {
  line-height: 231px !important;
}

.min-w-232 {
  min-width: 232px !important;
}

.w-232 {
  width: 232px !important;
}

.max-w-232 {
  max-width: 232px !important;
}

.min-h-232 {
  min-height: 232px !important;
}

.h-232 {
  height: 232px !important;
}

.max-h-232 {
  max-height: 232px !important;
}

.lh-232 {
  line-height: 232px !important;
}

.min-w-233 {
  min-width: 233px !important;
}

.w-233 {
  width: 233px !important;
}

.max-w-233 {
  max-width: 233px !important;
}

.min-h-233 {
  min-height: 233px !important;
}

.h-233 {
  height: 233px !important;
}

.max-h-233 {
  max-height: 233px !important;
}

.lh-233 {
  line-height: 233px !important;
}

.min-w-234 {
  min-width: 234px !important;
}

.w-234 {
  width: 234px !important;
}

.max-w-234 {
  max-width: 234px !important;
}

.min-h-234 {
  min-height: 234px !important;
}

.h-234 {
  height: 234px !important;
}

.max-h-234 {
  max-height: 234px !important;
}

.lh-234 {
  line-height: 234px !important;
}

.min-w-235 {
  min-width: 235px !important;
}

.w-235 {
  width: 235px !important;
}

.max-w-235 {
  max-width: 235px !important;
}

.min-h-235 {
  min-height: 235px !important;
}

.h-235 {
  height: 235px !important;
}

.max-h-235 {
  max-height: 235px !important;
}

.lh-235 {
  line-height: 235px !important;
}

.min-w-236 {
  min-width: 236px !important;
}

.w-236 {
  width: 236px !important;
}

.max-w-236 {
  max-width: 236px !important;
}

.min-h-236 {
  min-height: 236px !important;
}

.h-236 {
  height: 236px !important;
}

.max-h-236 {
  max-height: 236px !important;
}

.lh-236 {
  line-height: 236px !important;
}

.min-w-237 {
  min-width: 237px !important;
}

.w-237 {
  width: 237px !important;
}

.max-w-237 {
  max-width: 237px !important;
}

.min-h-237 {
  min-height: 237px !important;
}

.h-237 {
  height: 237px !important;
}

.max-h-237 {
  max-height: 237px !important;
}

.lh-237 {
  line-height: 237px !important;
}

.min-w-238 {
  min-width: 238px !important;
}

.w-238 {
  width: 238px !important;
}

.max-w-238 {
  max-width: 238px !important;
}

.min-h-238 {
  min-height: 238px !important;
}

.h-238 {
  height: 238px !important;
}

.max-h-238 {
  max-height: 238px !important;
}

.lh-238 {
  line-height: 238px !important;
}

.min-w-239 {
  min-width: 239px !important;
}

.w-239 {
  width: 239px !important;
}

.max-w-239 {
  max-width: 239px !important;
}

.min-h-239 {
  min-height: 239px !important;
}

.h-239 {
  height: 239px !important;
}

.max-h-239 {
  max-height: 239px !important;
}

.lh-239 {
  line-height: 239px !important;
}

.min-w-240 {
  min-width: 240px !important;
}

.w-240 {
  width: 240px !important;
}

.max-w-240 {
  max-width: 240px !important;
}

.min-h-240 {
  min-height: 240px !important;
}

.h-240 {
  height: 240px !important;
}

.max-h-240 {
  max-height: 240px !important;
}

.lh-240 {
  line-height: 240px !important;
}

.min-w-241 {
  min-width: 241px !important;
}

.w-241 {
  width: 241px !important;
}

.max-w-241 {
  max-width: 241px !important;
}

.min-h-241 {
  min-height: 241px !important;
}

.h-241 {
  height: 241px !important;
}

.max-h-241 {
  max-height: 241px !important;
}

.lh-241 {
  line-height: 241px !important;
}

.min-w-242 {
  min-width: 242px !important;
}

.w-242 {
  width: 242px !important;
}

.max-w-242 {
  max-width: 242px !important;
}

.min-h-242 {
  min-height: 242px !important;
}

.h-242 {
  height: 242px !important;
}

.max-h-242 {
  max-height: 242px !important;
}

.lh-242 {
  line-height: 242px !important;
}

.min-w-243 {
  min-width: 243px !important;
}

.w-243 {
  width: 243px !important;
}

.max-w-243 {
  max-width: 243px !important;
}

.min-h-243 {
  min-height: 243px !important;
}

.h-243 {
  height: 243px !important;
}

.max-h-243 {
  max-height: 243px !important;
}

.lh-243 {
  line-height: 243px !important;
}

.min-w-244 {
  min-width: 244px !important;
}

.w-244 {
  width: 244px !important;
}

.max-w-244 {
  max-width: 244px !important;
}

.min-h-244 {
  min-height: 244px !important;
}

.h-244 {
  height: 244px !important;
}

.max-h-244 {
  max-height: 244px !important;
}

.lh-244 {
  line-height: 244px !important;
}

.min-w-245 {
  min-width: 245px !important;
}

.w-245 {
  width: 245px !important;
}

.max-w-245 {
  max-width: 245px !important;
}

.min-h-245 {
  min-height: 245px !important;
}

.h-245 {
  height: 245px !important;
}

.max-h-245 {
  max-height: 245px !important;
}

.lh-245 {
  line-height: 245px !important;
}

.min-w-246 {
  min-width: 246px !important;
}

.w-246 {
  width: 246px !important;
}

.max-w-246 {
  max-width: 246px !important;
}

.min-h-246 {
  min-height: 246px !important;
}

.h-246 {
  height: 246px !important;
}

.max-h-246 {
  max-height: 246px !important;
}

.lh-246 {
  line-height: 246px !important;
}

.min-w-247 {
  min-width: 247px !important;
}

.w-247 {
  width: 247px !important;
}

.max-w-247 {
  max-width: 247px !important;
}

.min-h-247 {
  min-height: 247px !important;
}

.h-247 {
  height: 247px !important;
}

.max-h-247 {
  max-height: 247px !important;
}

.lh-247 {
  line-height: 247px !important;
}

.min-w-248 {
  min-width: 248px !important;
}

.w-248 {
  width: 248px !important;
}

.max-w-248 {
  max-width: 248px !important;
}

.min-h-248 {
  min-height: 248px !important;
}

.h-248 {
  height: 248px !important;
}

.max-h-248 {
  max-height: 248px !important;
}

.lh-248 {
  line-height: 248px !important;
}

.min-w-249 {
  min-width: 249px !important;
}

.w-249 {
  width: 249px !important;
}

.max-w-249 {
  max-width: 249px !important;
}

.min-h-249 {
  min-height: 249px !important;
}

.h-249 {
  height: 249px !important;
}

.max-h-249 {
  max-height: 249px !important;
}

.lh-249 {
  line-height: 249px !important;
}

.min-w-250 {
  min-width: 250px !important;
}

.w-250 {
  width: 250px !important;
}

.max-w-250 {
  max-width: 250px !important;
}

.min-h-250 {
  min-height: 250px !important;
}

.h-250 {
  height: 250px !important;
}

.max-h-250 {
  max-height: 250px !important;
}

.lh-250 {
  line-height: 250px !important;
}

.min-w-251 {
  min-width: 251px !important;
}

.w-251 {
  width: 251px !important;
}

.max-w-251 {
  max-width: 251px !important;
}

.min-h-251 {
  min-height: 251px !important;
}

.h-251 {
  height: 251px !important;
}

.max-h-251 {
  max-height: 251px !important;
}

.lh-251 {
  line-height: 251px !important;
}

.min-w-252 {
  min-width: 252px !important;
}

.w-252 {
  width: 252px !important;
}

.max-w-252 {
  max-width: 252px !important;
}

.min-h-252 {
  min-height: 252px !important;
}

.h-252 {
  height: 252px !important;
}

.max-h-252 {
  max-height: 252px !important;
}

.lh-252 {
  line-height: 252px !important;
}

.min-w-253 {
  min-width: 253px !important;
}

.w-253 {
  width: 253px !important;
}

.max-w-253 {
  max-width: 253px !important;
}

.min-h-253 {
  min-height: 253px !important;
}

.h-253 {
  height: 253px !important;
}

.max-h-253 {
  max-height: 253px !important;
}

.lh-253 {
  line-height: 253px !important;
}

.min-w-254 {
  min-width: 254px !important;
}

.w-254 {
  width: 254px !important;
}

.max-w-254 {
  max-width: 254px !important;
}

.min-h-254 {
  min-height: 254px !important;
}

.h-254 {
  height: 254px !important;
}

.max-h-254 {
  max-height: 254px !important;
}

.lh-254 {
  line-height: 254px !important;
}

.min-w-255 {
  min-width: 255px !important;
}

.w-255 {
  width: 255px !important;
}

.max-w-255 {
  max-width: 255px !important;
}

.min-h-255 {
  min-height: 255px !important;
}

.h-255 {
  height: 255px !important;
}

.max-h-255 {
  max-height: 255px !important;
}

.lh-255 {
  line-height: 255px !important;
}

.min-w-256 {
  min-width: 256px !important;
}

.w-256 {
  width: 256px !important;
}

.max-w-256 {
  max-width: 256px !important;
}

.min-h-256 {
  min-height: 256px !important;
}

.h-256 {
  height: 256px !important;
}

.max-h-256 {
  max-height: 256px !important;
}

.lh-256 {
  line-height: 256px !important;
}

.min-w-257 {
  min-width: 257px !important;
}

.w-257 {
  width: 257px !important;
}

.max-w-257 {
  max-width: 257px !important;
}

.min-h-257 {
  min-height: 257px !important;
}

.h-257 {
  height: 257px !important;
}

.max-h-257 {
  max-height: 257px !important;
}

.lh-257 {
  line-height: 257px !important;
}

.min-w-258 {
  min-width: 258px !important;
}

.w-258 {
  width: 258px !important;
}

.max-w-258 {
  max-width: 258px !important;
}

.min-h-258 {
  min-height: 258px !important;
}

.h-258 {
  height: 258px !important;
}

.max-h-258 {
  max-height: 258px !important;
}

.lh-258 {
  line-height: 258px !important;
}

.min-w-259 {
  min-width: 259px !important;
}

.w-259 {
  width: 259px !important;
}

.max-w-259 {
  max-width: 259px !important;
}

.min-h-259 {
  min-height: 259px !important;
}

.h-259 {
  height: 259px !important;
}

.max-h-259 {
  max-height: 259px !important;
}

.lh-259 {
  line-height: 259px !important;
}

.min-w-260 {
  min-width: 260px !important;
}

.w-260 {
  width: 260px !important;
}

.max-w-260 {
  max-width: 260px !important;
}

.min-h-260 {
  min-height: 260px !important;
}

.h-260 {
  height: 260px !important;
}

.max-h-260 {
  max-height: 260px !important;
}

.lh-260 {
  line-height: 260px !important;
}

.min-w-261 {
  min-width: 261px !important;
}

.w-261 {
  width: 261px !important;
}

.max-w-261 {
  max-width: 261px !important;
}

.min-h-261 {
  min-height: 261px !important;
}

.h-261 {
  height: 261px !important;
}

.max-h-261 {
  max-height: 261px !important;
}

.lh-261 {
  line-height: 261px !important;
}

.min-w-262 {
  min-width: 262px !important;
}

.w-262 {
  width: 262px !important;
}

.max-w-262 {
  max-width: 262px !important;
}

.min-h-262 {
  min-height: 262px !important;
}

.h-262 {
  height: 262px !important;
}

.max-h-262 {
  max-height: 262px !important;
}

.lh-262 {
  line-height: 262px !important;
}

.min-w-263 {
  min-width: 263px !important;
}

.w-263 {
  width: 263px !important;
}

.max-w-263 {
  max-width: 263px !important;
}

.min-h-263 {
  min-height: 263px !important;
}

.h-263 {
  height: 263px !important;
}

.max-h-263 {
  max-height: 263px !important;
}

.lh-263 {
  line-height: 263px !important;
}

.min-w-264 {
  min-width: 264px !important;
}

.w-264 {
  width: 264px !important;
}

.max-w-264 {
  max-width: 264px !important;
}

.min-h-264 {
  min-height: 264px !important;
}

.h-264 {
  height: 264px !important;
}

.max-h-264 {
  max-height: 264px !important;
}

.lh-264 {
  line-height: 264px !important;
}

.min-w-265 {
  min-width: 265px !important;
}

.w-265 {
  width: 265px !important;
}

.max-w-265 {
  max-width: 265px !important;
}

.min-h-265 {
  min-height: 265px !important;
}

.h-265 {
  height: 265px !important;
}

.max-h-265 {
  max-height: 265px !important;
}

.lh-265 {
  line-height: 265px !important;
}

.min-w-266 {
  min-width: 266px !important;
}

.w-266 {
  width: 266px !important;
}

.max-w-266 {
  max-width: 266px !important;
}

.min-h-266 {
  min-height: 266px !important;
}

.h-266 {
  height: 266px !important;
}

.max-h-266 {
  max-height: 266px !important;
}

.lh-266 {
  line-height: 266px !important;
}

.min-w-267 {
  min-width: 267px !important;
}

.w-267 {
  width: 267px !important;
}

.max-w-267 {
  max-width: 267px !important;
}

.min-h-267 {
  min-height: 267px !important;
}

.h-267 {
  height: 267px !important;
}

.max-h-267 {
  max-height: 267px !important;
}

.lh-267 {
  line-height: 267px !important;
}

.min-w-268 {
  min-width: 268px !important;
}

.w-268 {
  width: 268px !important;
}

.max-w-268 {
  max-width: 268px !important;
}

.min-h-268 {
  min-height: 268px !important;
}

.h-268 {
  height: 268px !important;
}

.max-h-268 {
  max-height: 268px !important;
}

.lh-268 {
  line-height: 268px !important;
}

.min-w-269 {
  min-width: 269px !important;
}

.w-269 {
  width: 269px !important;
}

.max-w-269 {
  max-width: 269px !important;
}

.min-h-269 {
  min-height: 269px !important;
}

.h-269 {
  height: 269px !important;
}

.max-h-269 {
  max-height: 269px !important;
}

.lh-269 {
  line-height: 269px !important;
}

.min-w-270 {
  min-width: 270px !important;
}

.w-270 {
  width: 270px !important;
}

.max-w-270 {
  max-width: 270px !important;
}

.min-h-270 {
  min-height: 270px !important;
}

.h-270 {
  height: 270px !important;
}

.max-h-270 {
  max-height: 270px !important;
}

.lh-270 {
  line-height: 270px !important;
}

.min-w-271 {
  min-width: 271px !important;
}

.w-271 {
  width: 271px !important;
}

.max-w-271 {
  max-width: 271px !important;
}

.min-h-271 {
  min-height: 271px !important;
}

.h-271 {
  height: 271px !important;
}

.max-h-271 {
  max-height: 271px !important;
}

.lh-271 {
  line-height: 271px !important;
}

.min-w-272 {
  min-width: 272px !important;
}

.w-272 {
  width: 272px !important;
}

.max-w-272 {
  max-width: 272px !important;
}

.min-h-272 {
  min-height: 272px !important;
}

.h-272 {
  height: 272px !important;
}

.max-h-272 {
  max-height: 272px !important;
}

.lh-272 {
  line-height: 272px !important;
}

.min-w-273 {
  min-width: 273px !important;
}

.w-273 {
  width: 273px !important;
}

.max-w-273 {
  max-width: 273px !important;
}

.min-h-273 {
  min-height: 273px !important;
}

.h-273 {
  height: 273px !important;
}

.max-h-273 {
  max-height: 273px !important;
}

.lh-273 {
  line-height: 273px !important;
}

.min-w-274 {
  min-width: 274px !important;
}

.w-274 {
  width: 274px !important;
}

.max-w-274 {
  max-width: 274px !important;
}

.min-h-274 {
  min-height: 274px !important;
}

.h-274 {
  height: 274px !important;
}

.max-h-274 {
  max-height: 274px !important;
}

.lh-274 {
  line-height: 274px !important;
}

.min-w-275 {
  min-width: 275px !important;
}

.w-275 {
  width: 275px !important;
}

.max-w-275 {
  max-width: 275px !important;
}

.min-h-275 {
  min-height: 275px !important;
}

.h-275 {
  height: 275px !important;
}

.max-h-275 {
  max-height: 275px !important;
}

.lh-275 {
  line-height: 275px !important;
}

.min-w-276 {
  min-width: 276px !important;
}

.w-276 {
  width: 276px !important;
}

.max-w-276 {
  max-width: 276px !important;
}

.min-h-276 {
  min-height: 276px !important;
}

.h-276 {
  height: 276px !important;
}

.max-h-276 {
  max-height: 276px !important;
}

.lh-276 {
  line-height: 276px !important;
}

.min-w-277 {
  min-width: 277px !important;
}

.w-277 {
  width: 277px !important;
}

.max-w-277 {
  max-width: 277px !important;
}

.min-h-277 {
  min-height: 277px !important;
}

.h-277 {
  height: 277px !important;
}

.max-h-277 {
  max-height: 277px !important;
}

.lh-277 {
  line-height: 277px !important;
}

.min-w-278 {
  min-width: 278px !important;
}

.w-278 {
  width: 278px !important;
}

.max-w-278 {
  max-width: 278px !important;
}

.min-h-278 {
  min-height: 278px !important;
}

.h-278 {
  height: 278px !important;
}

.max-h-278 {
  max-height: 278px !important;
}

.lh-278 {
  line-height: 278px !important;
}

.min-w-279 {
  min-width: 279px !important;
}

.w-279 {
  width: 279px !important;
}

.max-w-279 {
  max-width: 279px !important;
}

.min-h-279 {
  min-height: 279px !important;
}

.h-279 {
  height: 279px !important;
}

.max-h-279 {
  max-height: 279px !important;
}

.lh-279 {
  line-height: 279px !important;
}

.min-w-280 {
  min-width: 280px !important;
}

.w-280 {
  width: 280px !important;
}

.max-w-280 {
  max-width: 280px !important;
}

.min-h-280 {
  min-height: 280px !important;
}

.h-280 {
  height: 280px !important;
}

.max-h-280 {
  max-height: 280px !important;
}

.lh-280 {
  line-height: 280px !important;
}

.min-w-281 {
  min-width: 281px !important;
}

.w-281 {
  width: 281px !important;
}

.max-w-281 {
  max-width: 281px !important;
}

.min-h-281 {
  min-height: 281px !important;
}

.h-281 {
  height: 281px !important;
}

.max-h-281 {
  max-height: 281px !important;
}

.lh-281 {
  line-height: 281px !important;
}

.min-w-282 {
  min-width: 282px !important;
}

.w-282 {
  width: 282px !important;
}

.max-w-282 {
  max-width: 282px !important;
}

.min-h-282 {
  min-height: 282px !important;
}

.h-282 {
  height: 282px !important;
}

.max-h-282 {
  max-height: 282px !important;
}

.lh-282 {
  line-height: 282px !important;
}

.min-w-283 {
  min-width: 283px !important;
}

.w-283 {
  width: 283px !important;
}

.max-w-283 {
  max-width: 283px !important;
}

.min-h-283 {
  min-height: 283px !important;
}

.h-283 {
  height: 283px !important;
}

.max-h-283 {
  max-height: 283px !important;
}

.lh-283 {
  line-height: 283px !important;
}

.min-w-284 {
  min-width: 284px !important;
}

.w-284 {
  width: 284px !important;
}

.max-w-284 {
  max-width: 284px !important;
}

.min-h-284 {
  min-height: 284px !important;
}

.h-284 {
  height: 284px !important;
}

.max-h-284 {
  max-height: 284px !important;
}

.lh-284 {
  line-height: 284px !important;
}

.min-w-285 {
  min-width: 285px !important;
}

.w-285 {
  width: 285px !important;
}

.max-w-285 {
  max-width: 285px !important;
}

.min-h-285 {
  min-height: 285px !important;
}

.h-285 {
  height: 285px !important;
}

.max-h-285 {
  max-height: 285px !important;
}

.lh-285 {
  line-height: 285px !important;
}

.min-w-286 {
  min-width: 286px !important;
}

.w-286 {
  width: 286px !important;
}

.max-w-286 {
  max-width: 286px !important;
}

.min-h-286 {
  min-height: 286px !important;
}

.h-286 {
  height: 286px !important;
}

.max-h-286 {
  max-height: 286px !important;
}

.lh-286 {
  line-height: 286px !important;
}

.min-w-287 {
  min-width: 287px !important;
}

.w-287 {
  width: 287px !important;
}

.max-w-287 {
  max-width: 287px !important;
}

.min-h-287 {
  min-height: 287px !important;
}

.h-287 {
  height: 287px !important;
}

.max-h-287 {
  max-height: 287px !important;
}

.lh-287 {
  line-height: 287px !important;
}

.min-w-288 {
  min-width: 288px !important;
}

.w-288 {
  width: 288px !important;
}

.max-w-288 {
  max-width: 288px !important;
}

.min-h-288 {
  min-height: 288px !important;
}

.h-288 {
  height: 288px !important;
}

.max-h-288 {
  max-height: 288px !important;
}

.lh-288 {
  line-height: 288px !important;
}

.min-w-289 {
  min-width: 289px !important;
}

.w-289 {
  width: 289px !important;
}

.max-w-289 {
  max-width: 289px !important;
}

.min-h-289 {
  min-height: 289px !important;
}

.h-289 {
  height: 289px !important;
}

.max-h-289 {
  max-height: 289px !important;
}

.lh-289 {
  line-height: 289px !important;
}

.min-w-290 {
  min-width: 290px !important;
}

.w-290 {
  width: 290px !important;
}

.max-w-290 {
  max-width: 290px !important;
}

.min-h-290 {
  min-height: 290px !important;
}

.h-290 {
  height: 290px !important;
}

.max-h-290 {
  max-height: 290px !important;
}

.lh-290 {
  line-height: 290px !important;
}

.min-w-291 {
  min-width: 291px !important;
}

.w-291 {
  width: 291px !important;
}

.max-w-291 {
  max-width: 291px !important;
}

.min-h-291 {
  min-height: 291px !important;
}

.h-291 {
  height: 291px !important;
}

.max-h-291 {
  max-height: 291px !important;
}

.lh-291 {
  line-height: 291px !important;
}

.min-w-292 {
  min-width: 292px !important;
}

.w-292 {
  width: 292px !important;
}

.max-w-292 {
  max-width: 292px !important;
}

.min-h-292 {
  min-height: 292px !important;
}

.h-292 {
  height: 292px !important;
}

.max-h-292 {
  max-height: 292px !important;
}

.lh-292 {
  line-height: 292px !important;
}

.min-w-293 {
  min-width: 293px !important;
}

.w-293 {
  width: 293px !important;
}

.max-w-293 {
  max-width: 293px !important;
}

.min-h-293 {
  min-height: 293px !important;
}

.h-293 {
  height: 293px !important;
}

.max-h-293 {
  max-height: 293px !important;
}

.lh-293 {
  line-height: 293px !important;
}

.min-w-294 {
  min-width: 294px !important;
}

.w-294 {
  width: 294px !important;
}

.max-w-294 {
  max-width: 294px !important;
}

.min-h-294 {
  min-height: 294px !important;
}

.h-294 {
  height: 294px !important;
}

.max-h-294 {
  max-height: 294px !important;
}

.lh-294 {
  line-height: 294px !important;
}

.min-w-295 {
  min-width: 295px !important;
}

.w-295 {
  width: 295px !important;
}

.max-w-295 {
  max-width: 295px !important;
}

.min-h-295 {
  min-height: 295px !important;
}

.h-295 {
  height: 295px !important;
}

.max-h-295 {
  max-height: 295px !important;
}

.lh-295 {
  line-height: 295px !important;
}

.min-w-296 {
  min-width: 296px !important;
}

.w-296 {
  width: 296px !important;
}

.max-w-296 {
  max-width: 296px !important;
}

.min-h-296 {
  min-height: 296px !important;
}

.h-296 {
  height: 296px !important;
}

.max-h-296 {
  max-height: 296px !important;
}

.lh-296 {
  line-height: 296px !important;
}

.min-w-297 {
  min-width: 297px !important;
}

.w-297 {
  width: 297px !important;
}

.max-w-297 {
  max-width: 297px !important;
}

.min-h-297 {
  min-height: 297px !important;
}

.h-297 {
  height: 297px !important;
}

.max-h-297 {
  max-height: 297px !important;
}

.lh-297 {
  line-height: 297px !important;
}

.min-w-298 {
  min-width: 298px !important;
}

.w-298 {
  width: 298px !important;
}

.max-w-298 {
  max-width: 298px !important;
}

.min-h-298 {
  min-height: 298px !important;
}

.h-298 {
  height: 298px !important;
}

.max-h-298 {
  max-height: 298px !important;
}

.lh-298 {
  line-height: 298px !important;
}

.min-w-299 {
  min-width: 299px !important;
}

.w-299 {
  width: 299px !important;
}

.max-w-299 {
  max-width: 299px !important;
}

.min-h-299 {
  min-height: 299px !important;
}

.h-299 {
  height: 299px !important;
}

.max-h-299 {
  max-height: 299px !important;
}

.lh-299 {
  line-height: 299px !important;
}

.min-w-300 {
  min-width: 300px !important;
}

.w-300 {
  width: 300px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

.h-300 {
  height: 300px !important;
}

.max-h-300 {
  max-height: 300px !important;
}

.lh-300 {
  line-height: 300px !important;
}

.min-w-301 {
  min-width: 301px !important;
}

.w-301 {
  width: 301px !important;
}

.max-w-301 {
  max-width: 301px !important;
}

.min-h-301 {
  min-height: 301px !important;
}

.h-301 {
  height: 301px !important;
}

.max-h-301 {
  max-height: 301px !important;
}

.lh-301 {
  line-height: 301px !important;
}

.min-w-302 {
  min-width: 302px !important;
}

.w-302 {
  width: 302px !important;
}

.max-w-302 {
  max-width: 302px !important;
}

.min-h-302 {
  min-height: 302px !important;
}

.h-302 {
  height: 302px !important;
}

.max-h-302 {
  max-height: 302px !important;
}

.lh-302 {
  line-height: 302px !important;
}

.min-w-303 {
  min-width: 303px !important;
}

.w-303 {
  width: 303px !important;
}

.max-w-303 {
  max-width: 303px !important;
}

.min-h-303 {
  min-height: 303px !important;
}

.h-303 {
  height: 303px !important;
}

.max-h-303 {
  max-height: 303px !important;
}

.lh-303 {
  line-height: 303px !important;
}

.min-w-304 {
  min-width: 304px !important;
}

.w-304 {
  width: 304px !important;
}

.max-w-304 {
  max-width: 304px !important;
}

.min-h-304 {
  min-height: 304px !important;
}

.h-304 {
  height: 304px !important;
}

.max-h-304 {
  max-height: 304px !important;
}

.lh-304 {
  line-height: 304px !important;
}

.min-w-305 {
  min-width: 305px !important;
}

.w-305 {
  width: 305px !important;
}

.max-w-305 {
  max-width: 305px !important;
}

.min-h-305 {
  min-height: 305px !important;
}

.h-305 {
  height: 305px !important;
}

.max-h-305 {
  max-height: 305px !important;
}

.lh-305 {
  line-height: 305px !important;
}

.min-w-306 {
  min-width: 306px !important;
}

.w-306 {
  width: 306px !important;
}

.max-w-306 {
  max-width: 306px !important;
}

.min-h-306 {
  min-height: 306px !important;
}

.h-306 {
  height: 306px !important;
}

.max-h-306 {
  max-height: 306px !important;
}

.lh-306 {
  line-height: 306px !important;
}

.min-w-307 {
  min-width: 307px !important;
}

.w-307 {
  width: 307px !important;
}

.max-w-307 {
  max-width: 307px !important;
}

.min-h-307 {
  min-height: 307px !important;
}

.h-307 {
  height: 307px !important;
}

.max-h-307 {
  max-height: 307px !important;
}

.lh-307 {
  line-height: 307px !important;
}

.min-w-308 {
  min-width: 308px !important;
}

.w-308 {
  width: 308px !important;
}

.max-w-308 {
  max-width: 308px !important;
}

.min-h-308 {
  min-height: 308px !important;
}

.h-308 {
  height: 308px !important;
}

.max-h-308 {
  max-height: 308px !important;
}

.lh-308 {
  line-height: 308px !important;
}

.min-w-309 {
  min-width: 309px !important;
}

.w-309 {
  width: 309px !important;
}

.max-w-309 {
  max-width: 309px !important;
}

.min-h-309 {
  min-height: 309px !important;
}

.h-309 {
  height: 309px !important;
}

.max-h-309 {
  max-height: 309px !important;
}

.lh-309 {
  line-height: 309px !important;
}

.min-w-310 {
  min-width: 310px !important;
}

.w-310 {
  width: 310px !important;
}

.max-w-310 {
  max-width: 310px !important;
}

.min-h-310 {
  min-height: 310px !important;
}

.h-310 {
  height: 310px !important;
}

.max-h-310 {
  max-height: 310px !important;
}

.lh-310 {
  line-height: 310px !important;
}

.min-w-311 {
  min-width: 311px !important;
}

.w-311 {
  width: 311px !important;
}

.max-w-311 {
  max-width: 311px !important;
}

.min-h-311 {
  min-height: 311px !important;
}

.h-311 {
  height: 311px !important;
}

.max-h-311 {
  max-height: 311px !important;
}

.lh-311 {
  line-height: 311px !important;
}

.min-w-312 {
  min-width: 312px !important;
}

.w-312 {
  width: 312px !important;
}

.max-w-312 {
  max-width: 312px !important;
}

.min-h-312 {
  min-height: 312px !important;
}

.h-312 {
  height: 312px !important;
}

.max-h-312 {
  max-height: 312px !important;
}

.lh-312 {
  line-height: 312px !important;
}

.min-w-313 {
  min-width: 313px !important;
}

.w-313 {
  width: 313px !important;
}

.max-w-313 {
  max-width: 313px !important;
}

.min-h-313 {
  min-height: 313px !important;
}

.h-313 {
  height: 313px !important;
}

.max-h-313 {
  max-height: 313px !important;
}

.lh-313 {
  line-height: 313px !important;
}

.min-w-314 {
  min-width: 314px !important;
}

.w-314 {
  width: 314px !important;
}

.max-w-314 {
  max-width: 314px !important;
}

.min-h-314 {
  min-height: 314px !important;
}

.h-314 {
  height: 314px !important;
}

.max-h-314 {
  max-height: 314px !important;
}

.lh-314 {
  line-height: 314px !important;
}

.min-w-315 {
  min-width: 315px !important;
}

.w-315 {
  width: 315px !important;
}

.max-w-315 {
  max-width: 315px !important;
}

.min-h-315 {
  min-height: 315px !important;
}

.h-315 {
  height: 315px !important;
}

.max-h-315 {
  max-height: 315px !important;
}

.lh-315 {
  line-height: 315px !important;
}

.min-w-316 {
  min-width: 316px !important;
}

.w-316 {
  width: 316px !important;
}

.max-w-316 {
  max-width: 316px !important;
}

.min-h-316 {
  min-height: 316px !important;
}

.h-316 {
  height: 316px !important;
}

.max-h-316 {
  max-height: 316px !important;
}

.lh-316 {
  line-height: 316px !important;
}

.min-w-317 {
  min-width: 317px !important;
}

.w-317 {
  width: 317px !important;
}

.max-w-317 {
  max-width: 317px !important;
}

.min-h-317 {
  min-height: 317px !important;
}

.h-317 {
  height: 317px !important;
}

.max-h-317 {
  max-height: 317px !important;
}

.lh-317 {
  line-height: 317px !important;
}

.min-w-318 {
  min-width: 318px !important;
}

.w-318 {
  width: 318px !important;
}

.max-w-318 {
  max-width: 318px !important;
}

.min-h-318 {
  min-height: 318px !important;
}

.h-318 {
  height: 318px !important;
}

.max-h-318 {
  max-height: 318px !important;
}

.lh-318 {
  line-height: 318px !important;
}

.min-w-319 {
  min-width: 319px !important;
}

.w-319 {
  width: 319px !important;
}

.max-w-319 {
  max-width: 319px !important;
}

.min-h-319 {
  min-height: 319px !important;
}

.h-319 {
  height: 319px !important;
}

.max-h-319 {
  max-height: 319px !important;
}

.lh-319 {
  line-height: 319px !important;
}

.min-w-320 {
  min-width: 320px !important;
}

.w-320 {
  width: 320px !important;
}

.max-w-320 {
  max-width: 320px !important;
}

.min-h-320 {
  min-height: 320px !important;
}

.h-320 {
  height: 320px !important;
}

.max-h-320 {
  max-height: 320px !important;
}

.lh-320 {
  line-height: 320px !important;
}

.min-w-321 {
  min-width: 321px !important;
}

.w-321 {
  width: 321px !important;
}

.max-w-321 {
  max-width: 321px !important;
}

.min-h-321 {
  min-height: 321px !important;
}

.h-321 {
  height: 321px !important;
}

.max-h-321 {
  max-height: 321px !important;
}

.lh-321 {
  line-height: 321px !important;
}

.min-w-322 {
  min-width: 322px !important;
}

.w-322 {
  width: 322px !important;
}

.max-w-322 {
  max-width: 322px !important;
}

.min-h-322 {
  min-height: 322px !important;
}

.h-322 {
  height: 322px !important;
}

.max-h-322 {
  max-height: 322px !important;
}

.lh-322 {
  line-height: 322px !important;
}

.min-w-323 {
  min-width: 323px !important;
}

.w-323 {
  width: 323px !important;
}

.max-w-323 {
  max-width: 323px !important;
}

.min-h-323 {
  min-height: 323px !important;
}

.h-323 {
  height: 323px !important;
}

.max-h-323 {
  max-height: 323px !important;
}

.lh-323 {
  line-height: 323px !important;
}

.min-w-324 {
  min-width: 324px !important;
}

.w-324 {
  width: 324px !important;
}

.max-w-324 {
  max-width: 324px !important;
}

.min-h-324 {
  min-height: 324px !important;
}

.h-324 {
  height: 324px !important;
}

.max-h-324 {
  max-height: 324px !important;
}

.lh-324 {
  line-height: 324px !important;
}

.min-w-325 {
  min-width: 325px !important;
}

.w-325 {
  width: 325px !important;
}

.max-w-325 {
  max-width: 325px !important;
}

.min-h-325 {
  min-height: 325px !important;
}

.h-325 {
  height: 325px !important;
}

.max-h-325 {
  max-height: 325px !important;
}

.lh-325 {
  line-height: 325px !important;
}

.min-w-326 {
  min-width: 326px !important;
}

.w-326 {
  width: 326px !important;
}

.max-w-326 {
  max-width: 326px !important;
}

.min-h-326 {
  min-height: 326px !important;
}

.h-326 {
  height: 326px !important;
}

.max-h-326 {
  max-height: 326px !important;
}

.lh-326 {
  line-height: 326px !important;
}

.min-w-327 {
  min-width: 327px !important;
}

.w-327 {
  width: 327px !important;
}

.max-w-327 {
  max-width: 327px !important;
}

.min-h-327 {
  min-height: 327px !important;
}

.h-327 {
  height: 327px !important;
}

.max-h-327 {
  max-height: 327px !important;
}

.lh-327 {
  line-height: 327px !important;
}

.min-w-328 {
  min-width: 328px !important;
}

.w-328 {
  width: 328px !important;
}

.max-w-328 {
  max-width: 328px !important;
}

.min-h-328 {
  min-height: 328px !important;
}

.h-328 {
  height: 328px !important;
}

.max-h-328 {
  max-height: 328px !important;
}

.lh-328 {
  line-height: 328px !important;
}

.min-w-329 {
  min-width: 329px !important;
}

.w-329 {
  width: 329px !important;
}

.max-w-329 {
  max-width: 329px !important;
}

.min-h-329 {
  min-height: 329px !important;
}

.h-329 {
  height: 329px !important;
}

.max-h-329 {
  max-height: 329px !important;
}

.lh-329 {
  line-height: 329px !important;
}

.min-w-330 {
  min-width: 330px !important;
}

.w-330 {
  width: 330px !important;
}

.max-w-330 {
  max-width: 330px !important;
}

.min-h-330 {
  min-height: 330px !important;
}

.h-330 {
  height: 330px !important;
}

.max-h-330 {
  max-height: 330px !important;
}

.lh-330 {
  line-height: 330px !important;
}

.min-w-331 {
  min-width: 331px !important;
}

.w-331 {
  width: 331px !important;
}

.max-w-331 {
  max-width: 331px !important;
}

.min-h-331 {
  min-height: 331px !important;
}

.h-331 {
  height: 331px !important;
}

.max-h-331 {
  max-height: 331px !important;
}

.lh-331 {
  line-height: 331px !important;
}

.min-w-332 {
  min-width: 332px !important;
}

.w-332 {
  width: 332px !important;
}

.max-w-332 {
  max-width: 332px !important;
}

.min-h-332 {
  min-height: 332px !important;
}

.h-332 {
  height: 332px !important;
}

.max-h-332 {
  max-height: 332px !important;
}

.lh-332 {
  line-height: 332px !important;
}

.min-w-333 {
  min-width: 333px !important;
}

.w-333 {
  width: 333px !important;
}

.max-w-333 {
  max-width: 333px !important;
}

.min-h-333 {
  min-height: 333px !important;
}

.h-333 {
  height: 333px !important;
}

.max-h-333 {
  max-height: 333px !important;
}

.lh-333 {
  line-height: 333px !important;
}

.min-w-334 {
  min-width: 334px !important;
}

.w-334 {
  width: 334px !important;
}

.max-w-334 {
  max-width: 334px !important;
}

.min-h-334 {
  min-height: 334px !important;
}

.h-334 {
  height: 334px !important;
}

.max-h-334 {
  max-height: 334px !important;
}

.lh-334 {
  line-height: 334px !important;
}

.min-w-335 {
  min-width: 335px !important;
}

.w-335 {
  width: 335px !important;
}

.max-w-335 {
  max-width: 335px !important;
}

.min-h-335 {
  min-height: 335px !important;
}

.h-335 {
  height: 335px !important;
}

.max-h-335 {
  max-height: 335px !important;
}

.lh-335 {
  line-height: 335px !important;
}

.min-w-336 {
  min-width: 336px !important;
}

.w-336 {
  width: 336px !important;
}

.max-w-336 {
  max-width: 336px !important;
}

.min-h-336 {
  min-height: 336px !important;
}

.h-336 {
  height: 336px !important;
}

.max-h-336 {
  max-height: 336px !important;
}

.lh-336 {
  line-height: 336px !important;
}

.min-w-337 {
  min-width: 337px !important;
}

.w-337 {
  width: 337px !important;
}

.max-w-337 {
  max-width: 337px !important;
}

.min-h-337 {
  min-height: 337px !important;
}

.h-337 {
  height: 337px !important;
}

.max-h-337 {
  max-height: 337px !important;
}

.lh-337 {
  line-height: 337px !important;
}

.min-w-338 {
  min-width: 338px !important;
}

.w-338 {
  width: 338px !important;
}

.max-w-338 {
  max-width: 338px !important;
}

.min-h-338 {
  min-height: 338px !important;
}

.h-338 {
  height: 338px !important;
}

.max-h-338 {
  max-height: 338px !important;
}

.lh-338 {
  line-height: 338px !important;
}

.min-w-339 {
  min-width: 339px !important;
}

.w-339 {
  width: 339px !important;
}

.max-w-339 {
  max-width: 339px !important;
}

.min-h-339 {
  min-height: 339px !important;
}

.h-339 {
  height: 339px !important;
}

.max-h-339 {
  max-height: 339px !important;
}

.lh-339 {
  line-height: 339px !important;
}

.min-w-340 {
  min-width: 340px !important;
}

.w-340 {
  width: 340px !important;
}

.max-w-340 {
  max-width: 340px !important;
}

.min-h-340 {
  min-height: 340px !important;
}

.h-340 {
  height: 340px !important;
}

.max-h-340 {
  max-height: 340px !important;
}

.lh-340 {
  line-height: 340px !important;
}

.min-w-341 {
  min-width: 341px !important;
}

.w-341 {
  width: 341px !important;
}

.max-w-341 {
  max-width: 341px !important;
}

.min-h-341 {
  min-height: 341px !important;
}

.h-341 {
  height: 341px !important;
}

.max-h-341 {
  max-height: 341px !important;
}

.lh-341 {
  line-height: 341px !important;
}

.min-w-342 {
  min-width: 342px !important;
}

.w-342 {
  width: 342px !important;
}

.max-w-342 {
  max-width: 342px !important;
}

.min-h-342 {
  min-height: 342px !important;
}

.h-342 {
  height: 342px !important;
}

.max-h-342 {
  max-height: 342px !important;
}

.lh-342 {
  line-height: 342px !important;
}

.min-w-343 {
  min-width: 343px !important;
}

.w-343 {
  width: 343px !important;
}

.max-w-343 {
  max-width: 343px !important;
}

.min-h-343 {
  min-height: 343px !important;
}

.h-343 {
  height: 343px !important;
}

.max-h-343 {
  max-height: 343px !important;
}

.lh-343 {
  line-height: 343px !important;
}

.min-w-344 {
  min-width: 344px !important;
}

.w-344 {
  width: 344px !important;
}

.max-w-344 {
  max-width: 344px !important;
}

.min-h-344 {
  min-height: 344px !important;
}

.h-344 {
  height: 344px !important;
}

.max-h-344 {
  max-height: 344px !important;
}

.lh-344 {
  line-height: 344px !important;
}

.min-w-345 {
  min-width: 345px !important;
}

.w-345 {
  width: 345px !important;
}

.max-w-345 {
  max-width: 345px !important;
}

.min-h-345 {
  min-height: 345px !important;
}

.h-345 {
  height: 345px !important;
}

.max-h-345 {
  max-height: 345px !important;
}

.lh-345 {
  line-height: 345px !important;
}

.min-w-346 {
  min-width: 346px !important;
}

.w-346 {
  width: 346px !important;
}

.max-w-346 {
  max-width: 346px !important;
}

.min-h-346 {
  min-height: 346px !important;
}

.h-346 {
  height: 346px !important;
}

.max-h-346 {
  max-height: 346px !important;
}

.lh-346 {
  line-height: 346px !important;
}

.min-w-347 {
  min-width: 347px !important;
}

.w-347 {
  width: 347px !important;
}

.max-w-347 {
  max-width: 347px !important;
}

.min-h-347 {
  min-height: 347px !important;
}

.h-347 {
  height: 347px !important;
}

.max-h-347 {
  max-height: 347px !important;
}

.lh-347 {
  line-height: 347px !important;
}

.min-w-348 {
  min-width: 348px !important;
}

.w-348 {
  width: 348px !important;
}

.max-w-348 {
  max-width: 348px !important;
}

.min-h-348 {
  min-height: 348px !important;
}

.h-348 {
  height: 348px !important;
}

.max-h-348 {
  max-height: 348px !important;
}

.lh-348 {
  line-height: 348px !important;
}

.min-w-349 {
  min-width: 349px !important;
}

.w-349 {
  width: 349px !important;
}

.max-w-349 {
  max-width: 349px !important;
}

.min-h-349 {
  min-height: 349px !important;
}

.h-349 {
  height: 349px !important;
}

.max-h-349 {
  max-height: 349px !important;
}

.lh-349 {
  line-height: 349px !important;
}

.min-w-350 {
  min-width: 350px !important;
}

.w-350 {
  width: 350px !important;
}

.max-w-350 {
  max-width: 350px !important;
}

.min-h-350 {
  min-height: 350px !important;
}

.h-350 {
  height: 350px !important;
}

.max-h-350 {
  max-height: 350px !important;
}

.lh-350 {
  line-height: 350px !important;
}

.min-w-351 {
  min-width: 351px !important;
}

.w-351 {
  width: 351px !important;
}

.max-w-351 {
  max-width: 351px !important;
}

.min-h-351 {
  min-height: 351px !important;
}

.h-351 {
  height: 351px !important;
}

.max-h-351 {
  max-height: 351px !important;
}

.lh-351 {
  line-height: 351px !important;
}

.min-w-352 {
  min-width: 352px !important;
}

.w-352 {
  width: 352px !important;
}

.max-w-352 {
  max-width: 352px !important;
}

.min-h-352 {
  min-height: 352px !important;
}

.h-352 {
  height: 352px !important;
}

.max-h-352 {
  max-height: 352px !important;
}

.lh-352 {
  line-height: 352px !important;
}

.min-w-353 {
  min-width: 353px !important;
}

.w-353 {
  width: 353px !important;
}

.max-w-353 {
  max-width: 353px !important;
}

.min-h-353 {
  min-height: 353px !important;
}

.h-353 {
  height: 353px !important;
}

.max-h-353 {
  max-height: 353px !important;
}

.lh-353 {
  line-height: 353px !important;
}

.min-w-354 {
  min-width: 354px !important;
}

.w-354 {
  width: 354px !important;
}

.max-w-354 {
  max-width: 354px !important;
}

.min-h-354 {
  min-height: 354px !important;
}

.h-354 {
  height: 354px !important;
}

.max-h-354 {
  max-height: 354px !important;
}

.lh-354 {
  line-height: 354px !important;
}

.min-w-355 {
  min-width: 355px !important;
}

.w-355 {
  width: 355px !important;
}

.max-w-355 {
  max-width: 355px !important;
}

.min-h-355 {
  min-height: 355px !important;
}

.h-355 {
  height: 355px !important;
}

.max-h-355 {
  max-height: 355px !important;
}

.lh-355 {
  line-height: 355px !important;
}

.min-w-356 {
  min-width: 356px !important;
}

.w-356 {
  width: 356px !important;
}

.max-w-356 {
  max-width: 356px !important;
}

.min-h-356 {
  min-height: 356px !important;
}

.h-356 {
  height: 356px !important;
}

.max-h-356 {
  max-height: 356px !important;
}

.lh-356 {
  line-height: 356px !important;
}

.min-w-357 {
  min-width: 357px !important;
}

.w-357 {
  width: 357px !important;
}

.max-w-357 {
  max-width: 357px !important;
}

.min-h-357 {
  min-height: 357px !important;
}

.h-357 {
  height: 357px !important;
}

.max-h-357 {
  max-height: 357px !important;
}

.lh-357 {
  line-height: 357px !important;
}

.min-w-358 {
  min-width: 358px !important;
}

.w-358 {
  width: 358px !important;
}

.max-w-358 {
  max-width: 358px !important;
}

.min-h-358 {
  min-height: 358px !important;
}

.h-358 {
  height: 358px !important;
}

.max-h-358 {
  max-height: 358px !important;
}

.lh-358 {
  line-height: 358px !important;
}

.min-w-359 {
  min-width: 359px !important;
}

.w-359 {
  width: 359px !important;
}

.max-w-359 {
  max-width: 359px !important;
}

.min-h-359 {
  min-height: 359px !important;
}

.h-359 {
  height: 359px !important;
}

.max-h-359 {
  max-height: 359px !important;
}

.lh-359 {
  line-height: 359px !important;
}

.min-w-360 {
  min-width: 360px !important;
}

.w-360 {
  width: 360px !important;
}

.max-w-360 {
  max-width: 360px !important;
}

.min-h-360 {
  min-height: 360px !important;
}

.h-360 {
  height: 360px !important;
}

.max-h-360 {
  max-height: 360px !important;
}

.lh-360 {
  line-height: 360px !important;
}

.min-w-361 {
  min-width: 361px !important;
}

.w-361 {
  width: 361px !important;
}

.max-w-361 {
  max-width: 361px !important;
}

.min-h-361 {
  min-height: 361px !important;
}

.h-361 {
  height: 361px !important;
}

.max-h-361 {
  max-height: 361px !important;
}

.lh-361 {
  line-height: 361px !important;
}

.min-w-362 {
  min-width: 362px !important;
}

.w-362 {
  width: 362px !important;
}

.max-w-362 {
  max-width: 362px !important;
}

.min-h-362 {
  min-height: 362px !important;
}

.h-362 {
  height: 362px !important;
}

.max-h-362 {
  max-height: 362px !important;
}

.lh-362 {
  line-height: 362px !important;
}

.min-w-363 {
  min-width: 363px !important;
}

.w-363 {
  width: 363px !important;
}

.max-w-363 {
  max-width: 363px !important;
}

.min-h-363 {
  min-height: 363px !important;
}

.h-363 {
  height: 363px !important;
}

.max-h-363 {
  max-height: 363px !important;
}

.lh-363 {
  line-height: 363px !important;
}

.min-w-364 {
  min-width: 364px !important;
}

.w-364 {
  width: 364px !important;
}

.max-w-364 {
  max-width: 364px !important;
}

.min-h-364 {
  min-height: 364px !important;
}

.h-364 {
  height: 364px !important;
}

.max-h-364 {
  max-height: 364px !important;
}

.lh-364 {
  line-height: 364px !important;
}

.min-w-365 {
  min-width: 365px !important;
}

.w-365 {
  width: 365px !important;
}

.max-w-365 {
  max-width: 365px !important;
}

.min-h-365 {
  min-height: 365px !important;
}

.h-365 {
  height: 365px !important;
}

.max-h-365 {
  max-height: 365px !important;
}

.lh-365 {
  line-height: 365px !important;
}

.min-w-366 {
  min-width: 366px !important;
}

.w-366 {
  width: 366px !important;
}

.max-w-366 {
  max-width: 366px !important;
}

.min-h-366 {
  min-height: 366px !important;
}

.h-366 {
  height: 366px !important;
}

.max-h-366 {
  max-height: 366px !important;
}

.lh-366 {
  line-height: 366px !important;
}

.min-w-367 {
  min-width: 367px !important;
}

.w-367 {
  width: 367px !important;
}

.max-w-367 {
  max-width: 367px !important;
}

.min-h-367 {
  min-height: 367px !important;
}

.h-367 {
  height: 367px !important;
}

.max-h-367 {
  max-height: 367px !important;
}

.lh-367 {
  line-height: 367px !important;
}

.min-w-368 {
  min-width: 368px !important;
}

.w-368 {
  width: 368px !important;
}

.max-w-368 {
  max-width: 368px !important;
}

.min-h-368 {
  min-height: 368px !important;
}

.h-368 {
  height: 368px !important;
}

.max-h-368 {
  max-height: 368px !important;
}

.lh-368 {
  line-height: 368px !important;
}

.min-w-369 {
  min-width: 369px !important;
}

.w-369 {
  width: 369px !important;
}

.max-w-369 {
  max-width: 369px !important;
}

.min-h-369 {
  min-height: 369px !important;
}

.h-369 {
  height: 369px !important;
}

.max-h-369 {
  max-height: 369px !important;
}

.lh-369 {
  line-height: 369px !important;
}

.min-w-370 {
  min-width: 370px !important;
}

.w-370 {
  width: 370px !important;
}

.max-w-370 {
  max-width: 370px !important;
}

.min-h-370 {
  min-height: 370px !important;
}

.h-370 {
  height: 370px !important;
}

.max-h-370 {
  max-height: 370px !important;
}

.lh-370 {
  line-height: 370px !important;
}

.min-w-371 {
  min-width: 371px !important;
}

.w-371 {
  width: 371px !important;
}

.max-w-371 {
  max-width: 371px !important;
}

.min-h-371 {
  min-height: 371px !important;
}

.h-371 {
  height: 371px !important;
}

.max-h-371 {
  max-height: 371px !important;
}

.lh-371 {
  line-height: 371px !important;
}

.min-w-372 {
  min-width: 372px !important;
}

.w-372 {
  width: 372px !important;
}

.max-w-372 {
  max-width: 372px !important;
}

.min-h-372 {
  min-height: 372px !important;
}

.h-372 {
  height: 372px !important;
}

.max-h-372 {
  max-height: 372px !important;
}

.lh-372 {
  line-height: 372px !important;
}

.min-w-373 {
  min-width: 373px !important;
}

.w-373 {
  width: 373px !important;
}

.max-w-373 {
  max-width: 373px !important;
}

.min-h-373 {
  min-height: 373px !important;
}

.h-373 {
  height: 373px !important;
}

.max-h-373 {
  max-height: 373px !important;
}

.lh-373 {
  line-height: 373px !important;
}

.min-w-374 {
  min-width: 374px !important;
}

.w-374 {
  width: 374px !important;
}

.max-w-374 {
  max-width: 374px !important;
}

.min-h-374 {
  min-height: 374px !important;
}

.h-374 {
  height: 374px !important;
}

.max-h-374 {
  max-height: 374px !important;
}

.lh-374 {
  line-height: 374px !important;
}

.min-w-375 {
  min-width: 375px !important;
}

.w-375 {
  width: 375px !important;
}

.max-w-375 {
  max-width: 375px !important;
}

.min-h-375 {
  min-height: 375px !important;
}

.h-375 {
  height: 375px !important;
}

.max-h-375 {
  max-height: 375px !important;
}

.lh-375 {
  line-height: 375px !important;
}

.min-w-376 {
  min-width: 376px !important;
}

.w-376 {
  width: 376px !important;
}

.max-w-376 {
  max-width: 376px !important;
}

.min-h-376 {
  min-height: 376px !important;
}

.h-376 {
  height: 376px !important;
}

.max-h-376 {
  max-height: 376px !important;
}

.lh-376 {
  line-height: 376px !important;
}

.min-w-377 {
  min-width: 377px !important;
}

.w-377 {
  width: 377px !important;
}

.max-w-377 {
  max-width: 377px !important;
}

.min-h-377 {
  min-height: 377px !important;
}

.h-377 {
  height: 377px !important;
}

.max-h-377 {
  max-height: 377px !important;
}

.lh-377 {
  line-height: 377px !important;
}

.min-w-378 {
  min-width: 378px !important;
}

.w-378 {
  width: 378px !important;
}

.max-w-378 {
  max-width: 378px !important;
}

.min-h-378 {
  min-height: 378px !important;
}

.h-378 {
  height: 378px !important;
}

.max-h-378 {
  max-height: 378px !important;
}

.lh-378 {
  line-height: 378px !important;
}

.min-w-379 {
  min-width: 379px !important;
}

.w-379 {
  width: 379px !important;
}

.max-w-379 {
  max-width: 379px !important;
}

.min-h-379 {
  min-height: 379px !important;
}

.h-379 {
  height: 379px !important;
}

.max-h-379 {
  max-height: 379px !important;
}

.lh-379 {
  line-height: 379px !important;
}

.min-w-380 {
  min-width: 380px !important;
}

.w-380 {
  width: 380px !important;
}

.max-w-380 {
  max-width: 380px !important;
}

.min-h-380 {
  min-height: 380px !important;
}

.h-380 {
  height: 380px !important;
}

.max-h-380 {
  max-height: 380px !important;
}

.lh-380 {
  line-height: 380px !important;
}

.min-w-381 {
  min-width: 381px !important;
}

.w-381 {
  width: 381px !important;
}

.max-w-381 {
  max-width: 381px !important;
}

.min-h-381 {
  min-height: 381px !important;
}

.h-381 {
  height: 381px !important;
}

.max-h-381 {
  max-height: 381px !important;
}

.lh-381 {
  line-height: 381px !important;
}

.min-w-382 {
  min-width: 382px !important;
}

.w-382 {
  width: 382px !important;
}

.max-w-382 {
  max-width: 382px !important;
}

.min-h-382 {
  min-height: 382px !important;
}

.h-382 {
  height: 382px !important;
}

.max-h-382 {
  max-height: 382px !important;
}

.lh-382 {
  line-height: 382px !important;
}

.min-w-383 {
  min-width: 383px !important;
}

.w-383 {
  width: 383px !important;
}

.max-w-383 {
  max-width: 383px !important;
}

.min-h-383 {
  min-height: 383px !important;
}

.h-383 {
  height: 383px !important;
}

.max-h-383 {
  max-height: 383px !important;
}

.lh-383 {
  line-height: 383px !important;
}

.min-w-384 {
  min-width: 384px !important;
}

.w-384 {
  width: 384px !important;
}

.max-w-384 {
  max-width: 384px !important;
}

.min-h-384 {
  min-height: 384px !important;
}

.h-384 {
  height: 384px !important;
}

.max-h-384 {
  max-height: 384px !important;
}

.lh-384 {
  line-height: 384px !important;
}

.min-w-385 {
  min-width: 385px !important;
}

.w-385 {
  width: 385px !important;
}

.max-w-385 {
  max-width: 385px !important;
}

.min-h-385 {
  min-height: 385px !important;
}

.h-385 {
  height: 385px !important;
}

.max-h-385 {
  max-height: 385px !important;
}

.lh-385 {
  line-height: 385px !important;
}

.min-w-386 {
  min-width: 386px !important;
}

.w-386 {
  width: 386px !important;
}

.max-w-386 {
  max-width: 386px !important;
}

.min-h-386 {
  min-height: 386px !important;
}

.h-386 {
  height: 386px !important;
}

.max-h-386 {
  max-height: 386px !important;
}

.lh-386 {
  line-height: 386px !important;
}

.min-w-387 {
  min-width: 387px !important;
}

.w-387 {
  width: 387px !important;
}

.max-w-387 {
  max-width: 387px !important;
}

.min-h-387 {
  min-height: 387px !important;
}

.h-387 {
  height: 387px !important;
}

.max-h-387 {
  max-height: 387px !important;
}

.lh-387 {
  line-height: 387px !important;
}

.min-w-388 {
  min-width: 388px !important;
}

.w-388 {
  width: 388px !important;
}

.max-w-388 {
  max-width: 388px !important;
}

.min-h-388 {
  min-height: 388px !important;
}

.h-388 {
  height: 388px !important;
}

.max-h-388 {
  max-height: 388px !important;
}

.lh-388 {
  line-height: 388px !important;
}

.min-w-389 {
  min-width: 389px !important;
}

.w-389 {
  width: 389px !important;
}

.max-w-389 {
  max-width: 389px !important;
}

.min-h-389 {
  min-height: 389px !important;
}

.h-389 {
  height: 389px !important;
}

.max-h-389 {
  max-height: 389px !important;
}

.lh-389 {
  line-height: 389px !important;
}

.min-w-390 {
  min-width: 390px !important;
}

.w-390 {
  width: 390px !important;
}

.max-w-390 {
  max-width: 390px !important;
}

.min-h-390 {
  min-height: 390px !important;
}

.h-390 {
  height: 390px !important;
}

.max-h-390 {
  max-height: 390px !important;
}

.lh-390 {
  line-height: 390px !important;
}

.min-w-391 {
  min-width: 391px !important;
}

.w-391 {
  width: 391px !important;
}

.max-w-391 {
  max-width: 391px !important;
}

.min-h-391 {
  min-height: 391px !important;
}

.h-391 {
  height: 391px !important;
}

.max-h-391 {
  max-height: 391px !important;
}

.lh-391 {
  line-height: 391px !important;
}

.min-w-392 {
  min-width: 392px !important;
}

.w-392 {
  width: 392px !important;
}

.max-w-392 {
  max-width: 392px !important;
}

.min-h-392 {
  min-height: 392px !important;
}

.h-392 {
  height: 392px !important;
}

.max-h-392 {
  max-height: 392px !important;
}

.lh-392 {
  line-height: 392px !important;
}

.min-w-393 {
  min-width: 393px !important;
}

.w-393 {
  width: 393px !important;
}

.max-w-393 {
  max-width: 393px !important;
}

.min-h-393 {
  min-height: 393px !important;
}

.h-393 {
  height: 393px !important;
}

.max-h-393 {
  max-height: 393px !important;
}

.lh-393 {
  line-height: 393px !important;
}

.min-w-394 {
  min-width: 394px !important;
}

.w-394 {
  width: 394px !important;
}

.max-w-394 {
  max-width: 394px !important;
}

.min-h-394 {
  min-height: 394px !important;
}

.h-394 {
  height: 394px !important;
}

.max-h-394 {
  max-height: 394px !important;
}

.lh-394 {
  line-height: 394px !important;
}

.min-w-395 {
  min-width: 395px !important;
}

.w-395 {
  width: 395px !important;
}

.max-w-395 {
  max-width: 395px !important;
}

.min-h-395 {
  min-height: 395px !important;
}

.h-395 {
  height: 395px !important;
}

.max-h-395 {
  max-height: 395px !important;
}

.lh-395 {
  line-height: 395px !important;
}

.min-w-396 {
  min-width: 396px !important;
}

.w-396 {
  width: 396px !important;
}

.max-w-396 {
  max-width: 396px !important;
}

.min-h-396 {
  min-height: 396px !important;
}

.h-396 {
  height: 396px !important;
}

.max-h-396 {
  max-height: 396px !important;
}

.lh-396 {
  line-height: 396px !important;
}

.min-w-397 {
  min-width: 397px !important;
}

.w-397 {
  width: 397px !important;
}

.max-w-397 {
  max-width: 397px !important;
}

.min-h-397 {
  min-height: 397px !important;
}

.h-397 {
  height: 397px !important;
}

.max-h-397 {
  max-height: 397px !important;
}

.lh-397 {
  line-height: 397px !important;
}

.min-w-398 {
  min-width: 398px !important;
}

.w-398 {
  width: 398px !important;
}

.max-w-398 {
  max-width: 398px !important;
}

.min-h-398 {
  min-height: 398px !important;
}

.h-398 {
  height: 398px !important;
}

.max-h-398 {
  max-height: 398px !important;
}

.lh-398 {
  line-height: 398px !important;
}

.min-w-399 {
  min-width: 399px !important;
}

.w-399 {
  width: 399px !important;
}

.max-w-399 {
  max-width: 399px !important;
}

.min-h-399 {
  min-height: 399px !important;
}

.h-399 {
  height: 399px !important;
}

.max-h-399 {
  max-height: 399px !important;
}

.lh-399 {
  line-height: 399px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.w-400 {
  width: 400px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.h-400 {
  height: 400px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.lh-400 {
  line-height: 400px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-x-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.m-y-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-x-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-y-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.b-r-0 {
  border-radius: 0px !important;
}

.b-t-0 {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.b-b-0 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.b-tl-0 {
  border-top-left-radius: 0px !important;
}

.b-tr-0 {
  border-top-right-radius: 0px !important;
}

.b-bl-0 {
  border-bottom-left-radius: 0px !important;
}

.b-br-0 {
  border-bottom-right-radius: 0px !important;
}

.m-1 {
  margin: 1px !important;
}

.m-x-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.m-y-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.m-t-1 {
  margin-top: 1px !important;
}

.m-r-1 {
  margin-right: 1px !important;
}

.m-b-1 {
  margin-bottom: 1px !important;
}

.m-l-1 {
  margin-left: 1px !important;
}

.p-1 {
  padding: 1px !important;
}

.p-x-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.p-y-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.p-t-1 {
  padding-top: 1px !important;
}

.p-r-1 {
  padding-right: 1px !important;
}

.p-b-1 {
  padding-bottom: 1px !important;
}

.p-l-1 {
  padding-left: 1px !important;
}

.b-r-1 {
  border-radius: 1px !important;
}

.b-t-1 {
  border-top-left-radius: 1px !important;
  border-top-right-radius: 1px !important;
}

.b-b-1 {
  border-bottom-left-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.b-tl-1 {
  border-top-left-radius: 1px !important;
}

.b-tr-1 {
  border-top-right-radius: 1px !important;
}

.b-bl-1 {
  border-bottom-left-radius: 1px !important;
}

.b-br-1 {
  border-bottom-right-radius: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-x-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.m-y-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-r-2 {
  margin-right: 2px !important;
}

.m-b-2 {
  margin-bottom: 2px !important;
}

.m-l-2 {
  margin-left: 2px !important;
}

.p-2 {
  padding: 2px !important;
}

.p-x-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.p-y-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-r-2 {
  padding-right: 2px !important;
}

.p-b-2 {
  padding-bottom: 2px !important;
}

.p-l-2 {
  padding-left: 2px !important;
}

.b-r-2 {
  border-radius: 2px !important;
}

.b-t-2 {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.b-b-2 {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.b-tl-2 {
  border-top-left-radius: 2px !important;
}

.b-tr-2 {
  border-top-right-radius: 2px !important;
}

.b-bl-2 {
  border-bottom-left-radius: 2px !important;
}

.b-br-2 {
  border-bottom-right-radius: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.m-x-3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.m-y-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.m-t-3 {
  margin-top: 3px !important;
}

.m-r-3 {
  margin-right: 3px !important;
}

.m-b-3 {
  margin-bottom: 3px !important;
}

.m-l-3 {
  margin-left: 3px !important;
}

.p-3 {
  padding: 3px !important;
}

.p-x-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.p-y-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.p-t-3 {
  padding-top: 3px !important;
}

.p-r-3 {
  padding-right: 3px !important;
}

.p-b-3 {
  padding-bottom: 3px !important;
}

.p-l-3 {
  padding-left: 3px !important;
}

.b-r-3 {
  border-radius: 3px !important;
}

.b-t-3 {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.b-b-3 {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.b-tl-3 {
  border-top-left-radius: 3px !important;
}

.b-tr-3 {
  border-top-right-radius: 3px !important;
}

.b-bl-3 {
  border-bottom-left-radius: 3px !important;
}

.b-br-3 {
  border-bottom-right-radius: 3px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-x-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.m-y-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.m-r-4 {
  margin-right: 4px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-x-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.p-y-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.p-r-4 {
  padding-right: 4px !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.p-l-4 {
  padding-left: 4px !important;
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-t-4 {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.b-b-4 {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.b-tl-4 {
  border-top-left-radius: 4px !important;
}

.b-tr-4 {
  border-top-right-radius: 4px !important;
}

.b-bl-4 {
  border-bottom-left-radius: 4px !important;
}

.b-br-4 {
  border-bottom-right-radius: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-x-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.m-y-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-x-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.p-y-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.b-t-5 {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.b-b-5 {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.b-tl-5 {
  border-top-left-radius: 5px !important;
}

.b-tr-5 {
  border-top-right-radius: 5px !important;
}

.b-bl-5 {
  border-bottom-left-radius: 5px !important;
}

.b-br-5 {
  border-bottom-right-radius: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-x-6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.m-y-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.m-t-6 {
  margin-top: 6px !important;
}

.m-r-6 {
  margin-right: 6px !important;
}

.m-b-6 {
  margin-bottom: 6px !important;
}

.m-l-6 {
  margin-left: 6px !important;
}

.p-6 {
  padding: 6px !important;
}

.p-x-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.p-y-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.p-t-6 {
  padding-top: 6px !important;
}

.p-r-6 {
  padding-right: 6px !important;
}

.p-b-6 {
  padding-bottom: 6px !important;
}

.p-l-6 {
  padding-left: 6px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-t-6 {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.b-b-6 {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.b-tl-6 {
  border-top-left-radius: 6px !important;
}

.b-tr-6 {
  border-top-right-radius: 6px !important;
}

.b-bl-6 {
  border-bottom-left-radius: 6px !important;
}

.b-br-6 {
  border-bottom-right-radius: 6px !important;
}

.m-7 {
  margin: 7px !important;
}

.m-x-7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.m-y-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.m-r-7 {
  margin-right: 7px !important;
}

.m-b-7 {
  margin-bottom: 7px !important;
}

.m-l-7 {
  margin-left: 7px !important;
}

.p-7 {
  padding: 7px !important;
}

.p-x-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.p-y-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.p-t-7 {
  padding-top: 7px !important;
}

.p-r-7 {
  padding-right: 7px !important;
}

.p-b-7 {
  padding-bottom: 7px !important;
}

.p-l-7 {
  padding-left: 7px !important;
}

.b-r-7 {
  border-radius: 7px !important;
}

.b-t-7 {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.b-b-7 {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.b-tl-7 {
  border-top-left-radius: 7px !important;
}

.b-tr-7 {
  border-top-right-radius: 7px !important;
}

.b-bl-7 {
  border-bottom-left-radius: 7px !important;
}

.b-br-7 {
  border-bottom-right-radius: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-x-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-y-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-x-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-y-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.b-r-8 {
  border-radius: 8px !important;
}

.b-t-8 {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.b-b-8 {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.b-tl-8 {
  border-top-left-radius: 8px !important;
}

.b-tr-8 {
  border-top-right-radius: 8px !important;
}

.b-bl-8 {
  border-bottom-left-radius: 8px !important;
}

.b-br-8 {
  border-bottom-right-radius: 8px !important;
}

.m-9 {
  margin: 9px !important;
}

.m-x-9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.m-y-9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.m-t-9 {
  margin-top: 9px !important;
}

.m-r-9 {
  margin-right: 9px !important;
}

.m-b-9 {
  margin-bottom: 9px !important;
}

.m-l-9 {
  margin-left: 9px !important;
}

.p-9 {
  padding: 9px !important;
}

.p-x-9 {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.p-y-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.p-t-9 {
  padding-top: 9px !important;
}

.p-r-9 {
  padding-right: 9px !important;
}

.p-b-9 {
  padding-bottom: 9px !important;
}

.p-l-9 {
  padding-left: 9px !important;
}

.b-r-9 {
  border-radius: 9px !important;
}

.b-t-9 {
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
}

.b-b-9 {
  border-bottom-left-radius: 9px !important;
  border-bottom-right-radius: 9px !important;
}

.b-tl-9 {
  border-top-left-radius: 9px !important;
}

.b-tr-9 {
  border-top-right-radius: 9px !important;
}

.b-bl-9 {
  border-bottom-left-radius: 9px !important;
}

.b-br-9 {
  border-bottom-right-radius: 9px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-x-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.m-y-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

.b-t-10 {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.b-b-10 {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.b-tl-10 {
  border-top-left-radius: 10px !important;
}

.b-tr-10 {
  border-top-right-radius: 10px !important;
}

.b-bl-10 {
  border-bottom-left-radius: 10px !important;
}

.b-br-10 {
  border-bottom-right-radius: 10px !important;
}

.m-11 {
  margin: 11px !important;
}

.m-x-11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}

.m-y-11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.m-t-11 {
  margin-top: 11px !important;
}

.m-r-11 {
  margin-right: 11px !important;
}

.m-b-11 {
  margin-bottom: 11px !important;
}

.m-l-11 {
  margin-left: 11px !important;
}

.p-11 {
  padding: 11px !important;
}

.p-x-11 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.p-y-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.p-t-11 {
  padding-top: 11px !important;
}

.p-r-11 {
  padding-right: 11px !important;
}

.p-b-11 {
  padding-bottom: 11px !important;
}

.p-l-11 {
  padding-left: 11px !important;
}

.b-r-11 {
  border-radius: 11px !important;
}

.b-t-11 {
  border-top-left-radius: 11px !important;
  border-top-right-radius: 11px !important;
}

.b-b-11 {
  border-bottom-left-radius: 11px !important;
  border-bottom-right-radius: 11px !important;
}

.b-tl-11 {
  border-top-left-radius: 11px !important;
}

.b-tr-11 {
  border-top-right-radius: 11px !important;
}

.b-bl-11 {
  border-bottom-left-radius: 11px !important;
}

.b-br-11 {
  border-bottom-right-radius: 11px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-x-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.m-y-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-r-12 {
  margin-right: 12px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-l-12 {
  margin-left: 12px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-x-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.p-y-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.p-r-12 {
  padding-right: 12px !important;
}

.p-b-12 {
  padding-bottom: 12px !important;
}

.p-l-12 {
  padding-left: 12px !important;
}

.b-r-12 {
  border-radius: 12px !important;
}

.b-t-12 {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.b-b-12 {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.b-tl-12 {
  border-top-left-radius: 12px !important;
}

.b-tr-12 {
  border-top-right-radius: 12px !important;
}

.b-bl-12 {
  border-bottom-left-radius: 12px !important;
}

.b-br-12 {
  border-bottom-right-radius: 12px !important;
}

.m-13 {
  margin: 13px !important;
}

.m-x-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.m-y-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.m-t-13 {
  margin-top: 13px !important;
}

.m-r-13 {
  margin-right: 13px !important;
}

.m-b-13 {
  margin-bottom: 13px !important;
}

.m-l-13 {
  margin-left: 13px !important;
}

.p-13 {
  padding: 13px !important;
}

.p-x-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.p-y-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.p-t-13 {
  padding-top: 13px !important;
}

.p-r-13 {
  padding-right: 13px !important;
}

.p-b-13 {
  padding-bottom: 13px !important;
}

.p-l-13 {
  padding-left: 13px !important;
}

.b-r-13 {
  border-radius: 13px !important;
}

.b-t-13 {
  border-top-left-radius: 13px !important;
  border-top-right-radius: 13px !important;
}

.b-b-13 {
  border-bottom-left-radius: 13px !important;
  border-bottom-right-radius: 13px !important;
}

.b-tl-13 {
  border-top-left-radius: 13px !important;
}

.b-tr-13 {
  border-top-right-radius: 13px !important;
}

.b-bl-13 {
  border-bottom-left-radius: 13px !important;
}

.b-br-13 {
  border-bottom-right-radius: 13px !important;
}

.m-14 {
  margin: 14px !important;
}

.m-x-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.m-y-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.m-t-14 {
  margin-top: 14px !important;
}

.m-r-14 {
  margin-right: 14px !important;
}

.m-b-14 {
  margin-bottom: 14px !important;
}

.m-l-14 {
  margin-left: 14px !important;
}

.p-14 {
  padding: 14px !important;
}

.p-x-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.p-y-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.p-t-14 {
  padding-top: 14px !important;
}

.p-r-14 {
  padding-right: 14px !important;
}

.p-b-14 {
  padding-bottom: 14px !important;
}

.p-l-14 {
  padding-left: 14px !important;
}

.b-r-14 {
  border-radius: 14px !important;
}

.b-t-14 {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.b-b-14 {
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.b-tl-14 {
  border-top-left-radius: 14px !important;
}

.b-tr-14 {
  border-top-right-radius: 14px !important;
}

.b-bl-14 {
  border-bottom-left-radius: 14px !important;
}

.b-br-14 {
  border-bottom-right-radius: 14px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-x-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.m-y-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-x-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-y-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.b-r-15 {
  border-radius: 15px !important;
}

.b-t-15 {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.b-b-15 {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.b-tl-15 {
  border-top-left-radius: 15px !important;
}

.b-tr-15 {
  border-top-right-radius: 15px !important;
}

.b-bl-15 {
  border-bottom-left-radius: 15px !important;
}

.b-br-15 {
  border-bottom-right-radius: 15px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-x-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.m-y-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-x-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.p-y-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.p-r-16 {
  padding-right: 16px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.p-l-16 {
  padding-left: 16px !important;
}

.b-r-16 {
  border-radius: 16px !important;
}

.b-t-16 {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.b-b-16 {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.b-tl-16 {
  border-top-left-radius: 16px !important;
}

.b-tr-16 {
  border-top-right-radius: 16px !important;
}

.b-bl-16 {
  border-bottom-left-radius: 16px !important;
}

.b-br-16 {
  border-bottom-right-radius: 16px !important;
}

.m-17 {
  margin: 17px !important;
}

.m-x-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.m-y-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.m-t-17 {
  margin-top: 17px !important;
}

.m-r-17 {
  margin-right: 17px !important;
}

.m-b-17 {
  margin-bottom: 17px !important;
}

.m-l-17 {
  margin-left: 17px !important;
}

.p-17 {
  padding: 17px !important;
}

.p-x-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.p-y-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.p-t-17 {
  padding-top: 17px !important;
}

.p-r-17 {
  padding-right: 17px !important;
}

.p-b-17 {
  padding-bottom: 17px !important;
}

.p-l-17 {
  padding-left: 17px !important;
}

.b-r-17 {
  border-radius: 17px !important;
}

.b-t-17 {
  border-top-left-radius: 17px !important;
  border-top-right-radius: 17px !important;
}

.b-b-17 {
  border-bottom-left-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
}

.b-tl-17 {
  border-top-left-radius: 17px !important;
}

.b-tr-17 {
  border-top-right-radius: 17px !important;
}

.b-bl-17 {
  border-bottom-left-radius: 17px !important;
}

.b-br-17 {
  border-bottom-right-radius: 17px !important;
}

.m-18 {
  margin: 18px !important;
}

.m-x-18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.m-y-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.m-t-18 {
  margin-top: 18px !important;
}

.m-r-18 {
  margin-right: 18px !important;
}

.m-b-18 {
  margin-bottom: 18px !important;
}

.m-l-18 {
  margin-left: 18px !important;
}

.p-18 {
  padding: 18px !important;
}

.p-x-18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.p-y-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.p-t-18 {
  padding-top: 18px !important;
}

.p-r-18 {
  padding-right: 18px !important;
}

.p-b-18 {
  padding-bottom: 18px !important;
}

.p-l-18 {
  padding-left: 18px !important;
}

.b-r-18 {
  border-radius: 18px !important;
}

.b-t-18 {
  border-top-left-radius: 18px !important;
  border-top-right-radius: 18px !important;
}

.b-b-18 {
  border-bottom-left-radius: 18px !important;
  border-bottom-right-radius: 18px !important;
}

.b-tl-18 {
  border-top-left-radius: 18px !important;
}

.b-tr-18 {
  border-top-right-radius: 18px !important;
}

.b-bl-18 {
  border-bottom-left-radius: 18px !important;
}

.b-br-18 {
  border-bottom-right-radius: 18px !important;
}

.m-19 {
  margin: 19px !important;
}

.m-x-19 {
  margin-left: 19px !important;
  margin-right: 19px !important;
}

.m-y-19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.m-t-19 {
  margin-top: 19px !important;
}

.m-r-19 {
  margin-right: 19px !important;
}

.m-b-19 {
  margin-bottom: 19px !important;
}

.m-l-19 {
  margin-left: 19px !important;
}

.p-19 {
  padding: 19px !important;
}

.p-x-19 {
  padding-left: 19px !important;
  padding-right: 19px !important;
}

.p-y-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.p-t-19 {
  padding-top: 19px !important;
}

.p-r-19 {
  padding-right: 19px !important;
}

.p-b-19 {
  padding-bottom: 19px !important;
}

.p-l-19 {
  padding-left: 19px !important;
}

.b-r-19 {
  border-radius: 19px !important;
}

.b-t-19 {
  border-top-left-radius: 19px !important;
  border-top-right-radius: 19px !important;
}

.b-b-19 {
  border-bottom-left-radius: 19px !important;
  border-bottom-right-radius: 19px !important;
}

.b-tl-19 {
  border-top-left-radius: 19px !important;
}

.b-tr-19 {
  border-top-right-radius: 19px !important;
}

.b-bl-19 {
  border-bottom-left-radius: 19px !important;
}

.b-br-19 {
  border-bottom-right-radius: 19px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-x-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-y-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-x-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-y-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.b-r-20 {
  border-radius: 20px !important;
}

.b-t-20 {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.b-b-20 {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.b-tl-20 {
  border-top-left-radius: 20px !important;
}

.b-tr-20 {
  border-top-right-radius: 20px !important;
}

.b-bl-20 {
  border-bottom-left-radius: 20px !important;
}

.b-br-20 {
  border-bottom-right-radius: 20px !important;
}

.m-21 {
  margin: 21px !important;
}

.m-x-21 {
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.m-y-21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}

.m-t-21 {
  margin-top: 21px !important;
}

.m-r-21 {
  margin-right: 21px !important;
}

.m-b-21 {
  margin-bottom: 21px !important;
}

.m-l-21 {
  margin-left: 21px !important;
}

.p-21 {
  padding: 21px !important;
}

.p-x-21 {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.p-y-21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}

.p-t-21 {
  padding-top: 21px !important;
}

.p-r-21 {
  padding-right: 21px !important;
}

.p-b-21 {
  padding-bottom: 21px !important;
}

.p-l-21 {
  padding-left: 21px !important;
}

.b-r-21 {
  border-radius: 21px !important;
}

.b-t-21 {
  border-top-left-radius: 21px !important;
  border-top-right-radius: 21px !important;
}

.b-b-21 {
  border-bottom-left-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
}

.b-tl-21 {
  border-top-left-radius: 21px !important;
}

.b-tr-21 {
  border-top-right-radius: 21px !important;
}

.b-bl-21 {
  border-bottom-left-radius: 21px !important;
}

.b-br-21 {
  border-bottom-right-radius: 21px !important;
}

.m-22 {
  margin: 22px !important;
}

.m-x-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.m-y-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.m-t-22 {
  margin-top: 22px !important;
}

.m-r-22 {
  margin-right: 22px !important;
}

.m-b-22 {
  margin-bottom: 22px !important;
}

.m-l-22 {
  margin-left: 22px !important;
}

.p-22 {
  padding: 22px !important;
}

.p-x-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.p-y-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.p-t-22 {
  padding-top: 22px !important;
}

.p-r-22 {
  padding-right: 22px !important;
}

.p-b-22 {
  padding-bottom: 22px !important;
}

.p-l-22 {
  padding-left: 22px !important;
}

.b-r-22 {
  border-radius: 22px !important;
}

.b-t-22 {
  border-top-left-radius: 22px !important;
  border-top-right-radius: 22px !important;
}

.b-b-22 {
  border-bottom-left-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
}

.b-tl-22 {
  border-top-left-radius: 22px !important;
}

.b-tr-22 {
  border-top-right-radius: 22px !important;
}

.b-bl-22 {
  border-bottom-left-radius: 22px !important;
}

.b-br-22 {
  border-bottom-right-radius: 22px !important;
}

.m-23 {
  margin: 23px !important;
}

.m-x-23 {
  margin-left: 23px !important;
  margin-right: 23px !important;
}

.m-y-23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}

.m-t-23 {
  margin-top: 23px !important;
}

.m-r-23 {
  margin-right: 23px !important;
}

.m-b-23 {
  margin-bottom: 23px !important;
}

.m-l-23 {
  margin-left: 23px !important;
}

.p-23 {
  padding: 23px !important;
}

.p-x-23 {
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.p-y-23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.p-t-23 {
  padding-top: 23px !important;
}

.p-r-23 {
  padding-right: 23px !important;
}

.p-b-23 {
  padding-bottom: 23px !important;
}

.p-l-23 {
  padding-left: 23px !important;
}

.b-r-23 {
  border-radius: 23px !important;
}

.b-t-23 {
  border-top-left-radius: 23px !important;
  border-top-right-radius: 23px !important;
}

.b-b-23 {
  border-bottom-left-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
}

.b-tl-23 {
  border-top-left-radius: 23px !important;
}

.b-tr-23 {
  border-top-right-radius: 23px !important;
}

.b-bl-23 {
  border-bottom-left-radius: 23px !important;
}

.b-br-23 {
  border-bottom-right-radius: 23px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-x-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-y-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.m-r-24 {
  margin-right: 24px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.m-l-24 {
  margin-left: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-x-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.p-y-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-r-24 {
  padding-right: 24px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.b-r-24 {
  border-radius: 24px !important;
}

.b-t-24 {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}

.b-b-24 {
  border-bottom-left-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.b-tl-24 {
  border-top-left-radius: 24px !important;
}

.b-tr-24 {
  border-top-right-radius: 24px !important;
}

.b-bl-24 {
  border-bottom-left-radius: 24px !important;
}

.b-br-24 {
  border-bottom-right-radius: 24px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-x-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.m-y-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-x-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.p-y-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.b-r-25 {
  border-radius: 25px !important;
}

.b-t-25 {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.b-b-25 {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.b-tl-25 {
  border-top-left-radius: 25px !important;
}

.b-tr-25 {
  border-top-right-radius: 25px !important;
}

.b-bl-25 {
  border-bottom-left-radius: 25px !important;
}

.b-br-25 {
  border-bottom-right-radius: 25px !important;
}

.m-26 {
  margin: 26px !important;
}

.m-x-26 {
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.m-y-26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

.m-t-26 {
  margin-top: 26px !important;
}

.m-r-26 {
  margin-right: 26px !important;
}

.m-b-26 {
  margin-bottom: 26px !important;
}

.m-l-26 {
  margin-left: 26px !important;
}

.p-26 {
  padding: 26px !important;
}

.p-x-26 {
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.p-y-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.p-t-26 {
  padding-top: 26px !important;
}

.p-r-26 {
  padding-right: 26px !important;
}

.p-b-26 {
  padding-bottom: 26px !important;
}

.p-l-26 {
  padding-left: 26px !important;
}

.b-r-26 {
  border-radius: 26px !important;
}

.b-t-26 {
  border-top-left-radius: 26px !important;
  border-top-right-radius: 26px !important;
}

.b-b-26 {
  border-bottom-left-radius: 26px !important;
  border-bottom-right-radius: 26px !important;
}

.b-tl-26 {
  border-top-left-radius: 26px !important;
}

.b-tr-26 {
  border-top-right-radius: 26px !important;
}

.b-bl-26 {
  border-bottom-left-radius: 26px !important;
}

.b-br-26 {
  border-bottom-right-radius: 26px !important;
}

.m-27 {
  margin: 27px !important;
}

.m-x-27 {
  margin-left: 27px !important;
  margin-right: 27px !important;
}

.m-y-27 {
  margin-top: 27px !important;
  margin-bottom: 27px !important;
}

.m-t-27 {
  margin-top: 27px !important;
}

.m-r-27 {
  margin-right: 27px !important;
}

.m-b-27 {
  margin-bottom: 27px !important;
}

.m-l-27 {
  margin-left: 27px !important;
}

.p-27 {
  padding: 27px !important;
}

.p-x-27 {
  padding-left: 27px !important;
  padding-right: 27px !important;
}

.p-y-27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
}

.p-t-27 {
  padding-top: 27px !important;
}

.p-r-27 {
  padding-right: 27px !important;
}

.p-b-27 {
  padding-bottom: 27px !important;
}

.p-l-27 {
  padding-left: 27px !important;
}

.b-r-27 {
  border-radius: 27px !important;
}

.b-t-27 {
  border-top-left-radius: 27px !important;
  border-top-right-radius: 27px !important;
}

.b-b-27 {
  border-bottom-left-radius: 27px !important;
  border-bottom-right-radius: 27px !important;
}

.b-tl-27 {
  border-top-left-radius: 27px !important;
}

.b-tr-27 {
  border-top-right-radius: 27px !important;
}

.b-bl-27 {
  border-bottom-left-radius: 27px !important;
}

.b-br-27 {
  border-bottom-right-radius: 27px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-x-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.m-y-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.m-t-28 {
  margin-top: 28px !important;
}

.m-r-28 {
  margin-right: 28px !important;
}

.m-b-28 {
  margin-bottom: 28px !important;
}

.m-l-28 {
  margin-left: 28px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-x-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.p-y-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.p-t-28 {
  padding-top: 28px !important;
}

.p-r-28 {
  padding-right: 28px !important;
}

.p-b-28 {
  padding-bottom: 28px !important;
}

.p-l-28 {
  padding-left: 28px !important;
}

.b-r-28 {
  border-radius: 28px !important;
}

.b-t-28 {
  border-top-left-radius: 28px !important;
  border-top-right-radius: 28px !important;
}

.b-b-28 {
  border-bottom-left-radius: 28px !important;
  border-bottom-right-radius: 28px !important;
}

.b-tl-28 {
  border-top-left-radius: 28px !important;
}

.b-tr-28 {
  border-top-right-radius: 28px !important;
}

.b-bl-28 {
  border-bottom-left-radius: 28px !important;
}

.b-br-28 {
  border-bottom-right-radius: 28px !important;
}

.m-29 {
  margin: 29px !important;
}

.m-x-29 {
  margin-left: 29px !important;
  margin-right: 29px !important;
}

.m-y-29 {
  margin-top: 29px !important;
  margin-bottom: 29px !important;
}

.m-t-29 {
  margin-top: 29px !important;
}

.m-r-29 {
  margin-right: 29px !important;
}

.m-b-29 {
  margin-bottom: 29px !important;
}

.m-l-29 {
  margin-left: 29px !important;
}

.p-29 {
  padding: 29px !important;
}

.p-x-29 {
  padding-left: 29px !important;
  padding-right: 29px !important;
}

.p-y-29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.p-t-29 {
  padding-top: 29px !important;
}

.p-r-29 {
  padding-right: 29px !important;
}

.p-b-29 {
  padding-bottom: 29px !important;
}

.p-l-29 {
  padding-left: 29px !important;
}

.b-r-29 {
  border-radius: 29px !important;
}

.b-t-29 {
  border-top-left-radius: 29px !important;
  border-top-right-radius: 29px !important;
}

.b-b-29 {
  border-bottom-left-radius: 29px !important;
  border-bottom-right-radius: 29px !important;
}

.b-tl-29 {
  border-top-left-radius: 29px !important;
}

.b-tr-29 {
  border-top-right-radius: 29px !important;
}

.b-bl-29 {
  border-bottom-left-radius: 29px !important;
}

.b-br-29 {
  border-bottom-right-radius: 29px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-x-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.m-y-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-x-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-y-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.b-r-30 {
  border-radius: 30px !important;
}

.b-t-30 {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.b-b-30 {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.b-tl-30 {
  border-top-left-radius: 30px !important;
}

.b-tr-30 {
  border-top-right-radius: 30px !important;
}

.b-bl-30 {
  border-bottom-left-radius: 30px !important;
}

.b-br-30 {
  border-bottom-right-radius: 30px !important;
}

.m-31 {
  margin: 31px !important;
}

.m-x-31 {
  margin-left: 31px !important;
  margin-right: 31px !important;
}

.m-y-31 {
  margin-top: 31px !important;
  margin-bottom: 31px !important;
}

.m-t-31 {
  margin-top: 31px !important;
}

.m-r-31 {
  margin-right: 31px !important;
}

.m-b-31 {
  margin-bottom: 31px !important;
}

.m-l-31 {
  margin-left: 31px !important;
}

.p-31 {
  padding: 31px !important;
}

.p-x-31 {
  padding-left: 31px !important;
  padding-right: 31px !important;
}

.p-y-31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important;
}

.p-t-31 {
  padding-top: 31px !important;
}

.p-r-31 {
  padding-right: 31px !important;
}

.p-b-31 {
  padding-bottom: 31px !important;
}

.p-l-31 {
  padding-left: 31px !important;
}

.b-r-31 {
  border-radius: 31px !important;
}

.b-t-31 {
  border-top-left-radius: 31px !important;
  border-top-right-radius: 31px !important;
}

.b-b-31 {
  border-bottom-left-radius: 31px !important;
  border-bottom-right-radius: 31px !important;
}

.b-tl-31 {
  border-top-left-radius: 31px !important;
}

.b-tr-31 {
  border-top-right-radius: 31px !important;
}

.b-bl-31 {
  border-bottom-left-radius: 31px !important;
}

.b-br-31 {
  border-bottom-right-radius: 31px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-x-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.m-y-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.m-r-32 {
  margin-right: 32px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.m-l-32 {
  margin-left: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-x-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.p-y-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.p-r-32 {
  padding-right: 32px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.p-l-32 {
  padding-left: 32px !important;
}

.b-r-32 {
  border-radius: 32px !important;
}

.b-t-32 {
  border-top-left-radius: 32px !important;
  border-top-right-radius: 32px !important;
}

.b-b-32 {
  border-bottom-left-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}

.b-tl-32 {
  border-top-left-radius: 32px !important;
}

.b-tr-32 {
  border-top-right-radius: 32px !important;
}

.b-bl-32 {
  border-bottom-left-radius: 32px !important;
}

.b-br-32 {
  border-bottom-right-radius: 32px !important;
}

.m-33 {
  margin: 33px !important;
}

.m-x-33 {
  margin-left: 33px !important;
  margin-right: 33px !important;
}

.m-y-33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important;
}

.m-t-33 {
  margin-top: 33px !important;
}

.m-r-33 {
  margin-right: 33px !important;
}

.m-b-33 {
  margin-bottom: 33px !important;
}

.m-l-33 {
  margin-left: 33px !important;
}

.p-33 {
  padding: 33px !important;
}

.p-x-33 {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.p-y-33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important;
}

.p-t-33 {
  padding-top: 33px !important;
}

.p-r-33 {
  padding-right: 33px !important;
}

.p-b-33 {
  padding-bottom: 33px !important;
}

.p-l-33 {
  padding-left: 33px !important;
}

.b-r-33 {
  border-radius: 33px !important;
}

.b-t-33 {
  border-top-left-radius: 33px !important;
  border-top-right-radius: 33px !important;
}

.b-b-33 {
  border-bottom-left-radius: 33px !important;
  border-bottom-right-radius: 33px !important;
}

.b-tl-33 {
  border-top-left-radius: 33px !important;
}

.b-tr-33 {
  border-top-right-radius: 33px !important;
}

.b-bl-33 {
  border-bottom-left-radius: 33px !important;
}

.b-br-33 {
  border-bottom-right-radius: 33px !important;
}

.m-34 {
  margin: 34px !important;
}

.m-x-34 {
  margin-left: 34px !important;
  margin-right: 34px !important;
}

.m-y-34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}

.m-t-34 {
  margin-top: 34px !important;
}

.m-r-34 {
  margin-right: 34px !important;
}

.m-b-34 {
  margin-bottom: 34px !important;
}

.m-l-34 {
  margin-left: 34px !important;
}

.p-34 {
  padding: 34px !important;
}

.p-x-34 {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.p-y-34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}

.p-t-34 {
  padding-top: 34px !important;
}

.p-r-34 {
  padding-right: 34px !important;
}

.p-b-34 {
  padding-bottom: 34px !important;
}

.p-l-34 {
  padding-left: 34px !important;
}

.b-r-34 {
  border-radius: 34px !important;
}

.b-t-34 {
  border-top-left-radius: 34px !important;
  border-top-right-radius: 34px !important;
}

.b-b-34 {
  border-bottom-left-radius: 34px !important;
  border-bottom-right-radius: 34px !important;
}

.b-tl-34 {
  border-top-left-radius: 34px !important;
}

.b-tr-34 {
  border-top-right-radius: 34px !important;
}

.b-bl-34 {
  border-bottom-left-radius: 34px !important;
}

.b-br-34 {
  border-bottom-right-radius: 34px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-x-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.m-y-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-x-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.p-y-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.b-r-35 {
  border-radius: 35px !important;
}

.b-t-35 {
  border-top-left-radius: 35px !important;
  border-top-right-radius: 35px !important;
}

.b-b-35 {
  border-bottom-left-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
}

.b-tl-35 {
  border-top-left-radius: 35px !important;
}

.b-tr-35 {
  border-top-right-radius: 35px !important;
}

.b-bl-35 {
  border-bottom-left-radius: 35px !important;
}

.b-br-35 {
  border-bottom-right-radius: 35px !important;
}

.m-36 {
  margin: 36px !important;
}

.m-x-36 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.m-y-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.m-t-36 {
  margin-top: 36px !important;
}

.m-r-36 {
  margin-right: 36px !important;
}

.m-b-36 {
  margin-bottom: 36px !important;
}

.m-l-36 {
  margin-left: 36px !important;
}

.p-36 {
  padding: 36px !important;
}

.p-x-36 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.p-y-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.p-t-36 {
  padding-top: 36px !important;
}

.p-r-36 {
  padding-right: 36px !important;
}

.p-b-36 {
  padding-bottom: 36px !important;
}

.p-l-36 {
  padding-left: 36px !important;
}

.b-r-36 {
  border-radius: 36px !important;
}

.b-t-36 {
  border-top-left-radius: 36px !important;
  border-top-right-radius: 36px !important;
}

.b-b-36 {
  border-bottom-left-radius: 36px !important;
  border-bottom-right-radius: 36px !important;
}

.b-tl-36 {
  border-top-left-radius: 36px !important;
}

.b-tr-36 {
  border-top-right-radius: 36px !important;
}

.b-bl-36 {
  border-bottom-left-radius: 36px !important;
}

.b-br-36 {
  border-bottom-right-radius: 36px !important;
}

.m-37 {
  margin: 37px !important;
}

.m-x-37 {
  margin-left: 37px !important;
  margin-right: 37px !important;
}

.m-y-37 {
  margin-top: 37px !important;
  margin-bottom: 37px !important;
}

.m-t-37 {
  margin-top: 37px !important;
}

.m-r-37 {
  margin-right: 37px !important;
}

.m-b-37 {
  margin-bottom: 37px !important;
}

.m-l-37 {
  margin-left: 37px !important;
}

.p-37 {
  padding: 37px !important;
}

.p-x-37 {
  padding-left: 37px !important;
  padding-right: 37px !important;
}

.p-y-37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important;
}

.p-t-37 {
  padding-top: 37px !important;
}

.p-r-37 {
  padding-right: 37px !important;
}

.p-b-37 {
  padding-bottom: 37px !important;
}

.p-l-37 {
  padding-left: 37px !important;
}

.b-r-37 {
  border-radius: 37px !important;
}

.b-t-37 {
  border-top-left-radius: 37px !important;
  border-top-right-radius: 37px !important;
}

.b-b-37 {
  border-bottom-left-radius: 37px !important;
  border-bottom-right-radius: 37px !important;
}

.b-tl-37 {
  border-top-left-radius: 37px !important;
}

.b-tr-37 {
  border-top-right-radius: 37px !important;
}

.b-bl-37 {
  border-bottom-left-radius: 37px !important;
}

.b-br-37 {
  border-bottom-right-radius: 37px !important;
}

.m-38 {
  margin: 38px !important;
}

.m-x-38 {
  margin-left: 38px !important;
  margin-right: 38px !important;
}

.m-y-38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important;
}

.m-t-38 {
  margin-top: 38px !important;
}

.m-r-38 {
  margin-right: 38px !important;
}

.m-b-38 {
  margin-bottom: 38px !important;
}

.m-l-38 {
  margin-left: 38px !important;
}

.p-38 {
  padding: 38px !important;
}

.p-x-38 {
  padding-left: 38px !important;
  padding-right: 38px !important;
}

.p-y-38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}

.p-t-38 {
  padding-top: 38px !important;
}

.p-r-38 {
  padding-right: 38px !important;
}

.p-b-38 {
  padding-bottom: 38px !important;
}

.p-l-38 {
  padding-left: 38px !important;
}

.b-r-38 {
  border-radius: 38px !important;
}

.b-t-38 {
  border-top-left-radius: 38px !important;
  border-top-right-radius: 38px !important;
}

.b-b-38 {
  border-bottom-left-radius: 38px !important;
  border-bottom-right-radius: 38px !important;
}

.b-tl-38 {
  border-top-left-radius: 38px !important;
}

.b-tr-38 {
  border-top-right-radius: 38px !important;
}

.b-bl-38 {
  border-bottom-left-radius: 38px !important;
}

.b-br-38 {
  border-bottom-right-radius: 38px !important;
}

.m-39 {
  margin: 39px !important;
}

.m-x-39 {
  margin-left: 39px !important;
  margin-right: 39px !important;
}

.m-y-39 {
  margin-top: 39px !important;
  margin-bottom: 39px !important;
}

.m-t-39 {
  margin-top: 39px !important;
}

.m-r-39 {
  margin-right: 39px !important;
}

.m-b-39 {
  margin-bottom: 39px !important;
}

.m-l-39 {
  margin-left: 39px !important;
}

.p-39 {
  padding: 39px !important;
}

.p-x-39 {
  padding-left: 39px !important;
  padding-right: 39px !important;
}

.p-y-39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important;
}

.p-t-39 {
  padding-top: 39px !important;
}

.p-r-39 {
  padding-right: 39px !important;
}

.p-b-39 {
  padding-bottom: 39px !important;
}

.p-l-39 {
  padding-left: 39px !important;
}

.b-r-39 {
  border-radius: 39px !important;
}

.b-t-39 {
  border-top-left-radius: 39px !important;
  border-top-right-radius: 39px !important;
}

.b-b-39 {
  border-bottom-left-radius: 39px !important;
  border-bottom-right-radius: 39px !important;
}

.b-tl-39 {
  border-top-left-radius: 39px !important;
}

.b-tr-39 {
  border-top-right-radius: 39px !important;
}

.b-bl-39 {
  border-bottom-left-radius: 39px !important;
}

.b-br-39 {
  border-bottom-right-radius: 39px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-x-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.m-y-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-x-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.p-y-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.b-r-40 {
  border-radius: 40px !important;
}

.b-t-40 {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
}

.b-b-40 {
  border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.b-tl-40 {
  border-top-left-radius: 40px !important;
}

.b-tr-40 {
  border-top-right-radius: 40px !important;
}

.b-bl-40 {
  border-bottom-left-radius: 40px !important;
}

.b-br-40 {
  border-bottom-right-radius: 40px !important;
}

.m-41 {
  margin: 41px !important;
}

.m-x-41 {
  margin-left: 41px !important;
  margin-right: 41px !important;
}

.m-y-41 {
  margin-top: 41px !important;
  margin-bottom: 41px !important;
}

.m-t-41 {
  margin-top: 41px !important;
}

.m-r-41 {
  margin-right: 41px !important;
}

.m-b-41 {
  margin-bottom: 41px !important;
}

.m-l-41 {
  margin-left: 41px !important;
}

.p-41 {
  padding: 41px !important;
}

.p-x-41 {
  padding-left: 41px !important;
  padding-right: 41px !important;
}

.p-y-41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important;
}

.p-t-41 {
  padding-top: 41px !important;
}

.p-r-41 {
  padding-right: 41px !important;
}

.p-b-41 {
  padding-bottom: 41px !important;
}

.p-l-41 {
  padding-left: 41px !important;
}

.b-r-41 {
  border-radius: 41px !important;
}

.b-t-41 {
  border-top-left-radius: 41px !important;
  border-top-right-radius: 41px !important;
}

.b-b-41 {
  border-bottom-left-radius: 41px !important;
  border-bottom-right-radius: 41px !important;
}

.b-tl-41 {
  border-top-left-radius: 41px !important;
}

.b-tr-41 {
  border-top-right-radius: 41px !important;
}

.b-bl-41 {
  border-bottom-left-radius: 41px !important;
}

.b-br-41 {
  border-bottom-right-radius: 41px !important;
}

.m-42 {
  margin: 42px !important;
}

.m-x-42 {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

.m-y-42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important;
}

.m-t-42 {
  margin-top: 42px !important;
}

.m-r-42 {
  margin-right: 42px !important;
}

.m-b-42 {
  margin-bottom: 42px !important;
}

.m-l-42 {
  margin-left: 42px !important;
}

.p-42 {
  padding: 42px !important;
}

.p-x-42 {
  padding-left: 42px !important;
  padding-right: 42px !important;
}

.p-y-42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important;
}

.p-t-42 {
  padding-top: 42px !important;
}

.p-r-42 {
  padding-right: 42px !important;
}

.p-b-42 {
  padding-bottom: 42px !important;
}

.p-l-42 {
  padding-left: 42px !important;
}

.b-r-42 {
  border-radius: 42px !important;
}

.b-t-42 {
  border-top-left-radius: 42px !important;
  border-top-right-radius: 42px !important;
}

.b-b-42 {
  border-bottom-left-radius: 42px !important;
  border-bottom-right-radius: 42px !important;
}

.b-tl-42 {
  border-top-left-radius: 42px !important;
}

.b-tr-42 {
  border-top-right-radius: 42px !important;
}

.b-bl-42 {
  border-bottom-left-radius: 42px !important;
}

.b-br-42 {
  border-bottom-right-radius: 42px !important;
}

.m-43 {
  margin: 43px !important;
}

.m-x-43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

.m-y-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

.m-t-43 {
  margin-top: 43px !important;
}

.m-r-43 {
  margin-right: 43px !important;
}

.m-b-43 {
  margin-bottom: 43px !important;
}

.m-l-43 {
  margin-left: 43px !important;
}

.p-43 {
  padding: 43px !important;
}

.p-x-43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

.p-y-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

.p-t-43 {
  padding-top: 43px !important;
}

.p-r-43 {
  padding-right: 43px !important;
}

.p-b-43 {
  padding-bottom: 43px !important;
}

.p-l-43 {
  padding-left: 43px !important;
}

.b-r-43 {
  border-radius: 43px !important;
}

.b-t-43 {
  border-top-left-radius: 43px !important;
  border-top-right-radius: 43px !important;
}

.b-b-43 {
  border-bottom-left-radius: 43px !important;
  border-bottom-right-radius: 43px !important;
}

.b-tl-43 {
  border-top-left-radius: 43px !important;
}

.b-tr-43 {
  border-top-right-radius: 43px !important;
}

.b-bl-43 {
  border-bottom-left-radius: 43px !important;
}

.b-br-43 {
  border-bottom-right-radius: 43px !important;
}

.m-44 {
  margin: 44px !important;
}

.m-x-44 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.m-y-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.m-t-44 {
  margin-top: 44px !important;
}

.m-r-44 {
  margin-right: 44px !important;
}

.m-b-44 {
  margin-bottom: 44px !important;
}

.m-l-44 {
  margin-left: 44px !important;
}

.p-44 {
  padding: 44px !important;
}

.p-x-44 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.p-y-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.p-t-44 {
  padding-top: 44px !important;
}

.p-r-44 {
  padding-right: 44px !important;
}

.p-b-44 {
  padding-bottom: 44px !important;
}

.p-l-44 {
  padding-left: 44px !important;
}

.b-r-44 {
  border-radius: 44px !important;
}

.b-t-44 {
  border-top-left-radius: 44px !important;
  border-top-right-radius: 44px !important;
}

.b-b-44 {
  border-bottom-left-radius: 44px !important;
  border-bottom-right-radius: 44px !important;
}

.b-tl-44 {
  border-top-left-radius: 44px !important;
}

.b-tr-44 {
  border-top-right-radius: 44px !important;
}

.b-bl-44 {
  border-bottom-left-radius: 44px !important;
}

.b-br-44 {
  border-bottom-right-radius: 44px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-x-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.m-y-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-x-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.p-y-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.b-r-45 {
  border-radius: 45px !important;
}

.b-t-45 {
  border-top-left-radius: 45px !important;
  border-top-right-radius: 45px !important;
}

.b-b-45 {
  border-bottom-left-radius: 45px !important;
  border-bottom-right-radius: 45px !important;
}

.b-tl-45 {
  border-top-left-radius: 45px !important;
}

.b-tr-45 {
  border-top-right-radius: 45px !important;
}

.b-bl-45 {
  border-bottom-left-radius: 45px !important;
}

.b-br-45 {
  border-bottom-right-radius: 45px !important;
}

.m-46 {
  margin: 46px !important;
}

.m-x-46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

.m-y-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

.m-t-46 {
  margin-top: 46px !important;
}

.m-r-46 {
  margin-right: 46px !important;
}

.m-b-46 {
  margin-bottom: 46px !important;
}

.m-l-46 {
  margin-left: 46px !important;
}

.p-46 {
  padding: 46px !important;
}

.p-x-46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.p-y-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

.p-t-46 {
  padding-top: 46px !important;
}

.p-r-46 {
  padding-right: 46px !important;
}

.p-b-46 {
  padding-bottom: 46px !important;
}

.p-l-46 {
  padding-left: 46px !important;
}

.b-r-46 {
  border-radius: 46px !important;
}

.b-t-46 {
  border-top-left-radius: 46px !important;
  border-top-right-radius: 46px !important;
}

.b-b-46 {
  border-bottom-left-radius: 46px !important;
  border-bottom-right-radius: 46px !important;
}

.b-tl-46 {
  border-top-left-radius: 46px !important;
}

.b-tr-46 {
  border-top-right-radius: 46px !important;
}

.b-bl-46 {
  border-bottom-left-radius: 46px !important;
}

.b-br-46 {
  border-bottom-right-radius: 46px !important;
}

.m-47 {
  margin: 47px !important;
}

.m-x-47 {
  margin-left: 47px !important;
  margin-right: 47px !important;
}

.m-y-47 {
  margin-top: 47px !important;
  margin-bottom: 47px !important;
}

.m-t-47 {
  margin-top: 47px !important;
}

.m-r-47 {
  margin-right: 47px !important;
}

.m-b-47 {
  margin-bottom: 47px !important;
}

.m-l-47 {
  margin-left: 47px !important;
}

.p-47 {
  padding: 47px !important;
}

.p-x-47 {
  padding-left: 47px !important;
  padding-right: 47px !important;
}

.p-y-47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important;
}

.p-t-47 {
  padding-top: 47px !important;
}

.p-r-47 {
  padding-right: 47px !important;
}

.p-b-47 {
  padding-bottom: 47px !important;
}

.p-l-47 {
  padding-left: 47px !important;
}

.b-r-47 {
  border-radius: 47px !important;
}

.b-t-47 {
  border-top-left-radius: 47px !important;
  border-top-right-radius: 47px !important;
}

.b-b-47 {
  border-bottom-left-radius: 47px !important;
  border-bottom-right-radius: 47px !important;
}

.b-tl-47 {
  border-top-left-radius: 47px !important;
}

.b-tr-47 {
  border-top-right-radius: 47px !important;
}

.b-bl-47 {
  border-bottom-left-radius: 47px !important;
}

.b-br-47 {
  border-bottom-right-radius: 47px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-x-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.m-y-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.m-t-48 {
  margin-top: 48px !important;
}

.m-r-48 {
  margin-right: 48px !important;
}

.m-b-48 {
  margin-bottom: 48px !important;
}

.m-l-48 {
  margin-left: 48px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-x-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.p-y-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.p-t-48 {
  padding-top: 48px !important;
}

.p-r-48 {
  padding-right: 48px !important;
}

.p-b-48 {
  padding-bottom: 48px !important;
}

.p-l-48 {
  padding-left: 48px !important;
}

.b-r-48 {
  border-radius: 48px !important;
}

.b-t-48 {
  border-top-left-radius: 48px !important;
  border-top-right-radius: 48px !important;
}

.b-b-48 {
  border-bottom-left-radius: 48px !important;
  border-bottom-right-radius: 48px !important;
}

.b-tl-48 {
  border-top-left-radius: 48px !important;
}

.b-tr-48 {
  border-top-right-radius: 48px !important;
}

.b-bl-48 {
  border-bottom-left-radius: 48px !important;
}

.b-br-48 {
  border-bottom-right-radius: 48px !important;
}

.m-49 {
  margin: 49px !important;
}

.m-x-49 {
  margin-left: 49px !important;
  margin-right: 49px !important;
}

.m-y-49 {
  margin-top: 49px !important;
  margin-bottom: 49px !important;
}

.m-t-49 {
  margin-top: 49px !important;
}

.m-r-49 {
  margin-right: 49px !important;
}

.m-b-49 {
  margin-bottom: 49px !important;
}

.m-l-49 {
  margin-left: 49px !important;
}

.p-49 {
  padding: 49px !important;
}

.p-x-49 {
  padding-left: 49px !important;
  padding-right: 49px !important;
}

.p-y-49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important;
}

.p-t-49 {
  padding-top: 49px !important;
}

.p-r-49 {
  padding-right: 49px !important;
}

.p-b-49 {
  padding-bottom: 49px !important;
}

.p-l-49 {
  padding-left: 49px !important;
}

.b-r-49 {
  border-radius: 49px !important;
}

.b-t-49 {
  border-top-left-radius: 49px !important;
  border-top-right-radius: 49px !important;
}

.b-b-49 {
  border-bottom-left-radius: 49px !important;
  border-bottom-right-radius: 49px !important;
}

.b-tl-49 {
  border-top-left-radius: 49px !important;
}

.b-tr-49 {
  border-top-right-radius: 49px !important;
}

.b-bl-49 {
  border-bottom-left-radius: 49px !important;
}

.b-br-49 {
  border-bottom-right-radius: 49px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-x-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.m-y-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-x-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.p-y-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.b-r-50 {
  border-radius: 50px !important;
}

.b-t-50 {
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
}

.b-b-50 {
  border-bottom-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.b-tl-50 {
  border-top-left-radius: 50px !important;
}

.b-tr-50 {
  border-top-right-radius: 50px !important;
}

.b-bl-50 {
  border-bottom-left-radius: 50px !important;
}

.b-br-50 {
  border-bottom-right-radius: 50px !important;
}

.max-w-0percent {
  max-width: 0% !important;
}

.min-w-0percent {
  min-width: 0% !important;
}

.w-0percent {
  width: 0% !important;
}

.max-h-0percent {
  max-height: 0% !important;
}

.min-h-0percent {
  min-height: 0% !important;
}

.h-0percent {
  height: 0% !important;
}

.p-x-0percent {
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.p-y-0percent {
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.f-w-0 {
  font-weight: 0;
}

.z-0 {
  z-index: 0;
}

.max-w-1percent {
  max-width: 1% !important;
}

.min-w-1percent {
  min-width: 1% !important;
}

.w-1percent {
  width: 1% !important;
}

.max-h-1percent {
  max-height: 1% !important;
}

.min-h-1percent {
  min-height: 1% !important;
}

.h-1percent {
  height: 1% !important;
}

.p-x-1percent {
  padding-left: 1% !important;
  padding-right: 1% !important;
}

.p-y-1percent {
  padding-left: 1% !important;
  padding-right: 1% !important;
}

.f-w-1 {
  font-weight: 1;
}

.z-1 {
  z-index: 1;
}

.max-w-2percent {
  max-width: 2% !important;
}

.min-w-2percent {
  min-width: 2% !important;
}

.w-2percent {
  width: 2% !important;
}

.max-h-2percent {
  max-height: 2% !important;
}

.min-h-2percent {
  min-height: 2% !important;
}

.h-2percent {
  height: 2% !important;
}

.p-x-2percent {
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.p-y-2percent {
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.f-w-2 {
  font-weight: 2;
}

.z-2 {
  z-index: 2;
}

.max-w-3percent {
  max-width: 3% !important;
}

.min-w-3percent {
  min-width: 3% !important;
}

.w-3percent {
  width: 3% !important;
}

.max-h-3percent {
  max-height: 3% !important;
}

.min-h-3percent {
  min-height: 3% !important;
}

.h-3percent {
  height: 3% !important;
}

.p-x-3percent {
  padding-left: 3% !important;
  padding-right: 3% !important;
}

.p-y-3percent {
  padding-left: 3% !important;
  padding-right: 3% !important;
}

.f-w-3 {
  font-weight: 3;
}

.z-3 {
  z-index: 3;
}

.max-w-4percent {
  max-width: 4% !important;
}

.min-w-4percent {
  min-width: 4% !important;
}

.w-4percent {
  width: 4% !important;
}

.max-h-4percent {
  max-height: 4% !important;
}

.min-h-4percent {
  min-height: 4% !important;
}

.h-4percent {
  height: 4% !important;
}

.p-x-4percent {
  padding-left: 4% !important;
  padding-right: 4% !important;
}

.p-y-4percent {
  padding-left: 4% !important;
  padding-right: 4% !important;
}

.f-w-4 {
  font-weight: 4;
}

.z-4 {
  z-index: 4;
}

.max-w-5percent {
  max-width: 5% !important;
}

.min-w-5percent {
  min-width: 5% !important;
}

.w-5percent {
  width: 5% !important;
}

.max-h-5percent {
  max-height: 5% !important;
}

.min-h-5percent {
  min-height: 5% !important;
}

.h-5percent {
  height: 5% !important;
}

.p-x-5percent {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.p-y-5percent {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.f-w-5 {
  font-weight: 5;
}

.z-5 {
  z-index: 5;
}

.max-w-6percent {
  max-width: 6% !important;
}

.min-w-6percent {
  min-width: 6% !important;
}

.w-6percent {
  width: 6% !important;
}

.max-h-6percent {
  max-height: 6% !important;
}

.min-h-6percent {
  min-height: 6% !important;
}

.h-6percent {
  height: 6% !important;
}

.p-x-6percent {
  padding-left: 6% !important;
  padding-right: 6% !important;
}

.p-y-6percent {
  padding-left: 6% !important;
  padding-right: 6% !important;
}

.f-w-6 {
  font-weight: 6;
}

.z-6 {
  z-index: 6;
}

.max-w-7percent {
  max-width: 7% !important;
}

.min-w-7percent {
  min-width: 7% !important;
}

.w-7percent {
  width: 7% !important;
}

.max-h-7percent {
  max-height: 7% !important;
}

.min-h-7percent {
  min-height: 7% !important;
}

.h-7percent {
  height: 7% !important;
}

.p-x-7percent {
  padding-left: 7% !important;
  padding-right: 7% !important;
}

.p-y-7percent {
  padding-left: 7% !important;
  padding-right: 7% !important;
}

.f-w-7 {
  font-weight: 7;
}

.z-7 {
  z-index: 7;
}

.max-w-8percent {
  max-width: 8% !important;
}

.min-w-8percent {
  min-width: 8% !important;
}

.w-8percent {
  width: 8% !important;
}

.max-h-8percent {
  max-height: 8% !important;
}

.min-h-8percent {
  min-height: 8% !important;
}

.h-8percent {
  height: 8% !important;
}

.p-x-8percent {
  padding-left: 8% !important;
  padding-right: 8% !important;
}

.p-y-8percent {
  padding-left: 8% !important;
  padding-right: 8% !important;
}

.f-w-8 {
  font-weight: 8;
}

.z-8 {
  z-index: 8;
}

.max-w-9percent {
  max-width: 9% !important;
}

.min-w-9percent {
  min-width: 9% !important;
}

.w-9percent {
  width: 9% !important;
}

.max-h-9percent {
  max-height: 9% !important;
}

.min-h-9percent {
  min-height: 9% !important;
}

.h-9percent {
  height: 9% !important;
}

.p-x-9percent {
  padding-left: 9% !important;
  padding-right: 9% !important;
}

.p-y-9percent {
  padding-left: 9% !important;
  padding-right: 9% !important;
}

.f-w-9 {
  font-weight: 9;
}

.z-9 {
  z-index: 9;
}

.max-w-10percent {
  max-width: 10% !important;
}

.min-w-10percent {
  min-width: 10% !important;
}

.w-10percent {
  width: 10% !important;
}

.max-h-10percent {
  max-height: 10% !important;
}

.min-h-10percent {
  min-height: 10% !important;
}

.h-10percent {
  height: 10% !important;
}

.p-x-10percent {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.p-y-10percent {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.f-w-10 {
  font-weight: 10;
}

.z-10 {
  z-index: 10;
}

.max-w-11percent {
  max-width: 11% !important;
}

.min-w-11percent {
  min-width: 11% !important;
}

.w-11percent {
  width: 11% !important;
}

.max-h-11percent {
  max-height: 11% !important;
}

.min-h-11percent {
  min-height: 11% !important;
}

.h-11percent {
  height: 11% !important;
}

.p-x-11percent {
  padding-left: 11% !important;
  padding-right: 11% !important;
}

.p-y-11percent {
  padding-left: 11% !important;
  padding-right: 11% !important;
}

.f-w-11 {
  font-weight: 11;
}

.z-11 {
  z-index: 11;
}

.max-w-12percent {
  max-width: 12% !important;
}

.min-w-12percent {
  min-width: 12% !important;
}

.w-12percent {
  width: 12% !important;
}

.max-h-12percent {
  max-height: 12% !important;
}

.min-h-12percent {
  min-height: 12% !important;
}

.h-12percent {
  height: 12% !important;
}

.p-x-12percent {
  padding-left: 12% !important;
  padding-right: 12% !important;
}

.p-y-12percent {
  padding-left: 12% !important;
  padding-right: 12% !important;
}

.f-w-12 {
  font-weight: 12;
}

.z-12 {
  z-index: 12;
}

.max-w-13percent {
  max-width: 13% !important;
}

.min-w-13percent {
  min-width: 13% !important;
}

.w-13percent {
  width: 13% !important;
}

.max-h-13percent {
  max-height: 13% !important;
}

.min-h-13percent {
  min-height: 13% !important;
}

.h-13percent {
  height: 13% !important;
}

.p-x-13percent {
  padding-left: 13% !important;
  padding-right: 13% !important;
}

.p-y-13percent {
  padding-left: 13% !important;
  padding-right: 13% !important;
}

.f-w-13 {
  font-weight: 13;
}

.z-13 {
  z-index: 13;
}

.max-w-14percent {
  max-width: 14% !important;
}

.min-w-14percent {
  min-width: 14% !important;
}

.w-14percent {
  width: 14% !important;
}

.max-h-14percent {
  max-height: 14% !important;
}

.min-h-14percent {
  min-height: 14% !important;
}

.h-14percent {
  height: 14% !important;
}

.p-x-14percent {
  padding-left: 14% !important;
  padding-right: 14% !important;
}

.p-y-14percent {
  padding-left: 14% !important;
  padding-right: 14% !important;
}

.f-w-14 {
  font-weight: 14;
}

.z-14 {
  z-index: 14;
}

.max-w-15percent {
  max-width: 15% !important;
}

.min-w-15percent {
  min-width: 15% !important;
}

.w-15percent {
  width: 15% !important;
}

.max-h-15percent {
  max-height: 15% !important;
}

.min-h-15percent {
  min-height: 15% !important;
}

.h-15percent {
  height: 15% !important;
}

.p-x-15percent {
  padding-left: 15% !important;
  padding-right: 15% !important;
}

.p-y-15percent {
  padding-left: 15% !important;
  padding-right: 15% !important;
}

.f-w-15 {
  font-weight: 15;
}

.z-15 {
  z-index: 15;
}

.max-w-16percent {
  max-width: 16% !important;
}

.min-w-16percent {
  min-width: 16% !important;
}

.w-16percent {
  width: 16% !important;
}

.max-h-16percent {
  max-height: 16% !important;
}

.min-h-16percent {
  min-height: 16% !important;
}

.h-16percent {
  height: 16% !important;
}

.p-x-16percent {
  padding-left: 16% !important;
  padding-right: 16% !important;
}

.p-y-16percent {
  padding-left: 16% !important;
  padding-right: 16% !important;
}

.f-w-16 {
  font-weight: 16;
}

.z-16 {
  z-index: 16;
}

.max-w-17percent {
  max-width: 17% !important;
}

.min-w-17percent {
  min-width: 17% !important;
}

.w-17percent {
  width: 17% !important;
}

.max-h-17percent {
  max-height: 17% !important;
}

.min-h-17percent {
  min-height: 17% !important;
}

.h-17percent {
  height: 17% !important;
}

.p-x-17percent {
  padding-left: 17% !important;
  padding-right: 17% !important;
}

.p-y-17percent {
  padding-left: 17% !important;
  padding-right: 17% !important;
}

.f-w-17 {
  font-weight: 17;
}

.z-17 {
  z-index: 17;
}

.max-w-18percent {
  max-width: 18% !important;
}

.min-w-18percent {
  min-width: 18% !important;
}

.w-18percent {
  width: 18% !important;
}

.max-h-18percent {
  max-height: 18% !important;
}

.min-h-18percent {
  min-height: 18% !important;
}

.h-18percent {
  height: 18% !important;
}

.p-x-18percent {
  padding-left: 18% !important;
  padding-right: 18% !important;
}

.p-y-18percent {
  padding-left: 18% !important;
  padding-right: 18% !important;
}

.f-w-18 {
  font-weight: 18;
}

.z-18 {
  z-index: 18;
}

.max-w-19percent {
  max-width: 19% !important;
}

.min-w-19percent {
  min-width: 19% !important;
}

.w-19percent {
  width: 19% !important;
}

.max-h-19percent {
  max-height: 19% !important;
}

.min-h-19percent {
  min-height: 19% !important;
}

.h-19percent {
  height: 19% !important;
}

.p-x-19percent {
  padding-left: 19% !important;
  padding-right: 19% !important;
}

.p-y-19percent {
  padding-left: 19% !important;
  padding-right: 19% !important;
}

.f-w-19 {
  font-weight: 19;
}

.z-19 {
  z-index: 19;
}

.max-w-20percent {
  max-width: 20% !important;
}

.min-w-20percent {
  min-width: 20% !important;
}

.w-20percent {
  width: 20% !important;
}

.max-h-20percent {
  max-height: 20% !important;
}

.min-h-20percent {
  min-height: 20% !important;
}

.h-20percent {
  height: 20% !important;
}

.p-x-20percent {
  padding-left: 20% !important;
  padding-right: 20% !important;
}

.p-y-20percent {
  padding-left: 20% !important;
  padding-right: 20% !important;
}

.f-w-20 {
  font-weight: 20;
}

.z-20 {
  z-index: 20;
}

.max-w-21percent {
  max-width: 21% !important;
}

.min-w-21percent {
  min-width: 21% !important;
}

.w-21percent {
  width: 21% !important;
}

.max-h-21percent {
  max-height: 21% !important;
}

.min-h-21percent {
  min-height: 21% !important;
}

.h-21percent {
  height: 21% !important;
}

.p-x-21percent {
  padding-left: 21% !important;
  padding-right: 21% !important;
}

.p-y-21percent {
  padding-left: 21% !important;
  padding-right: 21% !important;
}

.f-w-21 {
  font-weight: 21;
}

.z-21 {
  z-index: 21;
}

.max-w-22percent {
  max-width: 22% !important;
}

.min-w-22percent {
  min-width: 22% !important;
}

.w-22percent {
  width: 22% !important;
}

.max-h-22percent {
  max-height: 22% !important;
}

.min-h-22percent {
  min-height: 22% !important;
}

.h-22percent {
  height: 22% !important;
}

.p-x-22percent {
  padding-left: 22% !important;
  padding-right: 22% !important;
}

.p-y-22percent {
  padding-left: 22% !important;
  padding-right: 22% !important;
}

.f-w-22 {
  font-weight: 22;
}

.z-22 {
  z-index: 22;
}

.max-w-23percent {
  max-width: 23% !important;
}

.min-w-23percent {
  min-width: 23% !important;
}

.w-23percent {
  width: 23% !important;
}

.max-h-23percent {
  max-height: 23% !important;
}

.min-h-23percent {
  min-height: 23% !important;
}

.h-23percent {
  height: 23% !important;
}

.p-x-23percent {
  padding-left: 23% !important;
  padding-right: 23% !important;
}

.p-y-23percent {
  padding-left: 23% !important;
  padding-right: 23% !important;
}

.f-w-23 {
  font-weight: 23;
}

.z-23 {
  z-index: 23;
}

.max-w-24percent {
  max-width: 24% !important;
}

.min-w-24percent {
  min-width: 24% !important;
}

.w-24percent {
  width: 24% !important;
}

.max-h-24percent {
  max-height: 24% !important;
}

.min-h-24percent {
  min-height: 24% !important;
}

.h-24percent {
  height: 24% !important;
}

.p-x-24percent {
  padding-left: 24% !important;
  padding-right: 24% !important;
}

.p-y-24percent {
  padding-left: 24% !important;
  padding-right: 24% !important;
}

.f-w-24 {
  font-weight: 24;
}

.z-24 {
  z-index: 24;
}

.max-w-25percent {
  max-width: 25% !important;
}

.min-w-25percent {
  min-width: 25% !important;
}

.w-25percent {
  width: 25% !important;
}

.max-h-25percent {
  max-height: 25% !important;
}

.min-h-25percent {
  min-height: 25% !important;
}

.h-25percent {
  height: 25% !important;
}

.p-x-25percent {
  padding-left: 25% !important;
  padding-right: 25% !important;
}

.p-y-25percent {
  padding-left: 25% !important;
  padding-right: 25% !important;
}

.f-w-25 {
  font-weight: 25;
}

.z-25 {
  z-index: 25;
}

.max-w-26percent {
  max-width: 26% !important;
}

.min-w-26percent {
  min-width: 26% !important;
}

.w-26percent {
  width: 26% !important;
}

.max-h-26percent {
  max-height: 26% !important;
}

.min-h-26percent {
  min-height: 26% !important;
}

.h-26percent {
  height: 26% !important;
}

.p-x-26percent {
  padding-left: 26% !important;
  padding-right: 26% !important;
}

.p-y-26percent {
  padding-left: 26% !important;
  padding-right: 26% !important;
}

.f-w-26 {
  font-weight: 26;
}

.z-26 {
  z-index: 26;
}

.max-w-27percent {
  max-width: 27% !important;
}

.min-w-27percent {
  min-width: 27% !important;
}

.w-27percent {
  width: 27% !important;
}

.max-h-27percent {
  max-height: 27% !important;
}

.min-h-27percent {
  min-height: 27% !important;
}

.h-27percent {
  height: 27% !important;
}

.p-x-27percent {
  padding-left: 27% !important;
  padding-right: 27% !important;
}

.p-y-27percent {
  padding-left: 27% !important;
  padding-right: 27% !important;
}

.f-w-27 {
  font-weight: 27;
}

.z-27 {
  z-index: 27;
}

.max-w-28percent {
  max-width: 28% !important;
}

.min-w-28percent {
  min-width: 28% !important;
}

.w-28percent {
  width: 28% !important;
}

.max-h-28percent {
  max-height: 28% !important;
}

.min-h-28percent {
  min-height: 28% !important;
}

.h-28percent {
  height: 28% !important;
}

.p-x-28percent {
  padding-left: 28% !important;
  padding-right: 28% !important;
}

.p-y-28percent {
  padding-left: 28% !important;
  padding-right: 28% !important;
}

.f-w-28 {
  font-weight: 28;
}

.z-28 {
  z-index: 28;
}

.max-w-29percent {
  max-width: 29% !important;
}

.min-w-29percent {
  min-width: 29% !important;
}

.w-29percent {
  width: 29% !important;
}

.max-h-29percent {
  max-height: 29% !important;
}

.min-h-29percent {
  min-height: 29% !important;
}

.h-29percent {
  height: 29% !important;
}

.p-x-29percent {
  padding-left: 29% !important;
  padding-right: 29% !important;
}

.p-y-29percent {
  padding-left: 29% !important;
  padding-right: 29% !important;
}

.f-w-29 {
  font-weight: 29;
}

.z-29 {
  z-index: 29;
}

.max-w-30percent {
  max-width: 30% !important;
}

.min-w-30percent {
  min-width: 30% !important;
}

.w-30percent {
  width: 30% !important;
}

.max-h-30percent {
  max-height: 30% !important;
}

.min-h-30percent {
  min-height: 30% !important;
}

.h-30percent {
  height: 30% !important;
}

.p-x-30percent {
  padding-left: 30% !important;
  padding-right: 30% !important;
}

.p-y-30percent {
  padding-left: 30% !important;
  padding-right: 30% !important;
}

.f-w-30 {
  font-weight: 30;
}

.z-30 {
  z-index: 30;
}

.max-w-31percent {
  max-width: 31% !important;
}

.min-w-31percent {
  min-width: 31% !important;
}

.w-31percent {
  width: 31% !important;
}

.max-h-31percent {
  max-height: 31% !important;
}

.min-h-31percent {
  min-height: 31% !important;
}

.h-31percent {
  height: 31% !important;
}

.p-x-31percent {
  padding-left: 31% !important;
  padding-right: 31% !important;
}

.p-y-31percent {
  padding-left: 31% !important;
  padding-right: 31% !important;
}

.f-w-31 {
  font-weight: 31;
}

.z-31 {
  z-index: 31;
}

.max-w-32percent {
  max-width: 32% !important;
}

.min-w-32percent {
  min-width: 32% !important;
}

.w-32percent {
  width: 32% !important;
}

.max-h-32percent {
  max-height: 32% !important;
}

.min-h-32percent {
  min-height: 32% !important;
}

.h-32percent {
  height: 32% !important;
}

.p-x-32percent {
  padding-left: 32% !important;
  padding-right: 32% !important;
}

.p-y-32percent {
  padding-left: 32% !important;
  padding-right: 32% !important;
}

.f-w-32 {
  font-weight: 32;
}

.z-32 {
  z-index: 32;
}

.max-w-33percent {
  max-width: 33% !important;
}

.min-w-33percent {
  min-width: 33% !important;
}

.w-33percent {
  width: 33% !important;
}

.max-h-33percent {
  max-height: 33% !important;
}

.min-h-33percent {
  min-height: 33% !important;
}

.h-33percent {
  height: 33% !important;
}

.p-x-33percent {
  padding-left: 33% !important;
  padding-right: 33% !important;
}

.p-y-33percent {
  padding-left: 33% !important;
  padding-right: 33% !important;
}

.f-w-33 {
  font-weight: 33;
}

.z-33 {
  z-index: 33;
}

.max-w-34percent {
  max-width: 34% !important;
}

.min-w-34percent {
  min-width: 34% !important;
}

.w-34percent {
  width: 34% !important;
}

.max-h-34percent {
  max-height: 34% !important;
}

.min-h-34percent {
  min-height: 34% !important;
}

.h-34percent {
  height: 34% !important;
}

.p-x-34percent {
  padding-left: 34% !important;
  padding-right: 34% !important;
}

.p-y-34percent {
  padding-left: 34% !important;
  padding-right: 34% !important;
}

.f-w-34 {
  font-weight: 34;
}

.z-34 {
  z-index: 34;
}

.max-w-35percent {
  max-width: 35% !important;
}

.min-w-35percent {
  min-width: 35% !important;
}

.w-35percent {
  width: 35% !important;
}

.max-h-35percent {
  max-height: 35% !important;
}

.min-h-35percent {
  min-height: 35% !important;
}

.h-35percent {
  height: 35% !important;
}

.p-x-35percent {
  padding-left: 35% !important;
  padding-right: 35% !important;
}

.p-y-35percent {
  padding-left: 35% !important;
  padding-right: 35% !important;
}

.f-w-35 {
  font-weight: 35;
}

.z-35 {
  z-index: 35;
}

.max-w-36percent {
  max-width: 36% !important;
}

.min-w-36percent {
  min-width: 36% !important;
}

.w-36percent {
  width: 36% !important;
}

.max-h-36percent {
  max-height: 36% !important;
}

.min-h-36percent {
  min-height: 36% !important;
}

.h-36percent {
  height: 36% !important;
}

.p-x-36percent {
  padding-left: 36% !important;
  padding-right: 36% !important;
}

.p-y-36percent {
  padding-left: 36% !important;
  padding-right: 36% !important;
}

.f-w-36 {
  font-weight: 36;
}

.z-36 {
  z-index: 36;
}

.max-w-37percent {
  max-width: 37% !important;
}

.min-w-37percent {
  min-width: 37% !important;
}

.w-37percent {
  width: 37% !important;
}

.max-h-37percent {
  max-height: 37% !important;
}

.min-h-37percent {
  min-height: 37% !important;
}

.h-37percent {
  height: 37% !important;
}

.p-x-37percent {
  padding-left: 37% !important;
  padding-right: 37% !important;
}

.p-y-37percent {
  padding-left: 37% !important;
  padding-right: 37% !important;
}

.f-w-37 {
  font-weight: 37;
}

.z-37 {
  z-index: 37;
}

.max-w-38percent {
  max-width: 38% !important;
}

.min-w-38percent {
  min-width: 38% !important;
}

.w-38percent {
  width: 38% !important;
}

.max-h-38percent {
  max-height: 38% !important;
}

.min-h-38percent {
  min-height: 38% !important;
}

.h-38percent {
  height: 38% !important;
}

.p-x-38percent {
  padding-left: 38% !important;
  padding-right: 38% !important;
}

.p-y-38percent {
  padding-left: 38% !important;
  padding-right: 38% !important;
}

.f-w-38 {
  font-weight: 38;
}

.z-38 {
  z-index: 38;
}

.max-w-39percent {
  max-width: 39% !important;
}

.min-w-39percent {
  min-width: 39% !important;
}

.w-39percent {
  width: 39% !important;
}

.max-h-39percent {
  max-height: 39% !important;
}

.min-h-39percent {
  min-height: 39% !important;
}

.h-39percent {
  height: 39% !important;
}

.p-x-39percent {
  padding-left: 39% !important;
  padding-right: 39% !important;
}

.p-y-39percent {
  padding-left: 39% !important;
  padding-right: 39% !important;
}

.f-w-39 {
  font-weight: 39;
}

.z-39 {
  z-index: 39;
}

.max-w-40percent {
  max-width: 40% !important;
}

.min-w-40percent {
  min-width: 40% !important;
}

.w-40percent {
  width: 40% !important;
}

.max-h-40percent {
  max-height: 40% !important;
}

.min-h-40percent {
  min-height: 40% !important;
}

.h-40percent {
  height: 40% !important;
}

.p-x-40percent {
  padding-left: 40% !important;
  padding-right: 40% !important;
}

.p-y-40percent {
  padding-left: 40% !important;
  padding-right: 40% !important;
}

.f-w-40 {
  font-weight: 40;
}

.z-40 {
  z-index: 40;
}

.max-w-41percent {
  max-width: 41% !important;
}

.min-w-41percent {
  min-width: 41% !important;
}

.w-41percent {
  width: 41% !important;
}

.max-h-41percent {
  max-height: 41% !important;
}

.min-h-41percent {
  min-height: 41% !important;
}

.h-41percent {
  height: 41% !important;
}

.p-x-41percent {
  padding-left: 41% !important;
  padding-right: 41% !important;
}

.p-y-41percent {
  padding-left: 41% !important;
  padding-right: 41% !important;
}

.f-w-41 {
  font-weight: 41;
}

.z-41 {
  z-index: 41;
}

.max-w-42percent {
  max-width: 42% !important;
}

.min-w-42percent {
  min-width: 42% !important;
}

.w-42percent {
  width: 42% !important;
}

.max-h-42percent {
  max-height: 42% !important;
}

.min-h-42percent {
  min-height: 42% !important;
}

.h-42percent {
  height: 42% !important;
}

.p-x-42percent {
  padding-left: 42% !important;
  padding-right: 42% !important;
}

.p-y-42percent {
  padding-left: 42% !important;
  padding-right: 42% !important;
}

.f-w-42 {
  font-weight: 42;
}

.z-42 {
  z-index: 42;
}

.max-w-43percent {
  max-width: 43% !important;
}

.min-w-43percent {
  min-width: 43% !important;
}

.w-43percent {
  width: 43% !important;
}

.max-h-43percent {
  max-height: 43% !important;
}

.min-h-43percent {
  min-height: 43% !important;
}

.h-43percent {
  height: 43% !important;
}

.p-x-43percent {
  padding-left: 43% !important;
  padding-right: 43% !important;
}

.p-y-43percent {
  padding-left: 43% !important;
  padding-right: 43% !important;
}

.f-w-43 {
  font-weight: 43;
}

.z-43 {
  z-index: 43;
}

.max-w-44percent {
  max-width: 44% !important;
}

.min-w-44percent {
  min-width: 44% !important;
}

.w-44percent {
  width: 44% !important;
}

.max-h-44percent {
  max-height: 44% !important;
}

.min-h-44percent {
  min-height: 44% !important;
}

.h-44percent {
  height: 44% !important;
}

.p-x-44percent {
  padding-left: 44% !important;
  padding-right: 44% !important;
}

.p-y-44percent {
  padding-left: 44% !important;
  padding-right: 44% !important;
}

.f-w-44 {
  font-weight: 44;
}

.z-44 {
  z-index: 44;
}

.max-w-45percent {
  max-width: 45% !important;
}

.min-w-45percent {
  min-width: 45% !important;
}

.w-45percent {
  width: 45% !important;
}

.max-h-45percent {
  max-height: 45% !important;
}

.min-h-45percent {
  min-height: 45% !important;
}

.h-45percent {
  height: 45% !important;
}

.p-x-45percent {
  padding-left: 45% !important;
  padding-right: 45% !important;
}

.p-y-45percent {
  padding-left: 45% !important;
  padding-right: 45% !important;
}

.f-w-45 {
  font-weight: 45;
}

.z-45 {
  z-index: 45;
}

.max-w-46percent {
  max-width: 46% !important;
}

.min-w-46percent {
  min-width: 46% !important;
}

.w-46percent {
  width: 46% !important;
}

.max-h-46percent {
  max-height: 46% !important;
}

.min-h-46percent {
  min-height: 46% !important;
}

.h-46percent {
  height: 46% !important;
}

.p-x-46percent {
  padding-left: 46% !important;
  padding-right: 46% !important;
}

.p-y-46percent {
  padding-left: 46% !important;
  padding-right: 46% !important;
}

.f-w-46 {
  font-weight: 46;
}

.z-46 {
  z-index: 46;
}

.max-w-47percent {
  max-width: 47% !important;
}

.min-w-47percent {
  min-width: 47% !important;
}

.w-47percent {
  width: 47% !important;
}

.max-h-47percent {
  max-height: 47% !important;
}

.min-h-47percent {
  min-height: 47% !important;
}

.h-47percent {
  height: 47% !important;
}

.p-x-47percent {
  padding-left: 47% !important;
  padding-right: 47% !important;
}

.p-y-47percent {
  padding-left: 47% !important;
  padding-right: 47% !important;
}

.f-w-47 {
  font-weight: 47;
}

.z-47 {
  z-index: 47;
}

.max-w-48percent {
  max-width: 48% !important;
}

.min-w-48percent {
  min-width: 48% !important;
}

.w-48percent {
  width: 48% !important;
}

.max-h-48percent {
  max-height: 48% !important;
}

.min-h-48percent {
  min-height: 48% !important;
}

.h-48percent {
  height: 48% !important;
}

.p-x-48percent {
  padding-left: 48% !important;
  padding-right: 48% !important;
}

.p-y-48percent {
  padding-left: 48% !important;
  padding-right: 48% !important;
}

.f-w-48 {
  font-weight: 48;
}

.z-48 {
  z-index: 48;
}

.max-w-49percent {
  max-width: 49% !important;
}

.min-w-49percent {
  min-width: 49% !important;
}

.w-49percent {
  width: 49% !important;
}

.max-h-49percent {
  max-height: 49% !important;
}

.min-h-49percent {
  min-height: 49% !important;
}

.h-49percent {
  height: 49% !important;
}

.p-x-49percent {
  padding-left: 49% !important;
  padding-right: 49% !important;
}

.p-y-49percent {
  padding-left: 49% !important;
  padding-right: 49% !important;
}

.f-w-49 {
  font-weight: 49;
}

.z-49 {
  z-index: 49;
}

.max-w-50percent {
  max-width: 50% !important;
}

.min-w-50percent {
  min-width: 50% !important;
}

.w-50percent {
  width: 50% !important;
}

.max-h-50percent {
  max-height: 50% !important;
}

.min-h-50percent {
  min-height: 50% !important;
}

.h-50percent {
  height: 50% !important;
}

.p-x-50percent {
  padding-left: 50% !important;
  padding-right: 50% !important;
}

.p-y-50percent {
  padding-left: 50% !important;
  padding-right: 50% !important;
}

.f-w-50 {
  font-weight: 50;
}

.z-50 {
  z-index: 50;
}

.max-w-51percent {
  max-width: 51% !important;
}

.min-w-51percent {
  min-width: 51% !important;
}

.w-51percent {
  width: 51% !important;
}

.max-h-51percent {
  max-height: 51% !important;
}

.min-h-51percent {
  min-height: 51% !important;
}

.h-51percent {
  height: 51% !important;
}

.p-x-51percent {
  padding-left: 51% !important;
  padding-right: 51% !important;
}

.p-y-51percent {
  padding-left: 51% !important;
  padding-right: 51% !important;
}

.f-w-51 {
  font-weight: 51;
}

.z-51 {
  z-index: 51;
}

.max-w-52percent {
  max-width: 52% !important;
}

.min-w-52percent {
  min-width: 52% !important;
}

.w-52percent {
  width: 52% !important;
}

.max-h-52percent {
  max-height: 52% !important;
}

.min-h-52percent {
  min-height: 52% !important;
}

.h-52percent {
  height: 52% !important;
}

.p-x-52percent {
  padding-left: 52% !important;
  padding-right: 52% !important;
}

.p-y-52percent {
  padding-left: 52% !important;
  padding-right: 52% !important;
}

.f-w-52 {
  font-weight: 52;
}

.z-52 {
  z-index: 52;
}

.max-w-53percent {
  max-width: 53% !important;
}

.min-w-53percent {
  min-width: 53% !important;
}

.w-53percent {
  width: 53% !important;
}

.max-h-53percent {
  max-height: 53% !important;
}

.min-h-53percent {
  min-height: 53% !important;
}

.h-53percent {
  height: 53% !important;
}

.p-x-53percent {
  padding-left: 53% !important;
  padding-right: 53% !important;
}

.p-y-53percent {
  padding-left: 53% !important;
  padding-right: 53% !important;
}

.f-w-53 {
  font-weight: 53;
}

.z-53 {
  z-index: 53;
}

.max-w-54percent {
  max-width: 54% !important;
}

.min-w-54percent {
  min-width: 54% !important;
}

.w-54percent {
  width: 54% !important;
}

.max-h-54percent {
  max-height: 54% !important;
}

.min-h-54percent {
  min-height: 54% !important;
}

.h-54percent {
  height: 54% !important;
}

.p-x-54percent {
  padding-left: 54% !important;
  padding-right: 54% !important;
}

.p-y-54percent {
  padding-left: 54% !important;
  padding-right: 54% !important;
}

.f-w-54 {
  font-weight: 54;
}

.z-54 {
  z-index: 54;
}

.max-w-55percent {
  max-width: 55% !important;
}

.min-w-55percent {
  min-width: 55% !important;
}

.w-55percent {
  width: 55% !important;
}

.max-h-55percent {
  max-height: 55% !important;
}

.min-h-55percent {
  min-height: 55% !important;
}

.h-55percent {
  height: 55% !important;
}

.p-x-55percent {
  padding-left: 55% !important;
  padding-right: 55% !important;
}

.p-y-55percent {
  padding-left: 55% !important;
  padding-right: 55% !important;
}

.f-w-55 {
  font-weight: 55;
}

.z-55 {
  z-index: 55;
}

.max-w-56percent {
  max-width: 56% !important;
}

.min-w-56percent {
  min-width: 56% !important;
}

.w-56percent {
  width: 56% !important;
}

.max-h-56percent {
  max-height: 56% !important;
}

.min-h-56percent {
  min-height: 56% !important;
}

.h-56percent {
  height: 56% !important;
}

.p-x-56percent {
  padding-left: 56% !important;
  padding-right: 56% !important;
}

.p-y-56percent {
  padding-left: 56% !important;
  padding-right: 56% !important;
}

.f-w-56 {
  font-weight: 56;
}

.z-56 {
  z-index: 56;
}

.max-w-57percent {
  max-width: 57% !important;
}

.min-w-57percent {
  min-width: 57% !important;
}

.w-57percent {
  width: 57% !important;
}

.max-h-57percent {
  max-height: 57% !important;
}

.min-h-57percent {
  min-height: 57% !important;
}

.h-57percent {
  height: 57% !important;
}

.p-x-57percent {
  padding-left: 57% !important;
  padding-right: 57% !important;
}

.p-y-57percent {
  padding-left: 57% !important;
  padding-right: 57% !important;
}

.f-w-57 {
  font-weight: 57;
}

.z-57 {
  z-index: 57;
}

.max-w-58percent {
  max-width: 58% !important;
}

.min-w-58percent {
  min-width: 58% !important;
}

.w-58percent {
  width: 58% !important;
}

.max-h-58percent {
  max-height: 58% !important;
}

.min-h-58percent {
  min-height: 58% !important;
}

.h-58percent {
  height: 58% !important;
}

.p-x-58percent {
  padding-left: 58% !important;
  padding-right: 58% !important;
}

.p-y-58percent {
  padding-left: 58% !important;
  padding-right: 58% !important;
}

.f-w-58 {
  font-weight: 58;
}

.z-58 {
  z-index: 58;
}

.max-w-59percent {
  max-width: 59% !important;
}

.min-w-59percent {
  min-width: 59% !important;
}

.w-59percent {
  width: 59% !important;
}

.max-h-59percent {
  max-height: 59% !important;
}

.min-h-59percent {
  min-height: 59% !important;
}

.h-59percent {
  height: 59% !important;
}

.p-x-59percent {
  padding-left: 59% !important;
  padding-right: 59% !important;
}

.p-y-59percent {
  padding-left: 59% !important;
  padding-right: 59% !important;
}

.f-w-59 {
  font-weight: 59;
}

.z-59 {
  z-index: 59;
}

.max-w-60percent {
  max-width: 60% !important;
}

.min-w-60percent {
  min-width: 60% !important;
}

.w-60percent {
  width: 60% !important;
}

.max-h-60percent {
  max-height: 60% !important;
}

.min-h-60percent {
  min-height: 60% !important;
}

.h-60percent {
  height: 60% !important;
}

.p-x-60percent {
  padding-left: 60% !important;
  padding-right: 60% !important;
}

.p-y-60percent {
  padding-left: 60% !important;
  padding-right: 60% !important;
}

.f-w-60 {
  font-weight: 60;
}

.z-60 {
  z-index: 60;
}

.max-w-61percent {
  max-width: 61% !important;
}

.min-w-61percent {
  min-width: 61% !important;
}

.w-61percent {
  width: 61% !important;
}

.max-h-61percent {
  max-height: 61% !important;
}

.min-h-61percent {
  min-height: 61% !important;
}

.h-61percent {
  height: 61% !important;
}

.p-x-61percent {
  padding-left: 61% !important;
  padding-right: 61% !important;
}

.p-y-61percent {
  padding-left: 61% !important;
  padding-right: 61% !important;
}

.f-w-61 {
  font-weight: 61;
}

.z-61 {
  z-index: 61;
}

.max-w-62percent {
  max-width: 62% !important;
}

.min-w-62percent {
  min-width: 62% !important;
}

.w-62percent {
  width: 62% !important;
}

.max-h-62percent {
  max-height: 62% !important;
}

.min-h-62percent {
  min-height: 62% !important;
}

.h-62percent {
  height: 62% !important;
}

.p-x-62percent {
  padding-left: 62% !important;
  padding-right: 62% !important;
}

.p-y-62percent {
  padding-left: 62% !important;
  padding-right: 62% !important;
}

.f-w-62 {
  font-weight: 62;
}

.z-62 {
  z-index: 62;
}

.max-w-63percent {
  max-width: 63% !important;
}

.min-w-63percent {
  min-width: 63% !important;
}

.w-63percent {
  width: 63% !important;
}

.max-h-63percent {
  max-height: 63% !important;
}

.min-h-63percent {
  min-height: 63% !important;
}

.h-63percent {
  height: 63% !important;
}

.p-x-63percent {
  padding-left: 63% !important;
  padding-right: 63% !important;
}

.p-y-63percent {
  padding-left: 63% !important;
  padding-right: 63% !important;
}

.f-w-63 {
  font-weight: 63;
}

.z-63 {
  z-index: 63;
}

.max-w-64percent {
  max-width: 64% !important;
}

.min-w-64percent {
  min-width: 64% !important;
}

.w-64percent {
  width: 64% !important;
}

.max-h-64percent {
  max-height: 64% !important;
}

.min-h-64percent {
  min-height: 64% !important;
}

.h-64percent {
  height: 64% !important;
}

.p-x-64percent {
  padding-left: 64% !important;
  padding-right: 64% !important;
}

.p-y-64percent {
  padding-left: 64% !important;
  padding-right: 64% !important;
}

.f-w-64 {
  font-weight: 64;
}

.z-64 {
  z-index: 64;
}

.max-w-65percent {
  max-width: 65% !important;
}

.min-w-65percent {
  min-width: 65% !important;
}

.w-65percent {
  width: 65% !important;
}

.max-h-65percent {
  max-height: 65% !important;
}

.min-h-65percent {
  min-height: 65% !important;
}

.h-65percent {
  height: 65% !important;
}

.p-x-65percent {
  padding-left: 65% !important;
  padding-right: 65% !important;
}

.p-y-65percent {
  padding-left: 65% !important;
  padding-right: 65% !important;
}

.f-w-65 {
  font-weight: 65;
}

.z-65 {
  z-index: 65;
}

.max-w-66percent {
  max-width: 66% !important;
}

.min-w-66percent {
  min-width: 66% !important;
}

.w-66percent {
  width: 66% !important;
}

.max-h-66percent {
  max-height: 66% !important;
}

.min-h-66percent {
  min-height: 66% !important;
}

.h-66percent {
  height: 66% !important;
}

.p-x-66percent {
  padding-left: 66% !important;
  padding-right: 66% !important;
}

.p-y-66percent {
  padding-left: 66% !important;
  padding-right: 66% !important;
}

.f-w-66 {
  font-weight: 66;
}

.z-66 {
  z-index: 66;
}

.max-w-67percent {
  max-width: 67% !important;
}

.min-w-67percent {
  min-width: 67% !important;
}

.w-67percent {
  width: 67% !important;
}

.max-h-67percent {
  max-height: 67% !important;
}

.min-h-67percent {
  min-height: 67% !important;
}

.h-67percent {
  height: 67% !important;
}

.p-x-67percent {
  padding-left: 67% !important;
  padding-right: 67% !important;
}

.p-y-67percent {
  padding-left: 67% !important;
  padding-right: 67% !important;
}

.f-w-67 {
  font-weight: 67;
}

.z-67 {
  z-index: 67;
}

.max-w-68percent {
  max-width: 68% !important;
}

.min-w-68percent {
  min-width: 68% !important;
}

.w-68percent {
  width: 68% !important;
}

.max-h-68percent {
  max-height: 68% !important;
}

.min-h-68percent {
  min-height: 68% !important;
}

.h-68percent {
  height: 68% !important;
}

.p-x-68percent {
  padding-left: 68% !important;
  padding-right: 68% !important;
}

.p-y-68percent {
  padding-left: 68% !important;
  padding-right: 68% !important;
}

.f-w-68 {
  font-weight: 68;
}

.z-68 {
  z-index: 68;
}

.max-w-69percent {
  max-width: 69% !important;
}

.min-w-69percent {
  min-width: 69% !important;
}

.w-69percent {
  width: 69% !important;
}

.max-h-69percent {
  max-height: 69% !important;
}

.min-h-69percent {
  min-height: 69% !important;
}

.h-69percent {
  height: 69% !important;
}

.p-x-69percent {
  padding-left: 69% !important;
  padding-right: 69% !important;
}

.p-y-69percent {
  padding-left: 69% !important;
  padding-right: 69% !important;
}

.f-w-69 {
  font-weight: 69;
}

.z-69 {
  z-index: 69;
}

.max-w-70percent {
  max-width: 70% !important;
}

.min-w-70percent {
  min-width: 70% !important;
}

.w-70percent {
  width: 70% !important;
}

.max-h-70percent {
  max-height: 70% !important;
}

.min-h-70percent {
  min-height: 70% !important;
}

.h-70percent {
  height: 70% !important;
}

.p-x-70percent {
  padding-left: 70% !important;
  padding-right: 70% !important;
}

.p-y-70percent {
  padding-left: 70% !important;
  padding-right: 70% !important;
}

.f-w-70 {
  font-weight: 70;
}

.z-70 {
  z-index: 70;
}

.max-w-71percent {
  max-width: 71% !important;
}

.min-w-71percent {
  min-width: 71% !important;
}

.w-71percent {
  width: 71% !important;
}

.max-h-71percent {
  max-height: 71% !important;
}

.min-h-71percent {
  min-height: 71% !important;
}

.h-71percent {
  height: 71% !important;
}

.p-x-71percent {
  padding-left: 71% !important;
  padding-right: 71% !important;
}

.p-y-71percent {
  padding-left: 71% !important;
  padding-right: 71% !important;
}

.f-w-71 {
  font-weight: 71;
}

.z-71 {
  z-index: 71;
}

.max-w-72percent {
  max-width: 72% !important;
}

.min-w-72percent {
  min-width: 72% !important;
}

.w-72percent {
  width: 72% !important;
}

.max-h-72percent {
  max-height: 72% !important;
}

.min-h-72percent {
  min-height: 72% !important;
}

.h-72percent {
  height: 72% !important;
}

.p-x-72percent {
  padding-left: 72% !important;
  padding-right: 72% !important;
}

.p-y-72percent {
  padding-left: 72% !important;
  padding-right: 72% !important;
}

.f-w-72 {
  font-weight: 72;
}

.z-72 {
  z-index: 72;
}

.max-w-73percent {
  max-width: 73% !important;
}

.min-w-73percent {
  min-width: 73% !important;
}

.w-73percent {
  width: 73% !important;
}

.max-h-73percent {
  max-height: 73% !important;
}

.min-h-73percent {
  min-height: 73% !important;
}

.h-73percent {
  height: 73% !important;
}

.p-x-73percent {
  padding-left: 73% !important;
  padding-right: 73% !important;
}

.p-y-73percent {
  padding-left: 73% !important;
  padding-right: 73% !important;
}

.f-w-73 {
  font-weight: 73;
}

.z-73 {
  z-index: 73;
}

.max-w-74percent {
  max-width: 74% !important;
}

.min-w-74percent {
  min-width: 74% !important;
}

.w-74percent {
  width: 74% !important;
}

.max-h-74percent {
  max-height: 74% !important;
}

.min-h-74percent {
  min-height: 74% !important;
}

.h-74percent {
  height: 74% !important;
}

.p-x-74percent {
  padding-left: 74% !important;
  padding-right: 74% !important;
}

.p-y-74percent {
  padding-left: 74% !important;
  padding-right: 74% !important;
}

.f-w-74 {
  font-weight: 74;
}

.z-74 {
  z-index: 74;
}

.max-w-75percent {
  max-width: 75% !important;
}

.min-w-75percent {
  min-width: 75% !important;
}

.w-75percent {
  width: 75% !important;
}

.max-h-75percent {
  max-height: 75% !important;
}

.min-h-75percent {
  min-height: 75% !important;
}

.h-75percent {
  height: 75% !important;
}

.p-x-75percent {
  padding-left: 75% !important;
  padding-right: 75% !important;
}

.p-y-75percent {
  padding-left: 75% !important;
  padding-right: 75% !important;
}

.f-w-75 {
  font-weight: 75;
}

.z-75 {
  z-index: 75;
}

.max-w-76percent {
  max-width: 76% !important;
}

.min-w-76percent {
  min-width: 76% !important;
}

.w-76percent {
  width: 76% !important;
}

.max-h-76percent {
  max-height: 76% !important;
}

.min-h-76percent {
  min-height: 76% !important;
}

.h-76percent {
  height: 76% !important;
}

.p-x-76percent {
  padding-left: 76% !important;
  padding-right: 76% !important;
}

.p-y-76percent {
  padding-left: 76% !important;
  padding-right: 76% !important;
}

.f-w-76 {
  font-weight: 76;
}

.z-76 {
  z-index: 76;
}

.max-w-77percent {
  max-width: 77% !important;
}

.min-w-77percent {
  min-width: 77% !important;
}

.w-77percent {
  width: 77% !important;
}

.max-h-77percent {
  max-height: 77% !important;
}

.min-h-77percent {
  min-height: 77% !important;
}

.h-77percent {
  height: 77% !important;
}

.p-x-77percent {
  padding-left: 77% !important;
  padding-right: 77% !important;
}

.p-y-77percent {
  padding-left: 77% !important;
  padding-right: 77% !important;
}

.f-w-77 {
  font-weight: 77;
}

.z-77 {
  z-index: 77;
}

.max-w-78percent {
  max-width: 78% !important;
}

.min-w-78percent {
  min-width: 78% !important;
}

.w-78percent {
  width: 78% !important;
}

.max-h-78percent {
  max-height: 78% !important;
}

.min-h-78percent {
  min-height: 78% !important;
}

.h-78percent {
  height: 78% !important;
}

.p-x-78percent {
  padding-left: 78% !important;
  padding-right: 78% !important;
}

.p-y-78percent {
  padding-left: 78% !important;
  padding-right: 78% !important;
}

.f-w-78 {
  font-weight: 78;
}

.z-78 {
  z-index: 78;
}

.max-w-79percent {
  max-width: 79% !important;
}

.min-w-79percent {
  min-width: 79% !important;
}

.w-79percent {
  width: 79% !important;
}

.max-h-79percent {
  max-height: 79% !important;
}

.min-h-79percent {
  min-height: 79% !important;
}

.h-79percent {
  height: 79% !important;
}

.p-x-79percent {
  padding-left: 79% !important;
  padding-right: 79% !important;
}

.p-y-79percent {
  padding-left: 79% !important;
  padding-right: 79% !important;
}

.f-w-79 {
  font-weight: 79;
}

.z-79 {
  z-index: 79;
}

.max-w-80percent {
  max-width: 80% !important;
}

.min-w-80percent {
  min-width: 80% !important;
}

.w-80percent {
  width: 80% !important;
}

.max-h-80percent {
  max-height: 80% !important;
}

.min-h-80percent {
  min-height: 80% !important;
}

.h-80percent {
  height: 80% !important;
}

.p-x-80percent {
  padding-left: 80% !important;
  padding-right: 80% !important;
}

.p-y-80percent {
  padding-left: 80% !important;
  padding-right: 80% !important;
}

.f-w-80 {
  font-weight: 80;
}

.z-80 {
  z-index: 80;
}

.max-w-81percent {
  max-width: 81% !important;
}

.min-w-81percent {
  min-width: 81% !important;
}

.w-81percent {
  width: 81% !important;
}

.max-h-81percent {
  max-height: 81% !important;
}

.min-h-81percent {
  min-height: 81% !important;
}

.h-81percent {
  height: 81% !important;
}

.p-x-81percent {
  padding-left: 81% !important;
  padding-right: 81% !important;
}

.p-y-81percent {
  padding-left: 81% !important;
  padding-right: 81% !important;
}

.f-w-81 {
  font-weight: 81;
}

.z-81 {
  z-index: 81;
}

.max-w-82percent {
  max-width: 82% !important;
}

.min-w-82percent {
  min-width: 82% !important;
}

.w-82percent {
  width: 82% !important;
}

.max-h-82percent {
  max-height: 82% !important;
}

.min-h-82percent {
  min-height: 82% !important;
}

.h-82percent {
  height: 82% !important;
}

.p-x-82percent {
  padding-left: 82% !important;
  padding-right: 82% !important;
}

.p-y-82percent {
  padding-left: 82% !important;
  padding-right: 82% !important;
}

.f-w-82 {
  font-weight: 82;
}

.z-82 {
  z-index: 82;
}

.max-w-83percent {
  max-width: 83% !important;
}

.min-w-83percent {
  min-width: 83% !important;
}

.w-83percent {
  width: 83% !important;
}

.max-h-83percent {
  max-height: 83% !important;
}

.min-h-83percent {
  min-height: 83% !important;
}

.h-83percent {
  height: 83% !important;
}

.p-x-83percent {
  padding-left: 83% !important;
  padding-right: 83% !important;
}

.p-y-83percent {
  padding-left: 83% !important;
  padding-right: 83% !important;
}

.f-w-83 {
  font-weight: 83;
}

.z-83 {
  z-index: 83;
}

.max-w-84percent {
  max-width: 84% !important;
}

.min-w-84percent {
  min-width: 84% !important;
}

.w-84percent {
  width: 84% !important;
}

.max-h-84percent {
  max-height: 84% !important;
}

.min-h-84percent {
  min-height: 84% !important;
}

.h-84percent {
  height: 84% !important;
}

.p-x-84percent {
  padding-left: 84% !important;
  padding-right: 84% !important;
}

.p-y-84percent {
  padding-left: 84% !important;
  padding-right: 84% !important;
}

.f-w-84 {
  font-weight: 84;
}

.z-84 {
  z-index: 84;
}

.max-w-85percent {
  max-width: 85% !important;
}

.min-w-85percent {
  min-width: 85% !important;
}

.w-85percent {
  width: 85% !important;
}

.max-h-85percent {
  max-height: 85% !important;
}

.min-h-85percent {
  min-height: 85% !important;
}

.h-85percent {
  height: 85% !important;
}

.p-x-85percent {
  padding-left: 85% !important;
  padding-right: 85% !important;
}

.p-y-85percent {
  padding-left: 85% !important;
  padding-right: 85% !important;
}

.f-w-85 {
  font-weight: 85;
}

.z-85 {
  z-index: 85;
}

.max-w-86percent {
  max-width: 86% !important;
}

.min-w-86percent {
  min-width: 86% !important;
}

.w-86percent {
  width: 86% !important;
}

.max-h-86percent {
  max-height: 86% !important;
}

.min-h-86percent {
  min-height: 86% !important;
}

.h-86percent {
  height: 86% !important;
}

.p-x-86percent {
  padding-left: 86% !important;
  padding-right: 86% !important;
}

.p-y-86percent {
  padding-left: 86% !important;
  padding-right: 86% !important;
}

.f-w-86 {
  font-weight: 86;
}

.z-86 {
  z-index: 86;
}

.max-w-87percent {
  max-width: 87% !important;
}

.min-w-87percent {
  min-width: 87% !important;
}

.w-87percent {
  width: 87% !important;
}

.max-h-87percent {
  max-height: 87% !important;
}

.min-h-87percent {
  min-height: 87% !important;
}

.h-87percent {
  height: 87% !important;
}

.p-x-87percent {
  padding-left: 87% !important;
  padding-right: 87% !important;
}

.p-y-87percent {
  padding-left: 87% !important;
  padding-right: 87% !important;
}

.f-w-87 {
  font-weight: 87;
}

.z-87 {
  z-index: 87;
}

.max-w-88percent {
  max-width: 88% !important;
}

.min-w-88percent {
  min-width: 88% !important;
}

.w-88percent {
  width: 88% !important;
}

.max-h-88percent {
  max-height: 88% !important;
}

.min-h-88percent {
  min-height: 88% !important;
}

.h-88percent {
  height: 88% !important;
}

.p-x-88percent {
  padding-left: 88% !important;
  padding-right: 88% !important;
}

.p-y-88percent {
  padding-left: 88% !important;
  padding-right: 88% !important;
}

.f-w-88 {
  font-weight: 88;
}

.z-88 {
  z-index: 88;
}

.max-w-89percent {
  max-width: 89% !important;
}

.min-w-89percent {
  min-width: 89% !important;
}

.w-89percent {
  width: 89% !important;
}

.max-h-89percent {
  max-height: 89% !important;
}

.min-h-89percent {
  min-height: 89% !important;
}

.h-89percent {
  height: 89% !important;
}

.p-x-89percent {
  padding-left: 89% !important;
  padding-right: 89% !important;
}

.p-y-89percent {
  padding-left: 89% !important;
  padding-right: 89% !important;
}

.f-w-89 {
  font-weight: 89;
}

.z-89 {
  z-index: 89;
}

.max-w-90percent {
  max-width: 90% !important;
}

.min-w-90percent {
  min-width: 90% !important;
}

.w-90percent {
  width: 90% !important;
}

.max-h-90percent {
  max-height: 90% !important;
}

.min-h-90percent {
  min-height: 90% !important;
}

.h-90percent {
  height: 90% !important;
}

.p-x-90percent {
  padding-left: 90% !important;
  padding-right: 90% !important;
}

.p-y-90percent {
  padding-left: 90% !important;
  padding-right: 90% !important;
}

.f-w-90 {
  font-weight: 90;
}

.z-90 {
  z-index: 90;
}

.max-w-91percent {
  max-width: 91% !important;
}

.min-w-91percent {
  min-width: 91% !important;
}

.w-91percent {
  width: 91% !important;
}

.max-h-91percent {
  max-height: 91% !important;
}

.min-h-91percent {
  min-height: 91% !important;
}

.h-91percent {
  height: 91% !important;
}

.p-x-91percent {
  padding-left: 91% !important;
  padding-right: 91% !important;
}

.p-y-91percent {
  padding-left: 91% !important;
  padding-right: 91% !important;
}

.f-w-91 {
  font-weight: 91;
}

.z-91 {
  z-index: 91;
}

.max-w-92percent {
  max-width: 92% !important;
}

.min-w-92percent {
  min-width: 92% !important;
}

.w-92percent {
  width: 92% !important;
}

.max-h-92percent {
  max-height: 92% !important;
}

.min-h-92percent {
  min-height: 92% !important;
}

.h-92percent {
  height: 92% !important;
}

.p-x-92percent {
  padding-left: 92% !important;
  padding-right: 92% !important;
}

.p-y-92percent {
  padding-left: 92% !important;
  padding-right: 92% !important;
}

.f-w-92 {
  font-weight: 92;
}

.z-92 {
  z-index: 92;
}

.max-w-93percent {
  max-width: 93% !important;
}

.min-w-93percent {
  min-width: 93% !important;
}

.w-93percent {
  width: 93% !important;
}

.max-h-93percent {
  max-height: 93% !important;
}

.min-h-93percent {
  min-height: 93% !important;
}

.h-93percent {
  height: 93% !important;
}

.p-x-93percent {
  padding-left: 93% !important;
  padding-right: 93% !important;
}

.p-y-93percent {
  padding-left: 93% !important;
  padding-right: 93% !important;
}

.f-w-93 {
  font-weight: 93;
}

.z-93 {
  z-index: 93;
}

.max-w-94percent {
  max-width: 94% !important;
}

.min-w-94percent {
  min-width: 94% !important;
}

.w-94percent {
  width: 94% !important;
}

.max-h-94percent {
  max-height: 94% !important;
}

.min-h-94percent {
  min-height: 94% !important;
}

.h-94percent {
  height: 94% !important;
}

.p-x-94percent {
  padding-left: 94% !important;
  padding-right: 94% !important;
}

.p-y-94percent {
  padding-left: 94% !important;
  padding-right: 94% !important;
}

.f-w-94 {
  font-weight: 94;
}

.z-94 {
  z-index: 94;
}

.max-w-95percent {
  max-width: 95% !important;
}

.min-w-95percent {
  min-width: 95% !important;
}

.w-95percent {
  width: 95% !important;
}

.max-h-95percent {
  max-height: 95% !important;
}

.min-h-95percent {
  min-height: 95% !important;
}

.h-95percent {
  height: 95% !important;
}

.p-x-95percent {
  padding-left: 95% !important;
  padding-right: 95% !important;
}

.p-y-95percent {
  padding-left: 95% !important;
  padding-right: 95% !important;
}

.f-w-95 {
  font-weight: 95;
}

.z-95 {
  z-index: 95;
}

.max-w-96percent {
  max-width: 96% !important;
}

.min-w-96percent {
  min-width: 96% !important;
}

.w-96percent {
  width: 96% !important;
}

.max-h-96percent {
  max-height: 96% !important;
}

.min-h-96percent {
  min-height: 96% !important;
}

.h-96percent {
  height: 96% !important;
}

.p-x-96percent {
  padding-left: 96% !important;
  padding-right: 96% !important;
}

.p-y-96percent {
  padding-left: 96% !important;
  padding-right: 96% !important;
}

.f-w-96 {
  font-weight: 96;
}

.z-96 {
  z-index: 96;
}

.max-w-97percent {
  max-width: 97% !important;
}

.min-w-97percent {
  min-width: 97% !important;
}

.w-97percent {
  width: 97% !important;
}

.max-h-97percent {
  max-height: 97% !important;
}

.min-h-97percent {
  min-height: 97% !important;
}

.h-97percent {
  height: 97% !important;
}

.p-x-97percent {
  padding-left: 97% !important;
  padding-right: 97% !important;
}

.p-y-97percent {
  padding-left: 97% !important;
  padding-right: 97% !important;
}

.f-w-97 {
  font-weight: 97;
}

.z-97 {
  z-index: 97;
}

.max-w-98percent {
  max-width: 98% !important;
}

.min-w-98percent {
  min-width: 98% !important;
}

.w-98percent {
  width: 98% !important;
}

.max-h-98percent {
  max-height: 98% !important;
}

.min-h-98percent {
  min-height: 98% !important;
}

.h-98percent {
  height: 98% !important;
}

.p-x-98percent {
  padding-left: 98% !important;
  padding-right: 98% !important;
}

.p-y-98percent {
  padding-left: 98% !important;
  padding-right: 98% !important;
}

.f-w-98 {
  font-weight: 98;
}

.z-98 {
  z-index: 98;
}

.max-w-99percent {
  max-width: 99% !important;
}

.min-w-99percent {
  min-width: 99% !important;
}

.w-99percent {
  width: 99% !important;
}

.max-h-99percent {
  max-height: 99% !important;
}

.min-h-99percent {
  min-height: 99% !important;
}

.h-99percent {
  height: 99% !important;
}

.p-x-99percent {
  padding-left: 99% !important;
  padding-right: 99% !important;
}

.p-y-99percent {
  padding-left: 99% !important;
  padding-right: 99% !important;
}

.f-w-99 {
  font-weight: 99;
}

.z-99 {
  z-index: 99;
}

.max-w-100percent {
  max-width: 100% !important;
}

.min-w-100percent {
  min-width: 100% !important;
}

.w-100percent {
  width: 100% !important;
}

.max-h-100percent {
  max-height: 100% !important;
}

.min-h-100percent {
  min-height: 100% !important;
}

.h-100percent {
  height: 100% !important;
}

.p-x-100percent {
  padding-left: 100% !important;
  padding-right: 100% !important;
}

.p-y-100percent {
  padding-left: 100% !important;
  padding-right: 100% !important;
}

.f-w-100 {
  font-weight: 100;
}

.z-100 {
  z-index: 100;
}

/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #084298;
  --bs-alert-bg: #cfe2ff;
  --bs-alert-border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #0d6efd !important;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca !important;
}

.link-secondary {
  color: #6c757d !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}
.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  --font-size: 14px;
  --header-height: 60px;
  --sidebar-width: 250px;
  --border-color: #E5E5E5;
  --link-color: #2056AF;
  --theme-color: #0B6AB1;
  --theme-dark-color: #023E69;
  --theme-sky-color: #29ADCE;
  --hightlight-color: #ECF7FF;
  --line-color: #06C755;
  --error-color: #EA6644;
  --pages-bg-color: #F5F5F5;
  --success-color: #32A17A;
  --danger-color: #F5584F;
  --theme-yellow: #FFC200;
}

* {
  font-size: var(--font-size);
  font-family: "Noto Sans Thai", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-block-start: unset;
  margin-block-end: unset;
}

.theme-button {
  background-color: var(--theme-color);
  width: 100%;
  height: 35px;
  border-radius: 17.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  transition: all 0.25s ease-in-out;
}
.theme-button.yellow {
  background-color: var(--theme-yellow);
}
.theme-button.yellow:hover {
  border: 1px solid var(--theme-yellow);
  color: var(--theme-yellow);
  background-color: white;
}
.theme-button.yellow:hover svg-icon.stroke svg path {
  stroke: var(--theme-yellow);
}
.theme-button:hover {
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
  background-color: white;
}
.theme-button:hover svg-icon.stroke svg path {
  stroke: var(--theme-color);
}
.theme-button:disabled {
  filter: grayscale(1) opacity(0.7);
}

.theme-outline-button {
  width: 100%;
  height: 35px;
  border-radius: 17.5px;
  border: 1px solid var(--theme-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--theme-color);
}
.theme-outline-button:hover {
  color: white;
  background-color: var(--theme-color);
}
.theme-outline-button:hover svg-icon.stroke svg path {
  stroke: var(--theme-color);
}
.theme-outline-button.yellow {
  color: var(--theme-yellow);
  border: 1px solid var(--theme-yellow);
}
.theme-outline-button.yellow:hover {
  color: white;
  background-color: var(--theme-yellow);
}
.theme-outline-button.yellow:hover svg-icon.stroke svg path {
  stroke: var(--theme-yellow);
}

.text-success {
  color: var(--success-color) !important;
}

.text-danger {
  color: var(--danger-color) !important;
}

.text-error {
  color: var(--error-color);
}

.text-theme {
  color: var(--theme-color);
}

.text-theme-dark {
  color: var(--theme-dark-color);
}

.text-theme-sky {
  color: var(--theme-sky-color);
}

.p-inputtext.p-component.p-element {
  padding: 0.5rem 0.75rem;
}

.pointer {
  cursor: pointer;
}

.no-gutters {
  margin: 0;
  padding: 0;
}

.p-check-labels .p-checkbox-label {
  cursor: pointer;
}

p-checkbox .p-checkbox {
  padding: 2px;
}
p-checkbox .p-checkbox .p-checkbox-box {
  width: 18px;
  height: 18px;
}

.p-dialog {
  min-width: 500px;
}
@media (max-width: 500px) {
  .p-dialog {
    min-width: 90%;
  }
}

.selected-file {
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.file-type {
  padding: 2.5px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: #A3EAEA;
}

.hover-fade {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.hover-fade:hover {
  opacity: 0.5;
}

.p-component.p-panel.p-panel-toggleable .p-panel-header {
  background: transparent;
  border: none;
  padding: 0.5rem 0.5rem 0.5rem 0;
}
.p-component.p-panel .p-panel-header {
  background: transparent;
  border: none;
  padding: 0.5rem 0.5rem 0.5rem 0;
}
.p-component .p-toggleable-content .p-panel-content {
  border: none;
  padding: 0.5rem 0;
}

.p-component.p-dropdown {
  width: 100%;
}
.p-component.p-dropdown .p-inputtext {
  padding: 0.5rem 0.75rem;
}

.p-text-success .p-dropdown-label {
  color: var(--success-color);
}

.p-text-danger .p-dropdown-label {
  color: var(--danger-color);
}

p-calendar {
  width: 100%;
}
p-calendar .p-calendar-timeonly, p-calendar .p-calendar {
  width: 100%;
}
p-calendar .p-calendar-timeonly .p-inputtext, p-calendar .p-calendar .p-inputtext {
  width: 100%;
  padding: 0.5rem 0.75rem;
}

.p-element.p-inputwrapper {
  padding: 0;
}
.p-element.p-inputwrapper .p-inputnumber {
  width: 100%;
}
.p-element.border-none .p-inputnumber input {
  border: none;
}

.p-button {
  color: black !important;
  border: none !important;
  background-color: lightgrey !important;
}

.theme-table {
  overflow-x: auto;
}
.theme-table table {
  width: 100%;
}
.theme-table table thead tr th {
  padding: 0.5rem;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  color: var(--theme-color);
}
.theme-table table tbody tr {
  transition: background-color 0.25s ease-in-out;
}
.theme-table table tbody tr td {
  padding: 1rem;
  color: #343A3F;
}
.theme-table table tbody tr:nth-child(even) {
  background-color: #F8F8FA;
}
.theme-table table tbody tr:hover {
  background-color: var(--hightlight-color);
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  background-color: var(--border-color) !important;
}

.page-base {
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  padding: 1rem;
  background-color: var(--pages-bg-color);
}
.page-base .main-contents {
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem;
  min-height: calc(100vh - 90px);
}

.icon-bg {
  background-color: var(--pages-bg-color);
}

p-image {
  width: 100%;
  height: 100%;
}
p-image .p-image {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
p-image .p-image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.btn-red-outline {
  color: #E84143;
  border: 1px solid #E84143;
  border-radius: 1.5rem;
}

p-progressbar {
  height: 6px;
}
p-progressbar .p-progressbar {
  height: 6px;
}

.preview-container .preview-box p-image .p-image img {
  display: none;
}
.preview-container .preview-box p-image .p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: opacity(0.5);
}
.preview-container .preview-box.show p-image .p-image img {
  display: unset;
  scale: 0.5;
}

lib-preview-image {
  width: 100%;
  height: fit-content;
  position: relative;
}

ngx-file-drop .ngx-file-drop__drop-zone {
  border: none !important;
}
ngx-file-drop .ngx-file-drop__drop-zone .ngx-file-drop__content {
  border: 2px dashed var(--theme-color);
  border-radius: 0.5rem;
  background-color: #E8F1FC;
  width: 100%;
}

p-tabview .p-tabview .p-tabview-nav li {
  flex-grow: 1;
}
p-tabview .p-tabview .p-tabview-nav li a {
  justify-content: center;
}
p-tabview .p-tabview .p-tabview-panels {
  padding: 0;
}

@media (max-width: 600px) {
  .page-base {
    padding: 0.5rem;
  }
  .page-base .main-contents {
    padding: 0.75rem;
  }
}