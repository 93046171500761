@import './helpers.scss';
@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --font-size: 14px;
  --header-height: 60px;
  --sidebar-width: 250px;
  --border-color: #E5E5E5;
  --link-color: #2056AF;
  --theme-color: #0B6AB1;
  --theme-dark-color: #023E69;
  --theme-sky-color: #29ADCE;
  --hightlight-color: #ECF7FF;
  --line-color: #06C755;
  --error-color: #EA6644;
  --pages-bg-color: #F5F5F5;
  --success-color: #32A17A;
  --danger-color: #F5584F;
  --theme-yellow: #FFC200;
}

* {
  font-size: var(--font-size);
  font-family: 'Noto Sans Thai', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: unset;
  margin-block-end: unset;
}

.theme-button {
  background-color: var(--theme-color);
  width: 100%;
  height: 35px;
  border-radius: 17.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  transition: all 0.25s ease-in-out;

  &.yellow {
    background-color: var(--theme-yellow);

    &:hover {
      border: 1px solid var(--theme-yellow);
      color: var(--theme-yellow);
      background-color: white;

      svg-icon {
        &.stroke {
          svg {
            path {
              stroke: var(--theme-yellow);
            }
          }
        }
      }
    }
  }

  &:hover {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    background-color: white;

    svg-icon {
      &.stroke {
        svg {
          path {
            stroke: var(--theme-color);
          }
        }
      }
    }
  }

  &:disabled {
    filter: grayscale(1) opacity(0.7);
  }
}

.theme-outline-button {
  width: 100%;
  height: 35px;
  border-radius: 17.5px;
  border: 1px solid var(--theme-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--theme-color);

  &:hover {
    color: white;
    background-color: var(--theme-color);


    svg-icon {
      &.stroke {
        svg {
          path {
            stroke: var(--theme-color);
          }
        }
      }
    }
  }

  &.yellow {
    color: var(--theme-yellow);
    border: 1px solid var(--theme-yellow);

    &:hover {
      color: white;
      background-color: var(--theme-yellow);


      svg-icon {
        &.stroke {
          svg {
            path {
              stroke: var(--theme-yellow);
            }
          }
        }
      }
    }
  }
}

.text-success {
  color: var(--success-color) !important;
}

.text-danger {
  color: var(--danger-color) !important;
}

.text-error {
  color: var(--error-color);
}

.text-theme {
  color: var(--theme-color);
}

.text-theme-dark {
  color: var(--theme-dark-color);
}

.text-theme-sky {
  color: var(--theme-sky-color);
}

.p-inputtext  {
  &.p-component {
    &.p-element {
      padding: 0.5rem 0.75rem;
    }
  }
}

.pointer {
  cursor: pointer;
}

.no-gutters {
  margin: 0;
  padding: 0;
}

.p-check-labels {
  .p-checkbox-label {
    cursor: pointer;
  }
}

p-checkbox {
  .p-checkbox {
    padding: 2px;

    .p-checkbox-box {
      width: 18px;
      height: 18px;
    }
  }
}

.p-dialog {
  min-width: 500px;

  @media (max-width: 500px) {
    min-width: 90%;
  }
}

.selected-file {
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.file-type {
  padding: 2.5px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: #A3EAEA;
}

.hover-fade {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
}
.p-component {
  &.p-panel {
    &.p-panel-toggleable {
      .p-panel-header {
        background: transparent;
        border: none;
        padding: 0.5rem 0.5rem 0.5rem 0;
      }
    }

    .p-panel-header {
      background: transparent;
      border: none;
      padding: 0.5rem 0.5rem 0.5rem 0;
    }
  }

  .p-toggleable-content {
    .p-panel-content {
      border: none;
      padding: 0.5rem 0;
    }
  }
}

.p-component {
  &.p-dropdown {
    width: 100%;

    .p-inputtext {
      padding: 0.5rem 0.75rem;
    }
  }
}

.p-text-success .p-dropdown-label {
  color: var(--success-color);
}

.p-text-danger .p-dropdown-label {
  color: var(--danger-color);
}

p-calendar {
  width: 100%;

  .p-calendar-timeonly, .p-calendar {
    width: 100%;

    .p-inputtext {
      width: 100%;
      padding: 0.5rem 0.75rem;
    }
  }
}

.p-element {
  &.p-inputwrapper {
    padding: 0;

    .p-inputnumber {
      width: 100%;
    }
  }

  &.border-none {
    .p-inputnumber {
      input {
        border: none;
      }
    }
  }
}


.p-button {
  color: black !important;
  border: none !important;
  background-color: lightgrey !important;
}

.theme-table {
  overflow-x: auto;

  table {
    width: 100%;
    thead {
      tr {
        th {
          padding: 0.5rem;
          border-top: 1px solid var(--border-color);
          border-bottom: 1px solid var(--border-color);
          color: var(--theme-color);
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 1rem;
          color: #343A3F;
        }
        transition: background-color 0.25s ease-in-out;

        &:nth-child(even) {
          background-color: #F8F8FA;
        }

        &:hover {
          background-color: var(--hightlight-color);
        }
      }
    }
  }
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  background-color: var(--border-color) !important;
}

.page-base {
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  padding: 1rem;
  background-color: var(--pages-bg-color);

  .main-contents {
    background-color: white;
    border-radius: 0.5rem;
    padding: 2rem;
    min-height: calc(100vh - 90px);
  }
}

.icon-bg {
  background-color: var(--pages-bg-color);
}


p-image {
  width: 100%;
  height: 100%;

  .p-image {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
}

.btn-red-outline {
  color: #E84143;
  border: 1px solid #E84143;
  border-radius: 1.5rem;
}

p-progressbar {
  height: 6px;

  .p-progressbar {
    height: 6px;
  }
}

.preview-container {
  .preview-box {
    p-image {
      .p-image {
        img {
          display: none;
        }
      }

      .p-image-preview-container:hover > .p-image-preview-indicator {
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: opacity(0.5);
      }
    }

    &.show {
      p-image {
        .p-image {
          img {
            display: unset;
            scale: 0.5;
          }
        }
      }
    }
  }
}

lib-preview-image {
  width: 100%;
  height: fit-content;
  position: relative;
}

ngx-file-drop {
  .ngx-file-drop__drop-zone {
    border: none !important;

    .ngx-file-drop__content {
      border: 2px dashed var(--theme-color);
      border-radius: 0.5rem;
      background-color: #E8F1FC;
      width: 100%;
    }
  }
}

p-tabview {
  .p-tabview {
    .p-tabview-nav {
      li {
        flex-grow: 1;

        a {
          justify-content: center;
        }
      }
    }

    .p-tabview-panels {
      padding: 0;
    }
  }
}


@media (max-width: 600px) {
  .page-base {
    padding: 0.5rem;

    .main-contents {
      padding: 0.75rem;
    }
  }
}
