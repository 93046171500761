$maximum-pixel-helper-class: 50;
$maximum-dimension-helper-class: 400;

$i: 0;
@while $i <= $maximum-dimension-helper-class {
  .min-w-#{$i} {
    min-width: #{$i}px !important;
  }
  .w-#{$i} {
    width: #{$i}px !important;
  }
  .max-w-#{$i} {
    max-width: #{$i}px !important;
  }
  .min-h-#{$i} {
    min-height: #{$i}px !important;
  }
  .h-#{$i} {
    height: #{$i}px !important;
  }
  .max-h-#{$i} {
    max-height: #{$i}px !important;
  }
  .lh-#{$i} {
    line-height: #{$i}px !important;
  }
  $i: $i + 1;
}

@for $i from 0 through $maximum-pixel-helper-class {
  .m-#{$i} {
    margin: #{$i}px !important;
  }
  .m-x-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }
  .m-y-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }
  .m-r-#{$i} {
    margin-right: #{$i}px !important;
  }
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }
  .p-#{$i} {
    padding: #{$i}px !important;
  }
  .p-x-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
  .p-y-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }
  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }
  .p-r-#{$i} {
    padding-right: #{$i}px !important;
  }
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .p-l-#{$i} {
    padding-left: #{$i}px !important;
  }
  .b-r-#{$i} {
    border-radius: #{$i}px !important;
  }
  .b-t-#{$i} {
    border-top-left-radius: #{$i}px !important;
    border-top-right-radius: #{$i}px !important;
  }
  .b-b-#{$i} {
    border-bottom-left-radius: #{$i}px !important;
    border-bottom-right-radius: #{$i}px !important;
  }
  .b-tl-#{$i} {
    border-top-left-radius: #{$i}px !important;
  }
  .b-tr-#{$i} {
    border-top-right-radius: #{$i}px !important;
  }
  .b-bl-#{$i} {
    border-bottom-left-radius: #{$i}px !important;
  }
  .b-br-#{$i} {
    border-bottom-right-radius: #{$i}px !important;
  }
}

$p: 0;
@while $p <= 100 {
  .max-w-#{$p}percent {
    max-width: #{$p} + '%' !important;
  }
  .min-w-#{$p}percent {
    min-width: #{$p} + '%' !important;
  }
  .w-#{$p}percent {
    width: #{$p} + '%' !important;
  }
  .max-h-#{$p}percent {
    max-height: #{$p} + '%' !important;
  }
  .min-h-#{$p}percent {
    min-height: #{$p} + '%' !important;
  }
  .h-#{$p}percent {
    height: #{$p} + '%' !important;
  }
  .p-x-#{$p}percent {
    padding-left: #{$p} + '%' !important;
    padding-right: #{$p} + '%' !important;
  }
  .p-y-#{$p}percent {
    padding-left: #{$p} + '%' !important;
    padding-right: #{$p} + '%' !important;
  }
  .f-w-#{$p} {
    font-weight: #{$p};
  }
  .z-#{$p} {
    z-index: #{$p};
  }
  $p: $p + 1;
}
